import { Button, Checkbox } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import useStyles from "../PartyStockLedger/style";
import ReportTbl from '../CollectionReport/ReportTbl';
import { COMPANY_CODE, GET_ALL_WAREHOUSE_STOCK, GET_GROUP_SELECTION } from '../../../constant';
import axios from 'axios';
import ComingSoonPopup from '../PopUP/PopUp';

function AllwarehouseStock() {
  const classes = useStyles();
  const[allwarehouseclm,setallwarehouseclm]=useState([]);
  const [isLoading,setisLoading] = useState(false)
  const [rowData, setRowData] = useState([]);
  const history = useHistory();
  const [tabledata, settabledata] = useState([]);
  const [formdetails, setformdetails] = useState([])
  const [companyColor, setcompanyColor] = useState("")
  const [groupselection, setgroupselection] = useState([])
  const [selectedBCDMap, setSelectedBCDMap] = useState({});
  var today = new Date().toISOString().slice(0, 10);
  const [todaydate,settodaydate] = useState(today)
  const[popup,setpopup]=useState(false)


  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))

    // ge all group selection 

    axios.post(GET_GROUP_SELECTION,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log("GROUP SELECTION")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setgroupselection(response.data.Data)

        }
      })
  }, [])

  const handleCheckboxChange = (bcdValue) => {
    setSelectedBCDMap((prevSelectedBCDMap) => {
      const updatedMap = { ...prevSelectedBCDMap };
      if (updatedMap[bcdValue]) {
        delete updatedMap[bcdValue];
      } else {
        updatedMap[bcdValue] = true;
      }
      return updatedMap;
    });
  };

  const getSelectedBCDString = () => {
    const selectedBCDArray = Object.keys(selectedBCDMap);
    return selectedBCDArray.join(',');
  };

  const showData =()=>{
    var BCDs = getSelectedBCDString().toString()
console.log(BCDs)
    axios.post(GET_ALL_WAREHOUSE_STOCK,
      { "CompanyNumber": COMPANY_CODE,
      "ToDate":todaydate,
      "Category":BCDs})
      .then((response) => {
        console.log("get warehouse",response)
        if (response.data.IsSuccess === true) {
          var  mydata = response.data.Data?.map((item,i)=>({...item}))

          var obj ={}
          var saleHeader =[]
          var keys = Object.keys(mydata[0])
        keys.forEach((data,i)=>{
         var newobject = { field: data, headerName: data, width: 150 }

         saleHeader.push(newobject)
        })
          setallwarehouseclm(saleHeader);
          setRowData(mydata); 
        } 
          setisLoading(false); 
        })
      .catch((error) => {
        console.error('Error fetching data:', error);
  
      });
  };
     
const openpop =()=>{
 setpopup(true);
}
const closePopup = () => {
  setpopup(false);
};

  const goHome =()=>{
    history.push('/dashboard')
  }
  return (
    <>
    {
      isLoading?  <div className='Commonloader'>
      </div>:null
    }
    {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
    <div className="balacepoupformmain allwarehousestock_main" >
      <div className="balacepoupformleft">
        <div className="checkboxes-d-flex" style={{ justifyContent: 'space-between', width: '98%', marginTop: '' }}>
          <p style={{ color: '#000' }}>Stock As On  :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><input type="date" value={todaydate} onChange={(e)=>settodaydate(e.target.value)} /></span> </p>


        </div>
        {
          formdetails?.map((item, index) => (
            <center style={{ color: '#fff', fontWeight: '900' }}>{item.topClientName}</center>
          ))
        }
        <div style={{ color: '#000', fontsize: '16px', fontWeight: '900' }}>Group Selection</div>
        <div>
          <div className="scrollerDiv" style={{ height: "440px"}}>
            {
              groupselection.map((item, i) => (
                <>
                <input type='checkbox' 
                value={item.BCD}
                checked={selectedBCDMap[item.BCD] || false}
                onChange={() => handleCheckboxChange(item.BCD)} />
                  <span style={{ color: '#000',fontSize:'12px',marginLeft:'12px' }}>{item.DESCRIPTION}</span><br />
                </>
              ))
            }

          </div>
        </div>
        <br />
        <div className="d-flex mt-2" style={{position:'sticky',bottom:"0px"}} >
          <Button className={classes[companyColor?.split(',')[0]]} variant="contained" onClick={openpop}>EXCEL</Button>&nbsp;
          <Button className={classes[companyColor?.split(',')[0]]} variant="contained" onClick={showData}>VIEW</Button>&nbsp;
          <Button className={classes[companyColor?.split(',')[0]]} variant="contained" onClick={openpop}>PDF</Button>&nbsp;
          <Button className={classes[companyColor?.split(',')[0]]} variant="contained"  onClick={goHome}>EXIT</Button>
        </div>

      </div>
      <div className="balacepoupformright">
      <div className=''>
    {allwarehouseclm.length > 0 ? (
      <ReportTbl dataArray={rowData}
       TblColumns={allwarehouseclm}
        headerColor={companyColor} 
         />
    ) :(
      <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
        <thead className={classes[companyColor?.split(',')[0]]}> 
          <tr>
            <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>
  
          </tr>
        </thead>
        <tbody>
          <tr>
          <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
          
          </tr>
        </tbody>
      </table>
    )}
   </div>
  </div>
    </div>
    </>
  );
}
export default AllwarehouseStock;