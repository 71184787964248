import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel, ListItemText
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import CountUp from 'react-countup';
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Label,
  Area,
  PieChart, BarChart, Bar,
  Pie, Tooltip,
  Cell, LineChart,
  YAxis,
  XAxis, CartesianGrid, LabelList
} from "recharts";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import axios from "axios";
// styles
import useStyles from "./styles";
import noti from '../../../images/hrx.png'
// Utiles
import PageTitle from "../../../Utiles/PageTitle/PageTitle";
import Searchbar from "../../../Utiles/Searchbar/SearchBar";
import TotalBilImg from '../../../images/pos/totalBillImg.png';
import SalesAnalysisImg from '../../../images/pos/SalesAnalysis.png';
import loadbtn from '../../../images/buttonloader.gif';
import SaleItemImg from '../../../images/pos/SaleItem.png';
import Alertmessage from "../../../Utiles/Alert/Alertmessage";
import MasterDashboard from "./Master/MasterDashboard";
import ItemDashboard from "./Item/ItemDashboard";
import CategoryDashboard from "./Category/CategoryDashboard";
import {
  COMPANY_CODE, GET_CATEGORY_SALE_REPORT, GET_TOTAL_COLLECTION_BY_DATE, GET_GRAPH_DATA, GET_ALL_WAREHOUSES,
  GET_ITEMS_BELOW_ROL, GET_SALES_ANALYSIS_COUNT, GET_TOP_TEN_SALE_ITEM,
  GET_TOTAL_BILL_OF_DAY, LOGIN_DETAILS, GET_COLLECTION_REPORT
} from "../../../constant";

const mainChartData = getMainChartData();
export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const SubDashboard = [
    {
      "name": "Master"
    },
    {
      "name": "Item"
    },
    {
      "name": "Category"
    },

  ]

  const [subDashBoardName, setSubDashBoardName] = useState(SubDashboard[0].name)
  var today = new Date().toISOString().slice(0, 10);
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(today)
  const [enddate, setenddate] = useState(nextday);
  const [todaysdate, settodaysdate] = useState("")
  const [sdate, setSdate] = useState(startdate)
  const [edate, setEdate] = useState(enddate);



  // local

  const [billoftheday, setbilloftheday] = useState(0);
  const [loginname, setloginname] = useState(localStorage.getItem('username'))
  const [topTenSaleItem, settopTenSaleItem] = useState([])
  const [ChartCategory, setChartCategory] = useState([])
  const [wareHousee, setWareHousee] = useState("");

  const [salsAnalysis, setsalsAnalysis] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [CurrentLiveEnquirispopupdata, setCurrentLiveEnquirispopupdata] = useState([])
  const [pendingRFQspopupdata, setpendingRFQspopupdata] = useState([])
  const [Pendingpopopupdata, setPendingpopopupdata] = useState([])
  const [debitorbalancedata, setdebitorbalancedata] = useState([])
  const [creditorbalancedata, setcreditorbalancedata] = useState([])
  const [graphDate, setGraphDate] = useState([]);
  const [popupHeader, setpopupHeader] = useState("")
  const [pendingreqpopup, setpendingreqpopup] = useState(false)
  const [currentlivepopup, setcurrentlivepopup] = useState(false)
  const [pendingPOpopup, setpendingPOpopup] = useState(false)
  const [dibitorbalancepopup, setdibitorbalancepopup] = useState(false)
  const [crediorbalancepopup, setcrediorbalancepopup] = useState(false)
  const [SelectedRows, setSelectedRows] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [isform, setisform] = useState(false)
  const [companyColor, setcompanyColor] = useState("")
  const [companyBG, setcompanyBG] = useState("")
  const [ItemsBelowRol, setItemsBelowRol] = useState([]);
  const [nowdate, setnowdate] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [warehouse, setwarehouse] = useState([])
  const [selectedOption, setSelectedOption] = useState('ALL');
  const [selectedWarehose, setselectedWarehose] = useState([])
  const [collectionData, setCollectionData] = useState({
    "FormDate": startdate,
    "ToDate": enddate,
    "CompanyNumber": COMPANY_CODE,
    "strWh": "",
    "SystemName": "",
    "Euser": "",
    "Category": ""
  });
  const color = [
    "#8884d8", "#e01ee9", "#34C2FF", "#4986FF", "#2055BD", "#1204c1", "#883be5", "#d65fff", "#e12856", "#e13e3e"
  ]
  const color2 = [
    "#883be5", "#d65fff", "#e01ee9", "#e12856", "#e13e3e"
  ]
  const PieChartData = [
    { name: "Group A", value: ChartCategory[0]?.Quantity, color: "primary" },
    { name: "Group B", value: ChartCategory[1]?.Quantity, color: "secondary" },
    { name: "Group C", value: ChartCategory[2]?.Quantity, color: "warning" },
    { name: "Group D", value: ChartCategory[3]?.Quantity, color: "success" },
    { name: "Group E", value: ChartCategory[4]?.Quantity, color: "success" },
    { name: "Group F", value: ChartCategory[5]?.Quantity, color: "success" },
    { name: "Group G", value: ChartCategory[6]?.Quantity, color: "success" },
    { name: "Group H", value: ChartCategory[7]?.Quantity, color: "success" },
    { name: "Group I", value: ChartCategory[8]?.Quantity, color: "success" },
    { name: "Group J", value: ChartCategory[9]?.Quantity, color: "success" },
  ];
  const [TotalCollection, setTotalCollection] = useState([]);


  const [popupclass, setpopupclass] = useState("display-none")
  const currentliveenquryclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 240 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 }

  ]

  const pendingRFQsclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 150 },
    { field: 'topVendorName', headerName: 'VENDOR NAME', width: 150 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 150 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 },


  ]

  const debitorbalancedataclm = [
    { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150 },
    { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150 },
    { field: 'Debit', headerName: 'DEBITED', width: 80 },
    { field: 'credit', headerName: 'CREDIT', width: 80 },
    { field: 'balance', headerName: 'BALANCE', width: 100 },
  ]

  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return months[monthNumber - 1] || null;
  }


  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType').split(",")[0])

    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
    //---------- GET ITEM BELOW ROLFOR DASHBOERD----------------
    axios.post(GET_ITEMS_BELOW_ROL, { "CompanyNumber": COMPANY_CODE }).then((response) => {
      if (response.data.IsSuccess === true) {
        setItemsBelowRol(response.data.Data);
      }
      else {
        setItemsBelowRol([])
      }
    })

    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    settodaysdate(formattedDate)
    getreports(startdate, enddate)

  }, []);

  useEffect(() => {
  axios.post(GET_ALL_WAREHOUSES,
        { "CompanyNumber": COMPANY_CODE })
        .then((response) => {
  
          if (response.data.IsSuccess === true) {
            console.log("dsd", response.data.Data)
            setwarehouse(response.data.Data)
             
          }
        })

        
    // all warehouses
    if (selectedOption == 'ALL') {
   
    
        setselectedWarehose('')
    }
    else {
    
      setselectedWarehose(selectedOption?.toString())
      console.log(selectedOption.toString())

    }
  }, [selectedOption])

  // for graph 

  useEffect(() => {
    //---------- GET GRAPH DATA FOR DASHBOERD----------------

    function yearago(startyr, endyr) {
      const today = new Date();
      const currentYear = today.getFullYear();
      startyr = new Date(currentYear, 0, 1); // January 1st of current year
      startyr.setDate(startyr.getDate() + 1); // add one day to the start date
      endyr = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Today's date
      return {
        startyr: startyr.toISOString().slice(0, 10),
        endyr: endyr.toISOString().slice(0, 10)
      };
    }
    const { startyr, endyr } = yearago()

    axios.post(GET_GRAPH_DATA, { "CompanyNumber": COMPANY_CODE, "fromDate": "2022-01-01", "toDate": "2023-12-31" })
      .then((response) => {
        if (response.data.IsSuccess === true) {
          setGraphDate(response.data.Data);
        }
        else {
          setGraphDate([]);
        }
      })
  }, [])


  const getyeargraph = (name) => {
    if (name == "current") {
      function yearago(startyr, endyr) {
        const today = new Date();
        const currentYear = today.getFullYear();
        startyr = new Date(currentYear, 0, 1); // January 1st of current year
        startyr.setDate(startyr.getDate() + 1); // add one day to the start date
        endyr = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Today's date

        return {
          startyr: startyr.toISOString().slice(0, 10),
          endyr: endyr.toISOString().slice(0, 10)
        };

      }
      const { startyr, endyr } = yearago()
      axios.post(GET_GRAPH_DATA, { "CompanyNumber": COMPANY_CODE, "fromDate": startyr, "toDate": endyr })
        .then((response) => {
          if (response.data.IsSuccess === true) {
            setGraphDate(response.data.Data);
          }
          else {
            setGraphDate([]);
          }
        })

    }
    else if (name == "last") {

      function yearago(startyr, endyr) {
        const today = new Date();
        const currentYear = today.getFullYear() - 1;
        startyr = new Date(currentYear, 0, 1); // January 1st of current year
        startyr.setDate(startyr.getDate() + 1); // add one day to the start date


        const lasttoday = new Date();
        const lastyear = lasttoday.getFullYear();
        endyr = new Date(lastyear, 0, 1); // dec 
        return {
          startyr: startyr.toISOString().slice(0, 10),
          endyr: endyr.toISOString().slice(0, 10)
        };

      }
      const { startyr, endyr } = yearago()
      axios.post(GET_GRAPH_DATA, { "CompanyNumber": COMPANY_CODE, "fromDate": startyr, "toDate": endyr })
        .then((response) => {
          if (response.data.IsSuccess === true) {
            setGraphDate(response.data.Data);
          }
          else {
            setGraphDate([]);
          }
        })
    }
  }




  // Get Report Function => getreports(startdate , enddate)

  const getreports = (start, end) => {

    //---------- Total Collection----------------

    axios.post(GET_TOTAL_BILL_OF_DAY, { "CompanyNumber": COMPANY_CODE, "Warehouse": LOGIN_DETAILS.WAREHOUSE, "FYear": LOGIN_DETAILS.FINANCIAL_YEAR, "CHSERIES": LOGIN_DETAILS.CHSERIES_RSL })
      .then((response) => {

        if (response.data.IsSuccess === true) {
          setbilloftheday(response.data.Data.BillCount)
        }
      })

    //---------- Total Collection----------------

    collectionData.FormDate = start
    collectionData.ToDate = end

    //-----------GET TOTAL COLLECTION------------- 
    axios.post(GET_COLLECTION_REPORT, collectionData)
      .then((response) => {
        if (response.data.IsSuccess === true) {

          const newData = response.data.Data.filter(row => row["User Name"] == "Total Collection");

          // for now sale analysis count 
          const a = newData[0].Amount;
          setsalsAnalysis(a)


          const filteredData = {};

          Object.keys(newData[0]).forEach(key => {
            if (key !== 'Date' && key !== 'User Name' && key !== 'System Name' && key !== 'Id' && key !== 'Type') {
              if (newData[0][key] != 0) {
                filteredData[key] = newData[0][key];
              }

            }
          });


          setTotalCollection(filteredData)
        }
        else {
          setTotalCollection([])
          setsalsAnalysis(0)
        }

      })
    //---------- Sales Analysis----------------
    // axios.post(GET_SALES_ANALYSIS_COUNT, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
    //   .then((response) => {
    //     console.log("sale analysis")
    //     console.log(response)
    //     if (response.data.IsSuccess === true) {
    //       console.log(response.data.Data.BillCount);

    //       const formattedNumber = formatNumberIndianStyle(response.data.Data.BillCount.toString());
    //       console.log(formattedNumber);

    //       setsalsAnalysis(formattedNumber)

    //     }
    //   })

    //---------- TOP TEN SALE ITEMS----------------

    axios.post(GET_TOP_TEN_SALE_ITEM, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
      .then((response) => {

        if (response.data.IsSuccess === true) {

          settopTenSaleItem(response.data.Data)
        }
        else {
          settopTenSaleItem([]);
        }
      })

    //---------- Category pie chart  ----------------

    axios.post(GET_CATEGORY_SALE_REPORT,
      { "CompanyNumber": COMPANY_CODE, "FormDate": start, "ToDate": end })
      .then((response) => {

        if (response.data.IsSuccess === true) {
          var array = response.data.Data;
          if (array.length > 0) {
            array.sort((a, b) => b.Quantity - a.Quantity);
            setChartCategory(array)

          }

        }
        else {
          setChartCategory([])
        }
      })



    // //---------- GET GRAPH DATA FOR DASHBOERD----------------
    // axios.post(GET_GRAPH_DATA, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
    //   .then((response) => {
    //     if (response.data.IsSuccess === true) {
    //     setGraphDate(response.data.Data);
    //     }
    //     else{
    //     setGraphDate([]);
    //     }
    //   })

  }


  var nowexactdate = function getFormettedDate() {
    var datentime = ""
    let today = new Date();

    // Get day, month, and year separately
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    // Create an array of month names
    let monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Get the month name from the array
    let monthName = monthNames[month - 1];
    datentime = `${day} ${monthName} ${year}`
    return datentime

  }

  const CustomBarLabel = ({ x, y, width, value }) => (
    <text x={x + width / 2} y={y - 4} dy={-4} textAnchor="middle" fill="#666">
      {value}
    </text>
  );


  const getreportallwarehouse = (e) => {
    setActiveButton(e.target.name)

    const today = new Date();
    const oneMonthsAgo = new Date();
    oneMonthsAgo.setMonth(today.getMonth() - 1);
    const formattedDate = oneMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  }

  const getreportseightmonths = (e) => {
    setActiveButton(e.target.name)

    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))
    handelData(formattedDate, new Date().toISOString().slice(0, 10));
  }

  const getreportssixmonths = (e) => {

    setActiveButton(e.target.name)
    // ActiveButton
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 6);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))
    handelData(formattedDate, new Date().toISOString().slice(0, 10));
  }
  //date before respective Months => (a) 
  // setActiveButton("six")

  const getreportsthreemonths = (e) => {
    setActiveButton(e.target.name)
    // setActiveButton("eight")
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 8);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))
    handelData(formattedDate, new Date().toISOString().slice(0, 10));

  }


  // graph 

  const graphdatacurrent = graphDate;

  const newArray = graphdatacurrent.map(item => ({
    Mth: item.Mth,
    LastYearPurchase: item.LyPurchase,
    CurrentYearPurchase: item.CyPurchase,
    LastYearSale: item.LySale,
    CurrentYearSale: item.CySale
  }));




  const uniqueBymonths = Object.values(newArray.reduce((acc, item) => {
    const month = item.Mth;
    if (!acc[month]) {
      acc[month] = {
        Mth: month,
        LastYearPurchase: 0,
        CurrentYearPurchase: 0,
        LastYearSale: 0,
        CurrentYearSale: 0
      };
    }
    acc[month].LastYearPurchase += item.LastYearPurchase;
    acc[month].CurrentYearPurchase += item.CurrentYearPurchase;
    acc[month].LastYearSale += item.LastYearSale;
    acc[month].CurrentYearSale += item.CurrentYearSale;
    return acc;
  }, {}));



  let cumulativeLastYearPurchase = 0;
  let cumulativeCurrentYearPurchase = 0;
  let cumulativeLastYearSale = 0;
  let cumulativeCurrentYearSale = 0;

  const cumulativeArr = uniqueBymonths.map((item) => {
    cumulativeLastYearPurchase += item.LastYearPurchase;
    cumulativeCurrentYearPurchase += item.CurrentYearPurchase;
    cumulativeLastYearSale += item.LastYearSale;
    cumulativeCurrentYearSale += item.CurrentYearSale;

    return {
      Mth: item.Mth,
      LastYearPurchase: cumulativeLastYearPurchase,
      CurrentYearPurchase: cumulativeCurrentYearPurchase,
      LastYearSale: cumulativeLastYearSale,
      CurrentYearSale: cumulativeCurrentYearSale
    };
  });


  // now split the array in two parts 
  let splitIndex = 0;

  for (let i = 1; i < cumulativeArr.length; i++) {
    if (
      cumulativeArr[i].CurrentYearPurchase === cumulativeArr[i - 1].CurrentYearPurchase &&
      cumulativeArr[i].CurrentYearSale === cumulativeArr[i - 1].CurrentYearSale
    ) {
      splitIndex = i;
      break;
    }
  }

  let newArray1 = cumulativeArr.slice(0, splitIndex);
  let newArray2 = cumulativeArr.slice(splitIndex);

  for (let i = 0; i < newArray2.length; i++) {
    newArray2[i].CurrentYearSale = "";
    newArray2[i].CurrentYearPurchase = "";
  }

  let mergedArray = newArray1.concat(newArray2);


  // here new login 

  const UniqueByMonth = [];
  const months = [...new Set(graphdatacurrent.map(item => item.Mth))];

  months.forEach(month => {
    const monthData = graphdatacurrent.filter(item => item.Mth === month);
    const obj = { Mth: month };

    monthData.forEach(item => {
      obj.LyPurchase = (obj.LyPurchase || 0) + item.LyPurchase;
      obj.CyPurchase = (obj.CyPurchase || 0) + item.CyPurchase;
      obj.LySale = (obj.LySale || 0) + item.LySale;
      obj.CySale = (obj.CySale || 0) + item.CySale;
    });

    UniqueByMonth.push(obj);
  });



  let updatedData = UniqueByMonth.map((currentMonth, index, arr) => {
    let cumulativeLySale = currentMonth.LySale;
    let cumulativeLyPurchase = currentMonth.LyPurchase;
    let cumulativeCySale = currentMonth.CySale;
    let cumulativeCyPurchase = currentMonth.CyPurchase;

    // Calculate cumulative values of previous months
    for (let i = 0; i < index; i++) {
      cumulativeLySale += arr[i].LySale;
      cumulativeLyPurchase += arr[i].LyPurchase;
      cumulativeCySale += arr[i].CySale;
      cumulativeCyPurchase += arr[i].CyPurchase;
    }

    return {
      Mth: currentMonth.Mth,
      LyPurchase: cumulativeLyPurchase,
      CyPurchase: cumulativeCyPurchase,
      LySale: cumulativeLySale,
      CySale: cumulativeCySale,
    };
  });


  // ------------------graph end ------------------

  const handleResize = () => {
    setShouldUpdate(false);
    setTimeout(() => {
      setShouldUpdate(true);
    }, 500);
  };


  const handleclosepopup = () => {
    setpopupclass("display-none")
  }

  const handlePendingRfqsCellClick = (params) => {
    setisform(true)
    setpopupclass("display-none")

  }

  //------------------on click of submit button--------------------
  const handelData = (sDate, eDate) => {
    setSdate(sDate);
    setEdate(eDate);
  }



  return (
    <>
      {/* {
        isLoading ? <div className='Commonloader'>
        </div> : null
      } */}
      <Grid container spacing={5}  >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Searchbar />
        </Grid>
        <div className="notify">
          <img height="20px" src={noti} />
        </div>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">

          <PageTitle title={`WELCOME TO ${loginname}`}
            button={<Button

              size="medium"
              className={classes.rastorant_mainColor}
            >
              <span className={`${classes[companyColor]}`} >{nowexactdate()}</span>
            </Button>} />
          <select className="dashboarddropdown" value={selectedOption} onChange={(e) => { setSelectedOption(e.target.value); getreportallwarehouse(e); }}>
            {warehouse.map((wareh, i) => (
              <option key={i} value={wareh.Type} className={selectedOption === wareh.Type ? `${classes[companyColor + 'BG']} ${classes.active}` : ''}>
                {wareh.Type}
              </option>
            ))}
          </select>

        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pt-0">
          Date Range
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="py-1">
          {/* <InputLabel id="demo-controlled-open-select-label">From Date</InputLabel> */}
          <Grid container spacing={5}>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0'>
              <TextField id="standard-basic" type="date" value={startdate}
                onChange={(e) => { setstartdate(e.target.value); setActiveButton(""); }} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0'>
              <TextField id="standard-basic" type="date" value={enddate}
                onChange={(e) => { setenddate(e.target.value); setActiveButton(""); }} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0'>
              <button id="getReport" className={`${classes.dashSideBtn}  ${classes.rastorant_mainColor} ${classes[companyColor + 'BG']}`} onClick={(e) => getreports(startdate, enddate)} >
                {isLoading ? <img className="loaderbtnApply" src={loadbtn} /> : "Apply"}
              </button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid  item lg={2} md={2} sm={1} xs={1}className="py-1"></Grid> */}
        <Grid item lg={6} md={6} sm={6} xs={12} className={`py-1 ${classes.dashSideBtnMain} btnmonths`}>
          <button className={`${activeButton === "three" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="three" onClick={(e) => getreportseightmonths(e)}>
            Last 3 months
          </button>
          <button className={`${activeButton === "six" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="six" onClick={(e) => getreportssixmonths(e)}>
            Last 6 months
          </button>
          <button className={`${activeButton === "eight" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="eight" onClick={(e) => getreportsthreemonths(e)}>
            Last 8 months
          </button>
        </Grid>
        <div className={`categorybtn ${companyColor.toUpperCase() === "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}>
          <div className='categorydashboardbtn'>
            {SubDashboard.map((data, i) => {

              return (
                <Button
                  key={i}
                  variant='contained'
                  className={`${subDashBoardName === data.name ? classes[companyColor + 'BG'] : classes['defaultBG']} my-1`}
                  style={{
                    width: "33.33%",
                    margin: "3px",
                    color: "#fff",
                  }}
                  onClick={() => setSubDashBoardName(data.name)}
                >
                  {data.name}
                </Button>
              );
            })}
          </div>
          <p>{selectedWarehose}</p>
          {subDashBoardName === "Master" ? <MasterDashboard startdate={startdate} enddate={enddate} wareHousee={selectedWarehose} /> : ""}
          {subDashBoardName === "Item" ? <ItemDashboard startdate={startdate} enddate={enddate} wareHousee={selectedWarehose} /> : ""}
          {subDashBoardName === "Category" ? <CategoryDashboard startdate={startdate} enddate={enddate} wareHousee={selectedWarehose} /> : ""}
        </div>
        {/*Total biil section main data */}
        {/* <Grid item lg={4} md={4} sm={4} xs={12} className="pb-1">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Total Bills of the day</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={3} className="py-5">
                  <h4 className={`fw-bold dashboard_card_number_counter  ${classes.textCountInfo} ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={billoftheday} useIndianSeparators={true} />
                  </h4>
                </Grid>
                <Grid item xs={6}>
                  <span >
                    <img src={TotalBilImg} className='dashboard_card_images' /></span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid> */}
        {/* <Grid item lg={4} md={4} sm={4} xs={12} className="pb-1 ">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Total Collection</h6>
              <div className="pb-4 total_collection_scroller" style={{ overflowY: 'scroll', height: '126px' }} sx={{ width: '100%', maxWidth: 360, height: '80%' }}>
                {Object.entries(TotalCollection).map(([key, value]) => (
                  <div key={key} className="d-flex pb-3 bigscreen">
                    <div>{key}</div>
                    <div className={`py-0 ${classes.cardSecondaryClr} ${classes[companyColor]}  `}>
                    &nbsp; <CountUp end={value} prefix="₹" useIndianSeparators={true} /></div>
                  </div>
                ))}
                {TotalCollection.length === 0 ? <p className={`${classes[companyColor]}`} style={{ position: 'absolute', bottom: '0', right: '0' }}>NO COLLECTION FOUND</p> : ""}
              </div>
            </div>
          </div>
        </Grid> */}
        {/* <Grid item lg={4} md={4} sm={4} xs={12} className="pb-1">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body ">
              <h6 className={`card-title my-0 fw-bold`}>Sales Analysis</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={3} className="py-5">
                  <h4 className={`fw-bold dashboard_card_number_counter ${classes.textCountInfo}  ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={salsAnalysis} useIndianSeparators={true} prefix="₹" />
                  </h4>
                </Grid>               
                <Grid item xs={6}>
                  <span >
                    <img src={SalesAnalysisImg} className='dashboard_card_images ' /></span>
                </Grid>
                {salsAnalysis.length === 0 ? <p className={`${classes[companyColor]}`} style={{  bottom: '0', right: '0' }}>NO DATA FOUND</p> : ""}
              </Grid>
              
            </div>
          </div>
        </Grid> */}
      </Grid>
      <Grid container spacing={5} >
        <Grid item lg={12} md={8} sm={8} xs={12} className="py-1">
          <Grid container spacing={5} >
            {/* <Grid item lg={8} md={12} sm={12} xs={12} className="pb-0">
              <div className={`card py-1 ${classes.row1Card}`} style={{ height: '90%' }}>
                <div className="card-body pb-5">
                  <h6 className={`card-title my-0 fw-bold`}>Top 10 Best Selling Items</h6>
                  <Grid className="py-3" container item alignItems={"center"}>
                    <Grid item xs={8}>
                      <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                      </h4>
                      <Grid container spacing={5} className="pt-0"  >
                        <Grid item xs={6} lg={6} className="pt-0 ">
                          {
                            topTenSaleItem?.slice(0, 5)?.map((item, i) => (
                              <>
                                <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                  <li style={{ display: 'flex' }} className="list-group-item" >
                                    <span className={`${classes[companyColor]}`}>{i + 1}</span>.
                                  <h6 className={`text-capitalize bigscreen ${classes.textSecondry}`}>  &nbsp;
                                  {item.Item.slice(0, 1) + item.Item.slice(1).toLowerCase()} {item.Qty} </h6></li>
                                </ol>
                              </>
                            ))
                          }
                        </Grid>
                        <Grid item xs={6} lg={6} className="pt-0">
                          {
                            topTenSaleItem?.slice(5, 10)?.map((item, i) => (
                              <>
                                <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                  <li style={{ display: 'flex' }} className="list-group-item ">
                                    <span className={`${classes[companyColor]}`}>{i + 6}</span>.
                                    <h6 className={`text-capitalize bigscreen ${classes.textSecondry}`}>  &nbsp;
                                   {item.Item.slice(0, 1) + item.Item.slice(1).toLowerCase()} {item.Qty} </h6></li>
                                </ol>
                              </>
                            ))
                          }
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={4} className="text-end">
                      <span >
                        {
                          topTenSaleItem.length < 1 ?
                            <img style={{ height: '66px', width: '100px' }} src={SaleItemImg} className='dashboard_card_images' />
                            :
                            <img src={SaleItemImg} className='dashboard_card_images' />
                        }

                      </span>
                    </Grid>
                  </Grid>
                  {topTenSaleItem.length === 0 ? <p className={`${classes[companyColor]}`} style={{bottom: '0', right: '0' }}>NO DATA FOUND</p> : ""}

                </div>
              </div>
            </Grid> */}
            {/* <Grid item lg={4} md={4} sm={4} xs={12} className="pb-1">
          <div className={`card py-1 ${classes.row1Card} fullheight`}>
            <div className="card-body ">
              <h6 className={`card-title my-0 fw-bold`}>Item Below ROL</h6>
              {ItemsBelowRol.length === 0 ? <p className={`${classes[companyColor]}`} style={{ float: 'right' }}>NO ITEM FOUND</p> : ""}
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} >
                  <div className="pb-4 Item_belowROL" style={{ overflowY: 'scroll'}} sx={{ width: '262px', maxWidth: 360, height: '80%' }}>

                    <Grid item xs={12} className="pt-0" >
                      {
                        ItemsBelowRol?.map((item, i) => (
                          <>
                            <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                              <li className="list-group-item"><h6 style={{ whiteSpace: 'nowrap' }} className={classes.textSecondry} > <span className={`${classes[companyColor]}`}>{i + 1}</span>.{item.Item?.slice(0, 36)}</h6></li>
                            </ol>
                          </>
                        ))
                      }
                    </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid> */}
            {/* <div className={`categorybtn'  ${companyColor.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}style={{width:"100%"}}>
      <div className='categorydashboardbtn' style={{padding:"20px"}}>  
                 <Button variant='contained' className={`${classes[companyColor+'BG']}  my-1`}  style={{width:"33.33%", margin:"3px", color:"#fff"}} >Item Master </Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`}style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Price Stock Ledger</Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`} style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Stock Statement</Button>
               
                </div> 
      </div> */}
            {/* <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
              <div className={`card py-1 ${classes.row1Card}`} style={{ height: '90%' }}>
                <div className="card-body pb-5 dashboard_barchart">
                  <h6 className={`card-title my-0 fw-bold pb-3`}>Category</h6>
                  {ChartCategory.length > 0 ? <p style={{ float: 'right' }}></p> : <p className={`${classes[companyColor]}`} style={{ float: 'right' }}>NO ITEM FOUND</p>}
                  <Grid item xs={12}>
                    <Grid container spacing={5}  >
                      <Grid item xs={12} className="pt-0">
                        <ResponsiveContainer width="100%" height={215}  >
                          <BarChart width={230} height={250} data={ChartCategory.slice(0, 10)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="">
                              <Label value="TOP 10 CATEGORIES" offset={0} position="insideBottom" />
                            </XAxis>
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="Quantity">
                              {
                                ChartCategory.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={color[index]} />
                                ))
                              }
                              <LabelList dataKey="CATEGORY" position="outside" angle={0} dy={-10}  style={{ fill: '#111', fontSize: 12 }}/>
                            </Bar>
                          </BarChart>

                        </ResponsiveContainer>
                      </Grid>
                     
                    </Grid>
                  </Grid>


                </div>
              </div>
            </Grid> */}
          </Grid>
        </Grid>

      </Grid>
      {/* <Grid container spacing={5} className="my-2 Graphdata fullwidth" >
        <div className="d-flex chart_header">
          <div><h6 className={`card-title my-0 fw-bold`}>Revenue Generated </h6></div>
          <div>
            <button id="currentyearbtn" style={{ background: '#2fbaa8' }} className={`  ${classes.dashSideBtn}  ${classes.rastorant_mainColor} ${classes[companyColor + 'BG']}`}
      
            ></button><span style={{ padding: '10px' }}>Current Year</span>
            <button id="lastyearbtn" style={{ background: '#8ba7e5' }} className={` ${classes.dashSideBtn}  ${classes.rastorant_mainColor} ${classes[companyColor + 'BG']}`}
      
            ></button><span style={{ padding: '10px' }}>Last Year</span>

          </div>
        </div>
        <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
          <ResponsiveContainer width="100%" height={250}>
            <AreaChart data={cumulativeArr} margin={{ top: 10, right: 0, left: 10, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorUvLastYear" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPvLastYear" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey={(data) => getMonthName(data.Mth)} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area type="monotone" dataKey="CurrentYearSale" stroke="#2fbaa8" fillOpacity={0.5} fill="#2fbaa8" />
              <Area type="monotone" dataKey="CurrentYearPurchase" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              <Area type="monotone" dataKey="LastYearSale" stroke="#8ba7e5" fillOpacity={0.5} fill="#8ba7e5" />
              <Area type="monotone" dataKey="LastYearPurchase" stroke="#82ca9d" fillOpacity={0.5} fill="url(#colorPvLastYear)" />
            </AreaChart>
          </ResponsiveContainer>

          {updatedData.length === 0 ? <p className={`${classes[companyColor]}`} style={{ float: 'right' }}>NO DATA FOUND</p> : ""}
        </Grid>


    
        <div className={popupclass}>
          <div className="popupbg" >
            <section className='popup-main'>
              <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                <h4 style={{ color: "#fff" }}>{popupHeader}</h4>
                <p style={{ color: "#fff" }} className="crossicon" onClick={handleclosepopup}>&#10006;</p>
              </div>

              <div className="table-responsive">
                {
                  pendingreqpopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={pendingRFQspopupdata}
                        columns={pendingRFQsclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
        
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = pendingRFQspopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
        

                        }}
                        {...pendingRFQspopupdata}
                      />
                    </div>

                    :
                    ""}

                {
                  currentlivepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={CurrentLiveEnquirispopupdata}
                        columns={currentliveenquryclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
              
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = CurrentLiveEnquirispopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
      

                        }}
                        {...CurrentLiveEnquirispopupdata}
                      />
                    </div> : ""
                }
                {
                  pendingPOpopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={Pendingpopopupdata}
                        columns={currentliveenquryclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
            
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = Pendingpopopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );
                          setSelectedRows(selectedRows);
                        }}
                        {...Pendingpopopupdata}
                      />
                    </div> : ""
                }
                {
                  dibitorbalancepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={debitorbalancedata}
                        columns={debitorbalancedataclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.MCODE}

                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = debitorbalancedata.filter((row) =>
                            selectedIDs.has(row.MCODE),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);

                        }}
                        {...debitorbalancedata}
                      />
                    </div> : ""
                }
                {
                  crediorbalancepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={creditorbalancedata}
                        columns={debitorbalancedataclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.MCODE}
          
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = creditorbalancedata.filter((row) =>
                            selectedIDs.has(row.MCODE),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                      
                        }}
                        {...creditorbalancedata}
                      />
                    </div> : ""
                }

              </div>
            </section>
          </div>
        </div>
      
        <div className="d">
        </div>
      </Grid> */}
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  const array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  const resultArray = [];
  const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  const mobile = getRandomData(31, 1500, 7500, 7500, 1500);


  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}