import React, { useEffect, useState } from 'react';
import noti from '../../images/hrx.png'


const Alertmessage = (props) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setShowAlert(true)
  }, [props.alertmsg]);

 const handleclose =()=>{

 }

  // function handleMouseEnter() {
  //   setShowAlert(true);
  // }

  // function handleMouseLeave() {
  //   setShowAlert(false);
  // }

  return (
    <div className={`custom-alert ${showAlert ? 'show' : 'hide'}`} style={{ background: props.bgcolor }}>
      <div style={{ width: "", height: "", display: "flex",justifyContent:'space-between', alignItems: "right" }}>
        <p>{props.alertmsg}</p>
        <p style={{cursor:'pointer'}} onClick={props.onClose}>&times; </p>
      </div>
      
    
    </div>
  );
};

export default Alertmessage;
