import React, { useState } from "react";
// import { Line } from "react-chartjs-3";
import { useHistory } from 'react-router-dom';
import { BrowserRouter , Route, Switch, Redirect, Link } from "react-router-dom";
import './App.css'
import {
  Grid,TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,InputLabel
} from "@material-ui/core";
// pages
import Error from "./commonModule/error/Error";
import Login from "./commonModule/login/Login";
// context
import Linechart from "./Utiles/Chart/linechart";
import POSDashboard from "./CompanyType/CompanyType_POS/dashboard/Dashboard";
import RanstorantDashboard from "./CompanyType/CompanyType_RESTORANT/dashboard/Dashboard";
import PRODUCTIONDashboard from "./CompanyType/CompanyType_Production/dashboard/Dashboard";
import OrderDashboard from "./CompanyType/CompanyType_ORDER/dashboard/Dashboard";
// import WidgetsIcon from '@mui/icons-material/Widgets';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Sidebar from "./Utiles/Sidebar/sidebar/Sidebar";
import ProtectedRoute from "./ProtectedRoute";
import SidebarOrder from "./CompanyType/CompanyType_ORDER/Sidebar/sidebar/Sidebar";
import Reports from './pages/MIS/Reports';
import RastorantSideBar  from "./CompanyType/CompanyType_RESTORANT/Sidebar/sidebar/Sidebar"
import SidebarPOS  from "./CompanyType/CompanyType_POS/Sidebar/sidebar/Sidebar"
import PoductionSidebar from "./CompanyType/CompanyType_Production/Sidebar/sidebar/Sidebar"
import {COMPANY_TYPE} from './constant';
import { useEffect } from "react";
import Help from "./pages/Help/Help";
import CategoryDashboard from "./pages/CategoryDashboard/CategoryDashboard";
import ItemDashBoard from "./pages/ItemDashboard/ItemDashboard";
import ClientList from "./pages/ClientList/ClientList";
import ItemsList from "./pages/ItemsList/ItemsList";
import Billing from "./pages/Billing/Billing";
import CreateClient from "./pages/CreateClient/CreateClient";
import Demo from './pages/MIS/GetUserlocation'
// import {animateWidth} from '../src/CompanyType/Animations/Animation'

export default function App() {
  const history = useHistory();
  const [leftnav,setleftnav] = useState("scroller"); 
  const [shouldUpdate, setShouldUpdate] = useState(true);

 const [compnyType,setCompanyType] = useState(localStorage.getItem("cmpnyType")?.split(',')[0]); 
//const [compnyType,setCompanyType] = useState("ORDER_MANAGEMENT"); 

const [logoutpopup, setlogoutpopup] = useState(false)

  const  togglesidebar =() =>{
    setleftnav("scrollerfull");
    if(leftnav === "scrollerfull"){
      setleftnav("scroller")
    }
  }
  const handleLogout = (isLoggedOut) => {
    setlogoutpopup(isLoggedOut)
  };

  const handlenologut = ()=>{
    setlogoutpopup(false)
  }
  const handleYEslogut = ()=>{
    localStorage.removeItem("username");
    localStorage.removeItem("cmpnyNo");
    localStorage.removeItem("cmpnyType");
    window.location.href ="/login";
    setlogoutpopup(false)
  }
  

  const handleResize = () => {
    setShouldUpdate(false);
    setTimeout(() => {
      setShouldUpdate(true);
    }, 500);
  };

  const RouteChangeAlert = () => {
    const history = useHistory();
  
    useEffect(() => {
      const unlisten = history.listen(() => {
    
    handleResize()

      });
  
      return () => {
        unlisten();   // cleanup function 
      };
    }, [history]);
  
    return null;
  }

  useEffect(()=>{
    handleResize()


   var comnytyp =  localStorage.getItem("cmpnyType");
   
 
  },[compnyType ])
  if(compnyType==="" || compnyType === null)
  {
  return (
    <BrowserRouter>
    <Switch>
    {/* <Route exact path="/" component={CompanyDetails}  /> */}
    {/* <Route exact path="/" component={Login}  /> */}
    <Route exact path="*" component={Login}  />
    </Switch>
    </BrowserRouter>
  );
  }
  else if(compnyType?.toUpperCase()===COMPANY_TYPE.POS)
  {
  return (
      <BrowserRouter>
      <RouteChangeAlert />
        <Switch>
        <Route exact path="/" component={Login}  />
        <Route exact path="/login" component={Login}  />
        <div className={leftnav}>
        <Grid container spacing={5} >
        <span className="TOGGLEicon" onClick={togglesidebar}>{leftnav === "scrollerfull"? <RemoveCircleIcon name="apps-outline"></RemoveCircleIcon>:  <CancelIcon name="apps-outline"></CancelIcon>} </span>
          {
            leftnav  === "scroller" ? < SidebarPOS   onLogout={handleLogout}/>:""
          }  
          <Grid  item lg={12} md={12} sm={12} xs={12}   >
          {logoutpopup ?
        <p className="paraLogout"><div><span className="logoutmsg">Are you sure you want to logout ?</span></div><br/> <div><button onClick={handleYEslogut} style={{ background:'#2fbaa8'}}>Yes</button> <button onClick={handlenologut} style={{ background:'#2fbaa8'}}>No</button></div></p>
        :"" } 
                <ProtectedRoute  exact path="/dashboard" component={POSDashboard} />
                <ProtectedRoute path="/:reportType" component={Reports} />
                  <Route path="/help" component={Help} />
                  <Route path="/categorydashboard" component={CategoryDashboard}/>
                  <Route path="/ItemDashBoard" component={ItemDashBoard}/>
                  <Route path="/ClientList" component={ClientList}/>
                  <Route path="/ItemsList" component={ItemsList}/>
                  <Route path="/Billing" component={Billing}/>
                  <Route path="/CreateClient" component={CreateClient}/>
                  <Route path="/demo" component={Demo}/>
                </Grid>
                </Grid>
        </div>
        </Switch>
       </BrowserRouter>
  
  );
  } 
  else if(compnyType?.toUpperCase()===COMPANY_TYPE.RESTAURANT)
  {
  return (
      <BrowserRouter>
        <Switch>
        <Route exact path="/" component={Login}  />
        <Route exact path="/login" component={Login}  />
        <div className={leftnav}>
        <Grid container spacing={5} >
        <span className="TOGGLEicon" onClick={togglesidebar}> {leftnav === "scrollerfull"? <RemoveCircleIcon name="apps-outline"></RemoveCircleIcon>:  <CancelIcon name="apps-outline"></CancelIcon>} </span>
        {/* <CancelIcon name="apps-outline"></CancelIcon></span> */}
          {
            leftnav  === "scroller" ? < RastorantSideBar onLogout={handleLogout}/>:""
          }  
          <Grid  item lg={12} md={12} sm={12} xs={12}    className="fullheightwidth">
          {logoutpopup ?
        <p className="paraLogout"><div><span className="logoutmsg">Are you sure you want to logout ?</span></div> <div><button onClick={handleYEslogut} style={{ background:'#fb7004'}}>Yes</button> <button onClick={handlenologut} style={{ background:'#fb7004'}}>No</button></div></p>
        :"" } 
                <ProtectedRoute  exact path="/dashboard" component={RanstorantDashboard} />
                <Route path="/:reportType" component={Reports} />
                <Route path="/help" component={Help} />
                <Route path="/categorydashboard" component={CategoryDashboard}/>
                <Route path="/ItemDashBoard" component={ItemDashBoard}/>
                <Route path="/ClientList" component={ClientList}/>
                <Route path="/ItemsList" component={ItemsList}/>
                <Route path="/Billing" component={Billing}/>
                <Route path="/CreateClient" component={CreateClient}/>
                {/* <Route path="/projects" component={Project} />
                <Route path="/projectsDesc" component={ProjectDetails} />
                <Route path="/state_city" component={StateCity} />
                <Route path="/employees" component={EmployeeList} />
                <Route path="/Reports" component={ClientList} />
                <Route path="/items" component={Items} />
                <Route path="/categories" component={Categories} />  */}
                </Grid>
                </Grid>
        </div>
       
        </Switch>
       </BrowserRouter>
  
  );
  } 
  else if(compnyType?.toUpperCase()===COMPANY_TYPE.PRODUCTION)
  {
  return (
      <BrowserRouter>
        <Switch>

        <Route exact path="/" component={Login}  />
        <Route exact path="/login" component={Login}  />
        <div className={leftnav}>
        <Grid container spacing={5} >
        <span className="TOGGLEicon" onClick={togglesidebar}>{leftnav === "scrollerfull"? <RemoveCircleIcon name="apps-outline"></RemoveCircleIcon>:  <CancelIcon name="apps-outline"></CancelIcon>} </span>
          {/* <CancelIcon name="apps-outline"></CancelIcon></span> */}
          {
            leftnav  === "scroller" ? < PoductionSidebar onLogout={handleLogout}  />:""
          }  
      <Grid  item lg={12} md={12} sm={12} xs={12}   >
        {logoutpopup ? 
        
        <p className="paraLogout"><div><span className="logoutmsg" >  Are you sure you want to logout ?</span></div> 
        <div><button onClick={handleYEslogut} style={{ background:'#4c86ce'}}>Yes</button> <button onClick={handlenologut} style={{ background:'#4c86ce'}}>No</button> 
        {/* <CancelIcon name="apps-outline"></CancelIcon> */}
        </div></p>
        :"" }  
        <Route  exact path="/dashboard" component={PRODUCTIONDashboard} />
        <Route path="/:reportType" component={Reports} />
        <Route path="/help" component={Help} />
        <Route path="/categorydashboard" component={CategoryDashboard}/>
        <Route path="/ItemDashBoard" component={ItemDashBoard}/>
        <Route path="/ClientList" component={ClientList}/>
        <Route path="/ItemsList" component={ItemsList}/>
        <Route path="/Billing" component={Billing}/>
        <Route path="/CreateClient" component={CreateClient}/>
        </Grid>
        </Grid>
        </div>

        </Switch>
       </BrowserRouter>
  
  );
  } 
  else if(compnyType?.toUpperCase()===COMPANY_TYPE.ORDER_MANAGEMENT){
    return(
        <BrowserRouter>
          <Switch>
          <Route exact path="/" component={Login}  />
          <Route exact path="/login" component={Login}  />
          <div className={leftnav}>
          <Grid container spacing={5} >
          <span className="TOGGLEicon" onClick={togglesidebar}>{leftnav === "scrollerfull"? <RemoveCircleIcon name="apps-outline"></RemoveCircleIcon>:  <CancelIcon name="apps-outline"></CancelIcon>} </span>
            {/* <CancelIcon name="apps-outline"></CancelIcon></span> */}
            {
     leftnav  === "scroller" ? < SidebarOrder/>:""

            }  
            <Grid  item lg={12} md={12} sm={12} xs={12}   >
            {logoutpopup ?
        <p className="paraLogout"><div><span className="logoutmsg">Are you sure you want to logout ?</span></div> <div><button onClick={handleYEslogut} style={{ background:'#0b5aa4'}}>Yes</button> <button onClick={handlenologut} style={{ background:'#0b5aa4'}}>No</button></div></p>
        :"" }

                  <Route  exact path="/dashboard" component={OrderDashboard} />
                  <Route path="/:reportType" component={Reports} />
                  </Grid>
                  </Grid>
          </div>
          </Switch>
         </BrowserRouter>
   
    );
  }
  else{
    return (
      <BrowserRouter>
      <Switch>
      {/* <Route exact path="/" component={CompanyDetails}  /> */}
      {/* <Route exact path="/" component={Login}  /> */}
      <Route exact path="*" component={Login}  />
      </Switch>
      </BrowserRouter>
    );
    
  }
 
}
