import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, TextField,
  Checkbox
} from "@material-ui/core";
import { useHistory } from "react-router-dom"
import { useTheme } from "@material-ui/styles";
import ComingSoonPopup from '../PopUP/PopUp';
// import { CSVLink } from "react-csv";
import useStyles from "./style";
import ReportTbl from '../CollectionReport/ReportTbl';
// styles

import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import {GET_PRICED_STOCK_LEDGER_GET_ITEM,GET_PRICED_STOCK_LEDGER_ORDER_TYPE,GET_PRICED_STOCK_LEDGER,COMPANY_CODE,GET_GROUP_SELECTION ,GET_ALL_WAREHOUSES,  } from '../../../constant';
import axios from "axios";

export default function Client(props) {
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const[popup,setpopup]=useState(false)
  const [isSelectAc, setIsSelectAc] = useState(false);
  const [ldgReport, setLdgReport] = useState([]);
  const [tabledata, settabledata] = useState([]);
  const [ldgAccList, setLdgAccList] = useState([]);
  const [ldgAcList, setLdgAcList] = useState([]);
  const [isLoading, setisLoading] = useState(false)
  const [selectedLdgAccIndex, setSelectedLdgAccIndex] = useState(0);

  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(today)    // need to set here today variable 
  const [enddate, setenddate] = useState(nextday);
  const [ChartCategory, setChartCategory] = useState([])
  const [pricestock,setpricestock] = useState([])
  const [transactionType,settransactionType] = useState([])
  const [ordertype,setordertype] = useState([])
  const[groupselection,setgroupselection] = useState([])
  const [getitem,setgetitem] = useState([])
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedwarehouse, setcheckedwarehouse] = useState([]);
  const [checkedtransactionTYp, setcheckedtransactionTYp] = useState([]);
  const [isCheckedCategory,setisCheckedCategory] = useState(false)
  const [SelectedToValue,setSelectedToValue] = useState("")
  const [SelectedfromValue,setSelectedfromValue] = useState("")
  const[Selectedwarehouse,setSelectedwarehouse] = useState("")
  const[Selectedtype,setSelectedtype] = useState("")
  const[Selectedselection,setSelectedselection] = useState("")
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  

  const [rowData, setRowData] = useState([]);
  const [warehouse, setwarehouse] = useState([]);
  const [checkedTypeItems, setCheckedTypeItems] = useState([]);
  const [checkedCategoryItems, setCheckedCategoryItems] = useState([]);

  const [companyColor, setcompanyColor] = useState("")
 const [pricestockClm ,setpricestockClm] = useState([])
   
  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
    //document.querySelector('.TOGGLEicon').click();
    
  }, []);

  const handleTypeCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    setCheckedTypeItems(prevState => {
      if (isChecked) {
        return [...prevState, item];
      } else {
        return prevState.filter(selectedItem => selectedItem.BCD !== item.BCD);
      }
    });
  };

  const handleCategoryCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    setCheckedCategoryItems(prevState => {
      if (isChecked) {
        return [...prevState, item];
      } else {
        return prevState.filter(selectedItem => selectedItem.ABVR !== item.ABVR);
      }
    });
  };

  const handleViewButtonClick = () => {
    // if (!Selectedwarehouse) {
    //   alert('Please select a warehouse.');
    //   return;
    // }
    setisLoading(true); 
    var categories =Array.from(document.querySelectorAll("#categorydata input:checked")).map((it) => it.value);
   
    console.log(categories)
    console.log(SelectedfromValue)
    axios.post(GET_PRICED_STOCK_LEDGER, 
      {
      "CompanyNumber": COMPANY_CODE,
      "Item": SelectedfromValue,
      "Period": SelectedToValue,
      "FormDate": startdate,
      "ToDate": enddate,
      "strWh": checkedwarehouse.join(','),
      "Type":Selectedtype,
      "Category":categories.join(','),
     
    })
      .then((response) => {
        console.log("datataa",response)
        if (response.data.IsSuccess === true) {    
          var  mydata = response.data.Data.map((item,i)=>({...item,sno:i+1}))

          var obj ={}
          var priceHeader =[]
          var keys = Object.keys(mydata[0])
        keys.forEach((data,i)=>{
         var newobject = { field: data, headerName: data, width: 150 }

         priceHeader.push(newobject)
        })

         setpricestockClm(priceHeader)
          setRowData(mydata);
        }
        setisLoading(false); // Hide loading indicator
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
        setisLoading(false); 
      });
  };
  
  useEffect(() => {

      /**all warehouses dropdown */
      axios.post(GET_ALL_WAREHOUSES,
        { "CompanyNumber": COMPANY_CODE })
        .then((response) => {
    
          if (response.data.IsSuccess === true) {
            setwarehouse(response.data.Data)
  
          }
        })

    // get order type

   axios.post(GET_PRICED_STOCK_LEDGER_ORDER_TYPE,
    { "CompanyNumber":COMPANY_CODE })
    .then((response) => {
      console.log(" GET ORDER TYPE ")
      console.log(response)
      if (response.data.IsSuccess === true) {
        console.log(response.data.Data);
        setordertype(response.data.Data)

      }
    })

     /** GROUP SELECTION */
     axios.post(GET_GROUP_SELECTION,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log("GROUP SELECTION")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setgroupselection(response.data.Data)

        }
      })
      
    // get item from and from to 

    axios.post(GET_PRICED_STOCK_LEDGER_GET_ITEM,
      { "CompanyNumber":COMPANY_CODE})
      .then((response) => {
        console.log(" GET ITEM  ")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setgetitem(response.data.Data)
  
        }
      })
  }, [startdate, enddate])
    const handleSelectChange = (e) => {
    setSelectedfromValue(e.target.value);
    console.log("Selected Value:", e.target.value);
  };
  const goHome =()=>{
    history.push('/dashboard')
  }
  const openpop =()=>{
    setpopup(true);
   }
   const closePopup = () => {
     setpopup(false);
   };
   
  const handleWarehouseCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    setcheckedwarehouse((prevChecked) => {
      if (isChecked) {
        return [...prevChecked, item.Type];
      } else {
        return prevChecked.filter((selectedItem) => selectedItem !== item.Type);
      }
    });
  };
  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
       {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
      <div className="balacepoupformmain pricedstock_main" >
        <div className="balacepoupformleft" style={{width: "35%"}} >

         
        <div className="row PricedStockLedger_2">
  <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">
    Select Warehouse
  </p>
  <div className="col-sm-12 col-12 col-md-12">
    <div className="scrollerDiv">
      {warehouse.map((item, index) => (
        <div key={index}>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`warehouseCheckbox${index}`}
              name={`warehouseCheckbox${index}`}
              value={item.Type}
              checked={checkedwarehouse.includes(item.Type)}
              onChange={(e) => handleWarehouseCheckboxChange(e, item)}
            />
            <label
              className="form-check-label text-dark my-1"
              htmlFor={`warehouseCheckbox${index}`}
            >
              &nbsp; {item.Type}
            </label>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>
            {/* <div className="row PricedStockLedger_2">
           
                <div className="">
                <select style={{ width:"100%" }} onChange={(e) => setSelectedwarehouse(e.target.value)}>
        <option   value="">Select Warehouse</option>
      
        {warehouse.map((item) => (
          <option key={item.Type} value={item.Type}>
            {item.Type}
          </option>
        ))}
      </select>
              </div>
        
              </div> */}
              <div className="scrolltype">
              <div className="row PricedStockLedger_2">
              <p className="form-check-label text-dark my-1" for="inlineRadio2">Group Selection</p>
              <div className="col-sm-6 col-6 col-md-6">
              <div className="scrollerDiv" id="categorydata">
                {groupselection.map((item, index) => (
      <div key={index}>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`inlineCheckboxGroup${index}`}
            name={`groupselectionCheckbox${index}`}
            value={item.BCD}
          />
          <label className="form-check-label text-dark my-1" htmlFor={`inlineCheckboxGroup${index}`}>
          &nbsp;     {item.DESCRIPTION}
                </label>
        </div>
      </div>
    ))}
                </div>
 
</div>

              <div className="col-sm-6 col-6 col-md-6">
                <p className="form-check-label text-dark my-1" for="inlineRadio2"></p>
                <div className="scrollerDiv">
    {ordertype.map((item, index) => (
      <div key={index}>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`inlineCheckboxType${index}`}
            name={`orderTypeCheckbox${index}`}

            value={item.BCD}
            onChange={(e) => handleTypeCheckboxChange(e, item)}
          />
          <label className="form-check-label text-dark my-1" htmlFor={`inlineCheckboxType${index}`}>
          &nbsp; {item.DESCRIPTION}
          </label>
        </div>
      </div>
    ))}
  </div>
                
              </div>
            </div>
          </div>
        
          <div className="row PricedStockLedger_2">
          <div className="mb-1 PricedStockLedger_2grid">
      
            <span style={{ color: '#000' }}>Item From &nbsp;</span>
            {/* <select  style={{width:'76%'}}  onChange={(e) => setSelectedfromValue(e.target.value)}  >
              <option value="">Please select any value</option>
            </select> 
            */}
            <select style={{ width: '76%' }} onChange={(e) => setSelectedfromValue(e.target.value)}>
        <option value="">Please select any value</option>
        {/* Mapping the data from the API response to create options */}
        {getitem.map((item) => (
          <option key={item.SN} value={item.ITEM}>
            {item.ITEM}
          </option>
        ))}
      </select>
            <br/>
            <br />
            <span style={{ color: '#000' }}>Item To &nbsp;&nbsp; &nbsp;&nbsp;</span>
            <select  style={{width:'76%'}}  onChange={(e) => setSelectedToValue(e.target.value)}>
              <option value="">Please select any value</option>
              {getitem.map((item) => (
          <option key={item.SN} value={item.ITEM}>
            {item.ITEM}
          </option>
        ))}
            </select>
          </div>
          </div>
           <div className="row PricedStockLedger_2">
          <div className="mb-2 PricedStockLedger_2grid">
        
            <span style={{ color: '#000' }}>Report Type &nbsp;&nbsp;&nbsp;</span>
            <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="inlineRadioOption" id="inlineRadio1" value="AToA" defaultChecked={true} disabled />
              <label className="form-check-label text-dark" for="inlineRadio1">Detail</label>

            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOption" id="inlineRadio2" value="Selected" disabled />
              <label className="form-check-label text-dark" for="inlineRadio2">Summary</label>
            </div>
           </div> 
          </div>
          <div className="row PricedStockLedger_2">
          <div className="mb-1 PricedStockLedger_2grid">
          <input className="form-check-input" type="checkbox" disabled />
              <label className="form-check-label text-dark" >&nbsp;&nbsp;Print Item Specification</label>

          </div>
          </div>
          <div className="row PricedStockLedger_2">
          <div className="mb-2 PricedStockLedger_2grid">
            <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="AToA"  />
              <label className="form-check-label text-dark" for="inlineRadio1">Inwards Only</label>

            </div>
            <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="AToA" />
              <label className="form-check-label text-dark" for="inlineRadio1">Outwards</label>

            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="Selected"  defaultChecked={true}/>
              <label className="form-check-label text-dark" for="inlineRadio2">Both</label>
            </div>
          </div>
          </div>
          
          <div className="row PricedStockLedger_2">
          <div className="mb-2 PricedStockLedger_2grid">
            <span style={{ color: '#000' }}>Print To &nbsp;</span>
            <select  style={{width:'80%'}} disabled>
              <option value="" ></option>
              {/* {
                specificParty?.map((item,i)=>(
                  <option value={item.Mcode}>{item.Name}</option>
                ))
              } */}
            </select>
          </div>
          </div>
        
          <div className="row PricedStockLedger_2">
            <div className="col-sm-6 col-6 col-md-6">
              <div className="mb-3">
                <h6 className="form-check-label text-dark my-1 " for="inlineRadio2">From Date :</h6>
                <input type="date" className="form-control" id="FDate" name="FormDate" value={startdate}
                  onChange={(e) => setstartdate(e.target.value)} />
              </div>
            </div>
            <div className="col-sm-6 col-6 col-md-6">
              <div className="mb-3">
                <h6 className="form-check-label text-dark my-1" for="inlineRadio2">To Date :</h6>
                <input type="date" className="form-control" id="ToDate" name="ToDate" value={enddate}
                  onChange={(e) => setenddate(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="row PricedStockLedger_2" style={{position:'sticky',bottom:"0px"}}>
          <div className="d-flex mt-2" style={{ width: '89%', }}>

            <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} onClick={openpop}>EXCEL </Button>&nbsp;
            <Button variant="contained"   className={classes[companyColor?.split(',')[0]]} onClick={handleViewButtonClick}>VIEW</Button>&nbsp;
            <Button variant="contained"  className={classes[companyColor?.split(',')[0]]}onClick={openpop}>PDF</Button>&nbsp;
            <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} onClick={goHome}>EXIT</Button>
          </div>
          </div>

        </div>
        <div className="balacepoupformright">
                {pricestockClm.length > 0 ? (
            <ReportTbl
            dataArray={rowData}
            TblColumns={pricestockClm}
            headerColor={companyColor}
            
            />
        ) : (
          <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
            <thead className={classes[companyColor?.split(',')[0]]}> 
              <tr>
                <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>
      
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
              
              </tr>
            </tbody>
          </table>
        )}
        </div>
        {/* <div className="balacepoupformright">
          <div className='table animatingBottom' style={{ height: "570px", width: "100%" }}>
            <DataGrid
              rows={rowData}
              columns={pricestockClm}
          autoPageSize
              className={`${classes[companyColor?.split(',')[0]]}`}
              getRowId={(row) => row.sno}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = tabledata.filter((row) =>
                  selectedIDs.has(row.Voucher),
                );
              }}
            />
            
          </div>

        </div> */}
      </div>
    </>
  );
}

