import React, { useState } from "react";
import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
    Button, TextField,
    Checkbox
} from "@material-ui/core";
import { useHistory } from "react-router-dom"
import { useTheme } from "@material-ui/styles";
// import { CSVLink } from "react-csv";
import useStyles from "./style";
import ComingSoonPopup from '../PopUP/PopUp';
// styles

import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { GET_All_ACCOUNT, LOGIN_DETAILS, GET_LEDGER_REPORT, GET_GRAPH_DATA, GET_CATEGORY_SALE_REPORT, COMPANY_CODE, GET_ALL_WAREHOUSES, GET_ALL_TRANSATIN_TYPE, GET_ALL_PARTY, GET_ALL_SPECIFIC_PARTY, GET_PARTY_STOCK_REPORT, GET_GROUP_SELECTION, GET_SALE_REGISTER, GET_PRICED_STOCK_LEDGER_ORDER_TYPE } from '../../../constant';
import axios from "axios";
import ReportTbl from '../CollectionReport/ReportTbl';

export default function Client(props) {
    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const [isSelectAc, setIsSelectAc] = useState(false);
    const [ldgReport, setLdgReport] = useState([]);
    const [tabledata, settabledata] = useState([]);
    const [ldgAccList, setLdgAccList] = useState([]);
    const [ldgAcList, setLdgAcList] = useState([]);
    const [isLoading, setisLoading] = useState(false)
    const [selectedLdgAccIndex, setSelectedLdgAccIndex] = useState(0);
    const[popup,setpopup]=useState(false);
    var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
    var toda = new Date();
    var tomorrow = new Date(toda);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var nextday = tomorrow.toISOString().slice(0, 10);
    const [startdate, setstartdate] = useState(today)    // need to set here today variable 
    const [enddate, setenddate] = useState(nextday);
    const [warehouse, setwarehouse] = useState([])
    const [groupselection, setgroupselection] = useState([])


    const [specificParty, setspecificParty] = useState([])
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkedwarehouse, setcheckedwarehouse] = useState([]);
    const [checkedtransactionTYp, setcheckedtransactionTYp] = useState([]);
    const [isCheckedCategory, setisCheckedCategory] = useState(false)
    const [SelectedToValue, setSelectedToValue] = useState("")
    const [SelectedfromValue, setSelectedfromValue] = useState("")
    const [selectedWarehouse, setSelectedWarehouse] = useState(false);
    const [pricestockClm, setpricestockClm] = useState([])
    const [rowData, setRowData] = useState([]);
    const [ordertype, setordertype] = useState([]);
    const [checkedTypeItems, setCheckedTypeItems] = useState([]);
    const [saleregisterdata, setsaleregisterclm] = useState([])



    const [companyColor, setcompanyColor] = useState("")
    const saleregisterrow = [
        {
            field: "CreatedDate",
            headerName: "Created AT",
            width: 95,
            disableClickEventBubbling: true,
            renderCell: (params) => {

                return (
                    <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
                );
            }
        },
        { field: 'Voucher', headerName: 'Voucher', width: 150 },
        { field: 'Nart', headerName: 'Naration', width: 150 },
        { field: 'Dabit', headerName: 'Debit', width: 150 },
        { field: 'Credit', headerName: 'Credit', width: 150 },
        { field: 'Currency', headerName: 'Currency', width: 150 },
        { field: 'atVal', headerName: '@', width: 150 },
    ]
    const [ledgerAc, setLedgerAc] = useState({
        "FYear": LOGIN_DETAILS.FINANCIAL_YEAR
    });
    const [ledger, setLedger] = useState({
        "FormDate": new Date(),
        "ToDate": new Date(),
        "chkLocalCurrency": 0,
        "Mcode": ""
    });

    const handleWarehouseCheckboxChange = (event) => {
        setSelectedWarehouse(event.target.checked);
    };
    const handleViewButtonClick = () => {
        // if (!selectedWarehouse) {
        //     alert('Please select a warehouse.');
        //     return;
        // }
        var selectedCategory = Array.from(document.querySelectorAll('#ItemCategory input:checked')).map((c)=>c.value)?.toString() ; 
        // if(selectedCategory == ''){
        //     alert("Please Selected Item Category !")
        //     return
        // } 
        // setisLoading(true);
        console.log(SelectedfromValue)
        setisLoading(true); 
        console.log("select warehouse", selectedWarehouse)
        var a = document.querySelectorAll('#warehousediv input');
        var Warehouses = Array.from(a).filter((c) => c.checked).map((c) => c.value);
        var categories =Array.from(document.querySelectorAll("#categorydata input:checked")).map((it) => it.value);
        console.log('selectedWarehouses', Warehouses);
        console.log("selected From value", SelectedfromValue)

        var reportViewSelect = document.querySelectorAll('#reportViewPR input:checked')[0].defaultValue;
        var PrintreportSelect = document.querySelectorAll('#printReportofPR input:checked')[0].defaultValue;
        // var printSequencePR = document.querySelectorAll('#printSequencePR input:checked')[0].defaultValue;

        //  we can select directly by its Parent because by default already we are setting 
        // a values in radio button 
        //  and user can only select one value at the time 
        // here we getting all filled data from form and then passing in api 

        console.log("report view ", reportViewSelect)
        console.log("pritn report for", PrintreportSelect)
        // console.log("print SequencePR ", printSequencePR)
         console.log( "selected Category",selectedCategory)

         // now cal api 
        
        axios.post(GET_SALE_REGISTER,
            {
                "CompanyNumber": COMPANY_CODE,
                "FormDate": startdate,
                "ToDate": enddate,
                "DocNo": "O",
                "Detailed": 1,
                "strWh": Warehouses[0],
                "Summarise": reportViewSelect == 'detail' ? 0 : 1,   // user select details [0]  else [1]
                "Ledger": 0,
                "Category":categories.join(','),
                "AccountFrom": SelectedfromValue
            })
            .then((response) => {
                console.log("Sale Register", response)
                if (response.data.IsSuccess === true) {
                    var mydata = response.data.Data.map((item, i) => ({ ...item, sno: i + 1 }))

                    var obj = {}
                    var saleHeader = []
                    var keys = Object.keys(mydata[0])
                    keys.forEach((data, i) => {
                        var newobject = { field: data, headerName: data, width: 150 }

                        saleHeader.push(newobject)
                    })
                    setsaleregisterclm(saleHeader);
                    setRowData(mydata);
                }
                setisLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);

            });
    };

    useEffect(() => {

        //all ware house
        axios.post(GET_ALL_WAREHOUSES,
            { "CompanyNumber": COMPANY_CODE })
            .then((response) => {
                console.log("ALL WARE HOUSE ")
                console.log(response)
                if (response.data.IsSuccess === true) {
                    console.log(response.data.Data);
                    setwarehouse(response.data.Data)

                }
            })

        // ITEM CATEOGRY

        axios.post(GET_GROUP_SELECTION,
            { "CompanyNumber": COMPANY_CODE })
            .then((response) => {
                console.log("GROUP SELECTION")
                console.log(response)
                if (response.data.IsSuccess === true) {
                    console.log(response.data.Data);
                    setgroupselection(response.data.Data)

                }
            })

        // get order type 
        axios.post(GET_PRICED_STOCK_LEDGER_ORDER_TYPE,
            { "CompanyNumber": COMPANY_CODE })
            .then((response) => {
                console.log(" GET ORDER TYPE ")
                console.log(response)
                if (response.data.IsSuccess === true) {
                    console.log(response.data.Data);
                    setordertype(response.data.Data)

                }
            })
        // get specific party 

        axios.post(GET_ALL_SPECIFIC_PARTY,
            { "CompanyNumber": COMPANY_CODE })
            .then((response) => {
                console.log("get specific party  ")
                console.log(response)
                if (response.data.IsSuccess === true) {
                    console.log(response.data.Data);
                    setspecificParty(response.data.Data)

                }
            })

    }, [startdate, enddate])


    useEffect(() => {
        setcompanyColor(localStorage.getItem('cmpnyType'))
        //document.querySelector('.TOGGLEicon').click();
        var a = window.location.href;
        axios.post(GET_All_ACCOUNT, ledgerAc
        ).then((response) => {
            if (response.data.IsSuccess === true) {
                setLdgAcList(response.data.Data);
            }
            else {
                // var emptList=[];
                setLdgAcList([]);
            }
        })
    }, []);


    const acTypeClicked = (type) => {
        if (type === "Selected") {
            setIsSelectAc(true);
        }
        else {
            setIsSelectAc(false);
        }
        setSelectedLdgAccIndex(0);
        setLedger({ ...ledger, "Mcode": "" });
    }
    const getLedgerReportInNxtBtn = () => {
        setisLoading(true)
        //localStorage.setItem('ldgAccList_index', selectedLdgAccIndex);

        setSelectedLdgAccIndex(selectedLdgAccIndex + 1);
        axios.post(GET_LEDGER_REPORT, {
            "FormDate": ledger.FormDate,
            "ToDate": ledger.ToDate,
            "chkLocalCurrency": ledger.chkLocalCurrency,
            "Mcode": ldgAccList[selectedLdgAccIndex + 1]
        }
        ).then((response) => {
            if (response.data.IsSuccess === true) {
                setLdgReport(response.data.Data);
                setisLoading(false)

            }
            else {
                // var emptList=[];
                setLdgReport([]);
                setisLoading(false)
            }
        })
    }
    const onhandleAcChanges = (e) => {
        setLdgAccList(ldgAccList => [...ldgAccList, e.target.value]);
        setLedger({ ...ledger, "Mcode": e.target.value });
    }
    const getLedgerReport = () => {
        setisLoading(true)
        if (ledger.FormDate === "" || ledger.ToDate === "" || ledger.Mcode === "") {
            alert("Account and from date and to date should not blank.");
            setisLoading(false)
        }
        else {
            axios.post(GET_LEDGER_REPORT,
                {
                    "FormDate": ledger.FormDate,
                    "ToDate": ledger.ToDate,
                    "chkLocalCurrency": ledger.chkLocalCurrency,
                    "Mcode": ldgAccList[0]
                }
            ).then((response) => {
                if (response.data.IsSuccess === true) {
                    setLdgReport(response.data.Data);
                    setSelectedLdgAccIndex(0);
                    setisLoading(false)
                }
                else {
                    // var emptList=[];
                    setLdgReport([]);
                    setisLoading(false)
                }
            })
        }
    }

    function getValues() {
        var checkboxes = document.querySelectorAll('#itemCategryChckbox');
        var itemCategories = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
        itemCategories = itemCategories.map(str => str.replace(/\r?\n|\r/g, '')).join(",")
        //
        var checkboxes = document.querySelectorAll('#warehouseChckbox');
        var warehouses = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
        warehouses = warehouses.map(str => str.replace(/\r?\n|\r/g, '')).join(",")
        //
        var checkboxes = document.querySelectorAll('#transactionChckbox');
        var transactionTypes = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
        transactionTypes = transactionTypes.map(str => str.replace(/\r?\n|\r/g, '')).join(",")



        return [itemCategories, warehouses, transactionTypes];
    }



    const goBackPage = () => {
        //navigate(-1);
        history.goBack();
    }
    const openpop =()=>{
        setpopup(true);
       }
       const closePopup = () => {
         setpopup(false);
       };
       
    const handleLedgerChange = (e) => {
        if (e.target.name === "chkLocalCurrency") {
            setLedger({ ...ledger, [e.target.name]: e.target.checked });
        }
        else {
            if (e.target.name === "Mcode") {
                if (e.target.checked) {
                    setLdgAccList(ldgAccList => [...ldgAccList, e.target.value]);
                }
                else {
                    if (ldgAccList.indexOf(e.target.value) > -1) {
                        ldgAccList.splice(ldgAccList.indexOf(e.target.value), 1);
                    }
                }
            }
            setLedger({ ...ledger, [e.target.name]: e.target.value });
        }
    }

    const handleTypeCheckboxChange = (e, item) => {
        const isChecked = e.target.checked;
        setCheckedTypeItems(prevState => {
            if (isChecked) {
                return [...prevState, item];
            } else {
                return prevState.filter(selectedItem => selectedItem.BCD !== item.BCD);
            }
        });
    };
    const goHome = () => {
        history.push('/dashboard')
    }

    return (
        <>
            {
                isLoading ? <div className='Commonloader'>
                </div> : null
            }
             {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
            <div className="balacepoupformmain saleregister_main" >
                <div className="balacepoupformleft" style={{ width: "35%" }} >


                    <div className="scrolltype">
                        <div className="row Saleregister_2">
                            <div className="row">
                                <div className="col-sm-6 col-6 col-md-6">
                                    <div className="mb-0">
                                        <h6 className="form-check-label text-dark my-1 " style={{ fontSize: '12px' }} for="inlineRadio2">From Date :</h6>
                                        <input type="date" className="form-control" style={{ fontSize: '12px' }} id="FDate" name="FormDate" value={startdate}
                                            onChange={(e) => setstartdate(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-6 col-md-6">
                                    <div className="mb-0">
                                        <h6 className="form-check-label text-dark my-1" style={{ fontSize: '12px' }} for="inlineRadio2">To Date :</h6>
                                        <input type="date" className="form-control" style={{ fontSize: '12px' }} id="ToDate" name="ToDate" value={enddate}
                                            onChange={(e) => setenddate(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row Saleregister_2">
                            <div className="col-sm-6 col-6 col-md-6">
                                <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">WAREHOUSE</p>
                                <div className="scrollerDiv" id="warehousediv">
                                    {warehouse.length === 0 ? "NO warehouse Found" : ""}
                                    {warehouse.map((item, i) => (
                                        <li key={i}>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`warehouseChckbox-${i}`}
                                                value={item.Type}
                                                onChange={handleWarehouseCheckboxChange}
                                            />{' '}
                                            &nbsp;{item.Category}
                                        </li>
                                    ))}
                                </div>

                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">ITEM CATEGORY</p>
                                <div className="scrollerDiv"  id="categorydata">
                                    {groupselection.length == 0 ? "No Item  Found" : ""}
                                    {
                                        groupselection?.map((item, i) => (
                                            <li id="ItemCategory">
                                                <input type="checkbox" className="form-check-input" id="transactionChckbox" name="" value={item.DESCRIPTION} /> &nbsp;{item.DESCRIPTION}</li>
                                        ))
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row Saleregister_2">
                        <div className="mb-0 Saleregister_2grid">
                            <span style={{ color: '#000' }}>Specific Party &nbsp;</span>
                            <select style={{ width: '73%' }} onChange={(e) => setSelectedfromValue(e.target.value)}  >
                                <option value="">Please Select Party</option>

                                {
                                    specificParty?.map((item, i) => (
                                        <option value={item.Mcode}>{item.Name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="row Saleregister_2">
                        <div className="mb-0 Saleregister_2grid" id="reportViewPR">
                            <span style={{ color: '#000' }}>Report View &nbsp;&nbsp;&nbsp;</span>
                            <div className="form-check form-check-inline ">
                                <input className="form-check-input" type="radio" name="inlineRadioOptionsreport" id="inlineRadio1" value="detail" defaultChecked={true} />
                                <label className="form-check-label text-dark" for="inlineRadio1">Detail</label>

                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptionsreport" id="inlineRadio2" value="summary" />
                                <label className="form-check-label text-dark" for="inlineRadio2">Summary</label>
                            </div>
                        </div>
                    </div>


                    <div className="row Saleregister_3">
                        <div className="mb-0 Saleregister_2grid" id="printReportofPR">
                            <span style={{ color: '#000' }}>Print Report For &nbsp;</span>
                            <div className="form-check form-check-inline ">
                                <input className="form-check-input" type="radio" name="inlineRadioOption" id="" value="passed" defaultChecked={true} />
                                <label className="form-check-label text-dark" for="inlineRadio1">Passed</label>

                            </div>
                            <div className="form-check form-check-inline ">
                                <input className="form-check-input" type="radio" name="inlineRadioOption" id="" value="unpassed" />
                                <label className="form-check-label text-dark" for="inlineRadio1">Un-Passed</label>

                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOption" id="" value="all" />
                                <label className="form-check-label text-dark" for="inlineRadio2">ALL</label>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ fontSize: '12px', padding: '1px', background: '#e7e7ec', color: '#111' }}>
                        <div className="col-sm-4 col-4 col-md-4">
                            <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">Order Type</p>
                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">Challan Services</p>
                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">Nature</p>
                        </div>
                    </div>
                    <div className="scrolltype">
                        <div className="row Saleregister_2">
                            <div className="col-sm-4 col-4 col-md-5">
                                <div className="scrollerDiv" id="bottomBox1">
                                    {ordertype.map((item, index) => (
                                        <div key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`inlineCheckboxType${index}`}
                                                    name={`orderTypeCheckbox${index}`}

                                                    value={item.BCD}
                                                    onChange={(e) => handleTypeCheckboxChange(e, item)}
                                                />
                                                <label className="form-check-label text-dark my-1" htmlFor={`inlineCheckboxType${index}`}>
                                                    &nbsp; {item.DESCRIPTION}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <div className="col-sm-4 col-4 col-md-4">

                                <div className="scrollerDiv">

                                    {/* {
               transactionType?.map((item ,i)=>(
                 <li>
                 <input type="checkbox" className="form-check-input" id="transactionChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
               ))
             } */}
                                </div>

                            </div>
                            <div className="col-sm-4 col-4 col-md-3">
                                <div className="scrollerDiv">
                                    {/* {
                transactionType?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" className="form-check-input" id="transactionChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
                ))
              } */}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row Saleregister_2">
                        <div className="mb-0 PricedStockLedger_2grid" id="printSequencePR">
                            <span style={{ color: '#000' }}>Print Sequence &nbsp;</span>
                            <div className="form-check form-check-inline ">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="challan" defaultChecked={true} />
                                <label className="form-check-label text-dark" for="inlineRadio1">Challan Date</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="passingDT" />
                                <label className="form-check-label text-dark" for="inlineRadio2">Passing Date</label>
                            </div>
                        </div>
                    </div>
                    <div className="row Saleregister_4">
                        <div className="mb-0 Saleregister_2grid">
                            <input className="form-check-input" type="checkbox" />
                            <label className="form-check-label text-dark" >&nbsp;&nbsp;Print Claimable Amount In &nbsp;&nbsp;</label>
                            <input className="form-check-input" type="checkbox" />
                            <label className="form-check-label text-dark" >&nbsp;&nbsp;PrintOverHeadAccount</label>

                        </div>
                        <div className="mb-0 Saleregister_2grid">
                            <input className="form-check-input" type="checkbox" />
                            <label className="form-check-label text-dark" >&nbsp;&nbsp;Print Party Address &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <input className="form-check-input" type="checkbox" />
                            <label className="form-check-label text-dark" >&nbsp;&nbsp;Print Shipment Details</label>

                        </div>
                    </div>
                    {/* <div className="mb-2">
            <span style={{ color: '#000' }}>Print To &nbsp;</span>
            <select  style={{width:'80%'}}>
              <option value="">Select Specific Item</option>
              {
                specificParty?.map((item,i)=>(
                  <option value={item.Mcode}>{item.Name}</option>
                ))
              }
            </select>
          </div> */}
                    <div className="row Saleregister_2" style={{ position: 'sticky', bottom: "0px" }}>
                        <div className="d-flex mt-2" style={{ width: '89%' }}>

                            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>EXCEL </Button>&nbsp;
                            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={handleViewButtonClick}>VIEW</Button>&nbsp;
                            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>PDF</Button>&nbsp;
                            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={goHome}>EXIT</Button>
                        </div>
                    </div>

                </div>

                <div className="balacepoupformright">
                    {saleregisterdata.length > 0 ? (
                        <ReportTbl
                            dataArray={rowData}
                            TblColumns={saleregisterdata}
                            headerColor={companyColor}

                        />
                    ) : (
                        <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
                            <thead className={classes[companyColor?.split(',')[0]]}>
                                <tr>
                                    <th style={{ height: "50px", padding: "8px", border: "1px solid #ccc" }}></th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: "8px", border: "1px solid #ccc", color: "#000", }}> No data&nbsp;</td>

                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>

            </div>
        </>
    );
}

