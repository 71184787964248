import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import useStyles from "../PartyStockLedger/style";
import ReportTbl from '../CollectionReport/ReportTbl';
import { COMPANY_CODE, GET_ALL_WAREHOUSES, GET_ALL_CATEGORIES, GET_VIEW_ACCOUNT_REPORT,GET_STOCK_STATUS_REPORT,GET_CATEGORY_STOCK_STATUS } from '../../../constant';
import axios from 'axios';
import ComingSoonPopup from '../PopUP/PopUp'
import { useFinancialYear } from "../FinancialYearContext";

function StockStatus() {
  const classes = useStyles();
  const { financialYear } = useFinancialYear();
  const history = useHistory();
  var today = new Date().toISOString().slice(0, 10); 
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [fromdate, setfromdate] = useState(financialYear.financialYearStart)
  const [todate, settodate] =  useState(financialYear.financialYearEnd);

  const[warehouse,setwarehouse]=useState([])
  const [stockstatus, setstockstatus] = useState([]);
  const [companyColor, setcompanyColor] = useState("")
  const [AllCategories,SetAllCategories] =useState([])
  const [selectedValue, setSelectedValue] = useState('');
  const [SelectedCAtegoryValue, setSelectedCAtegoryValue] = useState('');
  const[popup,setpopup]=useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const[stockstatementdata,setstockstatementclm]=useState([]);
  const [rowData, setRowData] = useState([]);
  const [isLoading,setisLoading] = useState(false);
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);


  useEffect(() => {
    axios.post(GET_ALL_WAREHOUSES,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log("ALL WAREHOUSE ")
         
        if (response.data.IsSuccess === true) {
          setwarehouse(response.data.Data)
  
        }
      })
    setcompanyColor(localStorage.getItem('cmpnyType'))
    //API FOR GET ALL CATEGORIES
    axios.post(GET_CATEGORY_STOCK_STATUS, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
      console.log(response)
      if (response.data.IsSuccess === true) {
        SetAllCategories(response.data.Data);
      }
      else {
        // var emptList=[];
        SetAllCategories([]);
      }
    })

  }, [])

  const stockstatusclm = [
    { field: 'Category', headerName: 'Category', width: 150 },
    { field: 'Closing Quantity', headerName: 'Closing Quantity', width: 150 },
    { field: 'Closing Value', headerName: 'Closing Value', width: 150 },
    { field: 'ICODE', headerName: 'ICODE', width: 150 },
    { field: 'Issued Quantity', headerName: 'Issued Quantity', width: 150 },
    { field: 'Issued Value', headerName: 'Issued Value', width: 150 },
    { field: 'Item Name', headerName: 'Item Name', width: 250 },
    { field: 'Opening Quantity', headerName: 'Opening Quantity', width: 150 },
    { field: 'Opening Value', headerName: 'Opening Value', width: 150 },
    { field: 'Recieved Quantity', headerName: 'Recieved Quantity', width: 150 },
    { field: 'Recieved Value', headerName: 'Recieved Value', width: 150 }
  ]
  const openSearchPopup = () => {
    setSearchPopupOpen(true);
  };

  const closeSearchPopup = () => {
    setSearchPopupOpen(false);
  };

  const loadData = () => {
    setisLoading(true);
    axios.post(GET_STOCK_STATUS_REPORT, {
      "CompanyNumber": COMPANY_CODE,
      "FormDate": fromdate,
      "ToDate": todate,
      "Category": SelectedCAtegoryValue,
      "strWh": selectedValue,
    })
    .then((response) => {
      setisLoading(false);
      console.log("get stock statement", response);
      if (response.data.IsSuccess === true) {
        var mydata = response.data.Data?.map((item, i) => ({ ...item, sno: i + 1 }));
  
        // Filter out "ICODE" and "Category" columns from the keys
        var keys = Object.keys(mydata[0]).filter(key => key !== "ICODE" && key !== "Category"&& key !== "sno");
        var saleHeader = [];
        keys.forEach((data, i) => {
          var newobject = { field: data, headerName: data, width: 150 };
          saleHeader.push(newobject);
        });
  
        setstockstatementclm(saleHeader);
        setRowData(mydata);
      }
      setisLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  };
  
  const goHome =()=>{
    history.push('/dashboard')
  }
  const openpop =()=>{
    setpopup(true);
   }
   const closePopup = () => {
     setpopup(false);
   };
   
  return (
    <>
    {
      isLoading ? <div className='Commonloader'>
      </div> : null
    }
     {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
    <div className="balacepoupformmain " >
      <div className="stockStatusform" style={{ color: '#0b5aa4', paddingTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>

        <div className='stockStatusform main'>
          <div>
            <span >Warehouse: </span>
            <select  className='stockwarehouse' style={{  }} onChange={(e) => setSelectedValue(e.target.value)}>
            <option value="">MAIN</option>
              {
                warehouse.map((item,i)=>(
                   <option value={item.Type}>{item.Type}</option>
                ))
              }
            </select>
                   
            <span >&nbsp;&nbsp;Category: </span>
            <select  className='stockwarehouse' style={{}} onChange={(e) => setSelectedCAtegoryValue(e.target.value)}>
            <option value="">ALL CATEGORIES</option>
              {
                AllCategories.map((item,i)=>(
                   <option value={item.BCD}>{item.NAME}</option>
                ))
              }
            </select>
              
          </div>
          <div >
            <p className='stockyear'  style={{width:'390px'}}>Financial Year : <span>
              <input type="date"  value={fromdate} style={{marginRight:'10px'}} onChange={(e) => setfromdate(e.target.value)} />
              <input type="date" value={todate} onChange={(e) => settodate(e.target.value)} />
            </span> </p>

          </div>
          {/* <div>
            <div className='accontstatus_checkbox'>
            <input type="checkbox" style={{ width:'20px', height:'20px', marginTop:'3px' }} id="one" name="sales_officer" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}/><span className='accountStatusform_secondgrid_span' style={{ fontSize: '12px', marginLeft:"5px", marginBottom:'4px' }}>Report in Local Currency</span>
          </div></div> */}
          <div className="d-block stockstatusbtns" style={{ justifyContent: 'space-between', width: '190px', marginTop:'-5px' }}>
            <button type="button"  style={{ paddingLeft:'15px', paddingRight:'15px'}}className={` stockStatusformbtn ${classes[companyColor?.split(',')[0]]}  my-1`} onClick={loadData}>LOAD DATA</button>

          </div>
        </div>
      </div>
      <div className='stockstatustbl'>
    {stockstatementdata.length > 0 ? (
      <ReportTbl dataArray={rowData} TblColumns={stockstatementdata} headerColor={companyColor}  tableHeight="70vh" />
    ) : (
      <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
        <thead className={classes[companyColor?.split(',')[0]]}> 
          <tr>
            <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>
  
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000" }}>NO Data Found &nbsp;</td>
          
          </tr>
        </tbody>
      </table>
    )}
   
  </div>
    
    <div className="stockstatsbtn">
        <div className="col">
          <div className="stockstatusbutton" style={{ float: 'left', marginBottom: '10px' }}>
            <Button
              variant="contained"
              className={classes[companyColor?.split(',')[0]]}
              onClick={openSearchPopup}
            >
              Search
            </Button>
            &nbsp;
            <Button variant="contained" className={classes[companyColor?.split(',')[0]]}onClick={openpop}>
              Excel
            </Button>
            &nbsp;
            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>
              PDF
            </Button>
            &nbsp;
          </div>
          <Button variant="contained" className={classes[companyColor?.split(',')[0]]}>
            Cancel
          </Button>
          &nbsp;
          <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={goHome}>
            Exit
          </Button>
        </div>
      </div>
      {searchPopupOpen && (
        <div className="searchPopup">
          <div className="searchPopupContent">
            <input
              type="text"
              placeholder="Search items..."
              // You can add onChange event handler for searching here
            />
            <Button variant="contained" onClick={closeSearchPopup}>
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
    </>
  );
}

export default StockStatus;