import React, { createContext, useContext, useState } from 'react';
import GetUserCountry from './GetUserlocation'

const FinancialYearContext = createContext();

export const useFinancialYear = () => {
  return useContext(FinancialYearContext);
};

export const FinancialYearProvider = ({ children }) => {

  const [Location, setLocation] = useState(false);

  async function getUserLocation() {
    let userCountry = 'India';
  
    try {
      // Attempt to fetch the user's country
      const fetchedCountry = await GetUserCountry();
      if (fetchedCountry) {
        userCountry = fetchedCountry;
        setLocation(true)
      }
    } catch (error) {
      console.error('Error fetching user country:', error);
    }
    return userCountry;
  }
  
  const locationToFinancialYear = {
    India: { startMonth: 4, endMonth: 3 }, // India
    Australia: { startMonth: 6, endMonth: 5 }, // Australia 

  };

  function getFinancialYearDates(location) {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    const financialYearConfig = locationToFinancialYear[location];

    if (!financialYearConfig) {
      return "India"; 
      alert('location not found')
    }

    const { startMonth, endMonth } = financialYearConfig;

    let financialYearStart, financialYearEnd;


    if (currentMonth < startMonth) {
      financialYearStart = new Date(currentYear - 2, startMonth, 1);
      financialYearEnd = new Date(currentYear - 1, endMonth, 0);
    } else {
      financialYearStart = new Date(currentYear - 1, startMonth-1, 1+1);
      financialYearEnd = new Date(currentYear, endMonth, 0);
    }
    


    const financialYearStartFormatted = financialYearStart.toISOString().slice(0, 10);
    const financialYearEndFormatted = financialYearEnd.toISOString().slice(0, 10);

    return {
      financialYearStart: financialYearStartFormatted,
      financialYearEnd: financialYearEndFormatted,
    };
  }

  const userLocation = getUserLocation();

  const financialYear =  Location ?  getFinancialYearDates(userLocation) : getFinancialYearDates('India');
// by default its Getting finance Dates of India if User Doesn't allow the Geo location 

  return (
    <FinancialYearContext.Provider
      value={{
        financialYear,
      }}
    >
      {children}
    </FinancialYearContext.Provider>
  );
};
