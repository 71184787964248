import { useGeolocation } from 'react-use';
import { useEffect, useState } from 'react';

const GetUserCountry = () => {
  const geolocation = useGeolocation();
  const [userCountry, setUserCountry] = useState(null);

  useEffect(() => {
   
    if (geolocation.latitude && geolocation.longitude) {
   
      fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${geolocation.latitude}&lon=${geolocation.longitude}&format=json`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.address && data.address.country) {
            setUserCountry(data.address.country);
          } else {
            setUserCountry('Country not found');
          }
        })
        .catch((error) => {
          console.error('Error fetching user country:', error);
          setUserCountry('Error fetching country');
        });
    } else {
      setUserCountry('Geolocation data not available');
    }
  }, [geolocation.latitude, geolocation.longitude]);

  return userCountry
};


export default GetUserCountry;