import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
btnForLedger:{
  background:"#0b5aa4",
  color:"white",
},
restaurant: {
  backgroundColor: theme.palette.primary.Rastorant_primary,
  color: theme.palette.primary.whiteColor,
},
restaurantborder:{
borderLeft:`3px solid ${theme.palette.primary.Rastorant_primary}`
},
pos: {
  backgroundColor: theme.palette.primary.POS_primary,
  color: theme.palette.primary.whiteColor,
},
posborder:{
  borderLeft:`3px solid ${theme.palette.primary.POS_primary}`
  },
production: {
  backgroundColor: theme.palette.primary.Production_primary,
  color: theme.palette.primary.whiteColor,
},
productionborder:{
  borderLeft:`3px solid ${theme.palette.primary.Production_primary}`
  },
order_management: {
  backgroundColor: theme.palette.primary.OrderMngmt_primary,
  color: theme.palette.primary.whiteColor,
},
order_managementborder:{
  borderLeft:`3px solid ${theme.palette.primary.OrderMngmt_primary}`
  },
restaurantBG: {
  color: theme.palette.primary.Rastorant_primary,
 

},
posBG: {
  color: theme.palette.primary.POS_primary,
 
},
productionBG: {
  color: theme.palette.primary.Production_primary,

},
order_managementBG: {
  color: theme.palette.primary.OrderMngmt_primary,
  
},
restaurantBB: {
  borderBottom: `2px solid ${theme.palette.primary.Rastorant_primary}`,
 

},
posBB: {
  
  borderBottom: `2px solid ${theme.palette.primary.POS_primary}`,
},
productionBB: {

  borderBottom: `2px solid ${theme.palette.primary.Production_primary}`,

},
order_managementBB: {
  borderBottom: `2px solid ${theme.palette.primary.OrderMngmt_primary}`,
  
},
}
));
