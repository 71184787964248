import tinycolor from "tinycolor2";

const primary  = "#2fbaa8";
const white = "#fff";
const POS_primary  = "#2fbaa8";
const rastorant_primary = "#fb7004";
const OrderMngmt_primary = "#0b5aa4";
const secondary = "#373636";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#03328ff0";
const lightBg="#ffebf2";
const lightenRate = 7.5;
const darkenRate = 15;
const Production_primary = "#4c86ce";
const Maintextcolor = "black";
const Production_secondary ="red";
const Production_warning ="red";
const Production_success ="green";
const Production_info ="blue";
const Production_lightBg ="black";
const Production_lightenRate ="white";
const Production_darkenRate ="black";

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      whiteColor:white,
      Rastorant_primary:rastorant_primary,
      OrderMngmt_primary:OrderMngmt_primary,
      Production_primary:Production_primary,
      Production_secondary:Production_secondary,
      POS_primary:POS_primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
        OrderMngmt_light: tinycolor(OrderMngmt_primary)
        .lighten(lightenRate)
        .toHexString(),
        POS_light: tinycolor(POS_primary)
        .lighten(lightenRate)
        .toHexString(),
        Production_light: tinycolor(Production_primary)
        .lighten(lightenRate)
        .toHexString(),
        Restro_light: tinycolor(rastorant_primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      veryLight:lightBg,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#373636",
      secondary: "#003DB4",
      hint: "#B9B9B9",
      light:"##4c4c4cf7",
      dark:"#454545"
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
      success:"#3BADA2",
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        paddingLeft: 24
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10
      }
    }
    
  },

};

export default defaultTheme;
