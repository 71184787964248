import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";

const drawerWidth = 240;
export default makeStyles(theme => ({
  sidebar: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "270px",
    borderRadius: "0px 36px 36px 0px",
    backgroundColor: theme.palette.primary.Rastorant_primary
    },
    production_sidebar: {
      position: "fixed",
      top: 0,
      left: 0,
      height: "100vh",
      width: "270px",
      borderRadius: "0px 36px 36px 0px",
      backgroundColor: theme.palette.primary.Production_primary
      },
      pageheading: {
     
        backgroundColor:  theme.palette.primary.Production_secondary
        },
    sidebarLogo: {
      alignItems: "center",
      background: "#FFFFFF",
      placeItems: "center",
      height: "164px",
     fontSize: "1.5rem",
    fontWeight: 700,
  },
  sidebarMenu :{
    position: "relative",
    height: "76vh"
},
sidebarMenuItem: {
  display: "flex",
  alignItems: "center",
  placeContent: "flex-start",
  fontSize: "18px",
  fontWeight: 500,
  color: "#fff",
  transition: "color 0.3s ease-in-out",
 
},
//this of active link
// sidebarMenuItem: {
//   color: "#0b5aa4",
// },
sidebarMenuItemIcon :{
  marginRight: "1rem",

},
sidebarMenuItemIcon :{
  fontSize: "1.9rem",
},
sidebarMenuItemText:
{
  paddingLeft:"3%",
  textDecoration:`none`
},
sidebarMenuIndicator: {
  height:"50px",
  position: "absolute",
  top: 0,
  left: "46%",
  width: "calc(100% - 50px)",
  borderRadius: "10px",
  border :"2px solid #fff",
  zIndex: -1,
  transform:'translateX(-50%)',
  transition: "0.3s ease-in-out",
},
sideMainText:{
color:theme.palette.primary.main,
paddingTop:"2%",
marginBottom:"2%"
},
userSideImg:
{
  width: "20%",
  height:"20%" ,
  borderRadius:"50%",
   padding:"2%",
   outline:`2px solid ${theme.palette.primary.main}`
},
textSecondry:
{
  color:theme.palette.text.light,
  margin:"1%",
},
textPrimary:{
  color:theme.palette.text.dark,
  },
  lowerMenu:{
  color:"#ffffff",
  borderTop:"1px solid white"
  },
  scroller:{
    position: "absolute",
    height: "100vh",
    top: "10px",
    left: "22.5%",
    width:"77%",
    paddingRight: "1%",
  },
  scrollerfull:{
    position: "absolute",
    height: "100vh",
    width: "97%",
    top: "10px",
    left: "1%",
    
  }
}
  ));
