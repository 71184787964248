import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import SearchIcon from '@mui/icons-material/Search';

import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import axios from "axios";
// styles
import useStyles from "./styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GET_ALL_CATEGORIES, COMPANY_CODE, GET_ITEMS } from "../../constant";

export default function ItemsList(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [isLoading, setisLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [companyColor, setcompanyColor] = useState("");
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const pageSize = 30;

  const fetchClients = async () => {
    try {
      const response = await axios.post(GET_ITEMS, {
        CompanyNumber: COMPANY_CODE,
        userType: "",
        route: "",
        Mcode: ""
      });
      const itemList = response.data.Data;
      
      setItems(itemList);
      setTotalPages(Math.ceil(itemList.length / pageSize));
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const fetchItems = async () => {
    try {
      const response = await axios.post(GET_ITEMS, {
        CompanyNumber: COMPANY_CODE,
        Category: ''
      });
      const itemList = response.data.Data;
      console.log(itemList);
      setItems(itemList);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    axios.post(GET_ALL_CATEGORIES, { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        if (response.data.IsSuccess === true) {
          setCategories(response.data.Data)
        }
      })
  }, []);

  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType').split(",")[0])
    var a = window.location.href;
  });

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const filteredItems = items.filter((item) =>
    item.itemName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const paginatedItems = filteredItems.slice(
    (page - 1) * pageSize,
    page * pageSize
  );

  var settings = {
    autoplay: true,
    speed: 1000,
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }]
  };

  const fetchItemsByCategory = async (categoryCode) => {
    try {
      const response = await axios.post(GET_ITEMS, {
        CompanyNumber: COMPANY_CODE,
        Category: categoryCode
      });
      const itemList = response.data.Data;
      setItems(itemList);
      setTotalPages(Math.ceil(itemList.length / pageSize));
      setPage(1); //  the first page
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const setCateg = (categoryCode) => {
    setSelectedCategory(categoryCode);
  };

  useEffect(() => {
    if (selectedCategory !== "") {
      fetchItemsByCategory(selectedCategory);
    }
  }, [selectedCategory]);

  return (
    <>
      {isLoading ? <div className='Commonloader'></div> : null}
      <div className='accountsearch'>
        <Grid container spacing={6} className="pb-0">
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <form className="search">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  placeholder="Search..."
                  style={{
                    height: '45px',
                    width: '80%',
                    padding: '5px',
                    border: '1px solid #fff',
                    borderRadius: '20px 0px 0px 20px',
                    boxShadow: '0px 2px 2px rgba(137, 137, 137, 0.25)',
                  }}
                />
                <button
                  style={{
                    backgroundColor: "#fff",
                    marginLeft: '6px',
                    padding: '5px 10px',
                    height: '45px',
                    border: '1px solid #fff',
                    borderRadius: "0px 20px 20px 0px",
                    cursor: 'pointer',
                    boxShadow: '0px 2px 2px rgba(137, 137, 137, 0.25)',
                  }}
                >
                  <SearchIcon name="apps-outline"></SearchIcon>
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>

      <div className="slickslider2" style={{ margin: "30px" }}>
        <Slider {...settings}>
          {categories?.map((category, index) => (
            <div key={index}>
              <div className={`card py-1 ${classes.row3Card}`}>
                <div className="card-body" onClick={() => setCateg(category.categoryCode)}>
                  <h6 className={`card-title my-0 fw-bold`}>{category.categoryName}</h6>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <Grid container spacing={6} className={companyColor}>
        {paginatedItems.map((item, index) => (
          <Grid item lg={4} md={4} sm={4} xs={12} className="pb-0" key={index}>
            <div className={`card py-1 ${classes.row1Card}`}>
              <div className="card-body">
                <h6 className={`card-title my-0 fw-bold`}>{item.itemName}</h6>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap></h4>
                  <ol className={`p-0 mt-3 mb-0 pl-0 ${classes.textSecondry}`} start='1'>
                    <li className="list-group-item"><h6 className={classes.textSecondry}>QOH: {item.qoh}</h6></li>
                  </ol>
                </Grid>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>

      <div style={{ marginTop: '20px' }}>
        <Grid container justify="center">
          <Button
            variant="contained"
            color="primary"
            disabled={page === 1}
            onClick={() => handlePageChange(page - 1)}
            style={{ margin: "20px", color: "#fff" }}
          >
            Previous Page
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={page === totalPages}
            onClick={() => handlePageChange(page + 1)}
            style={{ margin: "20px", color: "#fff" }}
          >
            Next Page
          </Button>
        </Grid>
      </div>
    </>
  );
}
