import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,TextField,
  Checkbox
} from "@material-ui/core";
import {useHistory } from "react-router-dom"
import { useTheme } from "@material-ui/styles";
import { CSVLink } from "react-csv";
import ReportTbl from '../CollectionReport/ReportTbl';
// styles
import useStyles from "./styles";
import { DataGrid} from "@mui/x-data-grid";
import { useEffect } from "react";
import {GET_All_ACCOUNT,LOGIN_DETAILS,GET_LEDGER_REPORT,COMPANY_CODE} from '../../../constant';
import axios from "axios";
import { useFinancialYear } from "../FinancialYearContext";
import ComingSoonPopup from '../PopUP/PopUp';


export default function LedgerReport(props) {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const [isSelectAc,setIsSelectAc]  = useState(false);
  const [nameOfAcc,setNameOfAcc] = useState("");
  const [ldgReport,setLdgReport] = useState([]);
  const [tabledata,settabledata] = useState([]);
  const [ldgAccList,setLdgAccList] = useState([]);
  const [ldgAcList,setLdgAcList] = useState([]);
  const [isLoading,setisLoading] = useState(false)
  const [selectedLdgAccIndex,setSelectedLdgAccIndex] = useState(0);
  const [companyColor, setcompanyColor] = useState("")
  const [LedgerTblRow,setLedgerTblRow] = useState([]);
  const[popup,setpopup]=useState(false);
  const [localcurrencycheck, setlocalcurrencycheck]= useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);


  const [ledgerAc,setLedgerAc] = useState({
    "FYear":LOGIN_DETAILS.FINANCIAL_YEAR,
    "CompanyNumber":COMPANY_CODE
  });
  const { financialYear } = useFinancialYear();


  const [ledger,setLedger] = useState({
    "FormDate":financialYear.financialYearStart,
    "ToDate":financialYear.financialYearEnd,
    "chkLocalCurrency":0,
    "CompanyNumber":COMPANY_CODE
  });

  //this will change Set financial dates based on their Country 
 useEffect(()=>{
  setLedger.FormDate = financialYear.financialYearStart;
  setLedger.ToDate = financialYear.financialYearEnd;

 },[financialYear])

  useEffect(() => {
    
    console.log("LedgerTblRow:", LedgerTblRow);
    setcompanyColor(localStorage.getItem('cmpnyType'))
   //  document.querySelector('.TOGGLEicon').click();
  var a = window.location.href;

        axios.post(GET_All_ACCOUNT,ledgerAc
    ).then((response)=>{
    
      if(response.data.IsSuccess === true){
        setLdgAcList(response.data.Data);
      }
      else{
        // var emptList=[];
        setLdgAcList([]);
      }
    })
  },[]);
  const acTypeClicked=(type)=>
{
  if(type==="Selected")
  {
    setIsSelectAc(true);
  }
  else{
    setIsSelectAc(false);
  }
  setSelectedLdgAccIndex(0);
  setLedger({...ledger,"Mcode":""});
}
const getLedgerReportInNxtBtn=()=>
{
  //localStorage.setItem('ldgAccList_index', selectedLdgAccIndex);
  console.log("ledger state",ledger)
  console.log("ldgAccList",ldgAccList)
  console.log("selectedLdgAccIndex",ldgAccList[0])
// getting all checked Selected Accoutns 🔽
var selectedAccount = Array.from(document.querySelectorAll('.SeledtedAccountDiv input:checked')).map(item => item.value)

  setisLoading(true)
  setSelectedLdgAccIndex(selectedLdgAccIndex+1);
  axios.post(GET_LEDGER_REPORT,{
    "FormDate":ledger.FormDate,
    "ToDate":ledger.ToDate,
    "chkLocalCurrency":ledger.chkLocalCurrency,
    "Mcode":  isSelectAc ?   selectedAccount.toString():  ldgAccList[selectedLdgAccIndex+1],
    "CompanyNumber":COMPANY_CODE
  }
    ).then((response)=>{
      console.log(response)
      console.log(response.data.Data)
      if(response.data.IsSuccess === true){

        var mydata = response.data.Data;
        const slicedDates = mydata.map((item) => ({
          ...item,
          VCDT: item.VCDT ? item.VCDT.slice(0, 10) : undefined,
        }));

        console.log(slicedDates);
              var generallegClm = [];
        var keys = Object.keys(mydata[0])
        keys.forEach((data,i)=>{
          var newObject =  { "field": data, "headerName": data, "width": 150 }
          generallegClm.push(newObject)
        })

        console.log("header",generallegClm)
        setLdgReport(slicedDates);
        setLedgerTblRow(generallegClm)  // dyanmic columns
        setisLoading(false)
      }
      else{
        // var emptList=[];
        setLdgReport([]);
        setisLoading(false)
      }
    })
}
const onhandleAcChanges=(e)=>
{
  debugger;
  if(e.target.name=="fromAc"||e.target.name=="toAc")
  {
    let fromIndex=0,toIndex=0;
    ldgAcList.some((element,i) => {
      if (element.Mcode === e.target.value) {
        e.target.name=="fromAc"?fromIndex=i:toIndex=i;
      }
  
    });
    if(e.target.name=="toAc")
    {
     for(var i=fromIndex;i<=toIndex;i++)
     {
       debugger;
        setLdgAccList(ldgAccList => [...ldgAccList,ldgAcList[i].Mcode] );
     }
    }
   
  }
  else{
    alert("selected acount")
    setLdgAccList(ldgAccList => [...ldgAccList,e.target.value] );
  }
  setLedger({...ledger,"Mcode":e.target.value});
  if (e.target) {
    setNameOfAcc(e.target.text);
  }
  
}
const getLedgerReport=()=>
{
  setisLoading(true)
  if(ledger.FormDate===""||ledger.ToDate===""||ledger.Mcode==="")
  {
    alert("Account and from date and to date should not blank.");
    setisLoading(false)
  }
  
  else{
    alert("FSd")
    console.log(ledger.FormDate)
    console.log(ledger.ToDate)
    console.log(ldgAccList[0])
    console.log(ledger.chkLocalCurrency)
    console.log(COMPANY_CODE)
    axios.post(GET_LEDGER_REPORT,
      {
        "FormDate":ledger.FormDate,
        "ToDate":ledger.ToDate,
        "chkLocalCurrency":ledger.chkLocalCurrency,
        "Mcode":ldgAccList[0],
        "CompanyNumber":COMPANY_CODE
      }).then((response)=>{
       
        debugger

        if(response.data.IsSuccess === true){
          console.log(response)
          setLdgReport(response.data.Data);
          setSelectedLdgAccIndex(0);
          setisLoading(false)

        }
        else{
          // var emptList=[];
          setLdgReport([]);
          setisLoading(false)
        }
      })
  }
}

const handleCheckboxChange = (index) => {
  setSelectedCheckbox(index);
};

const handleLedgerChange=(e)=>
{
  debugger;
  if(e.target.name==="chkLocalCurrency" )
  {
    if(e.target.checked)
    {
      setlocalcurrencycheck(true)
    }
    else{
      setlocalcurrencycheck(false)
    }
    e.target.value=e.target.checked;
  }
  else
  {
    if(e.target.name==="Mcode")
    {  
      if(e.target.checked)
      {
        if(ldgAccList.length>0)
        {
   
          setLdgAccList(ldgAccList => [...ldgAccList,`"${e.target.value}"`]);
        }
        // else{
          
        //   setLdgAccList(`${e?.target.value}`);
        // }
        // console.log(ldgAccList);
         //setLdgAccList({...ldgAccList,[e.target.name]:e.target.value}); 
      }
      else{
      //  alert("working with selected ACC")
      //   if(ldgAccList?.indexOf(e.target.value)>-1)
      //   {
      //     ldgAccList?.splice(ldgAccList.indexOf(e.target.value), 1);
      //   }
      }
      
    } 
    if (e.target) {  
      setLedger({...ledger,[e.target.name]:e.target.value});
    }
    
  }
}
const goHome =()=>{
  history.push('/dashboard')
}
const openpop =()=>{
  setpopup(true);
 }
 const closePopup = () => {
   setpopup(false);
 };
 
  return (
  <>
     {
    isLoading?  <div className='Commonloader'>
    </div>:null
  }
   {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
     <div className="balacepoupformmain general_ledger" >
   <div className="balacepoupformleft">
        <div className="left-blankspace"></div>

        <div className="form-check form-check-inline my-2">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="AToA" defaultChecked={true} onClick={e=>{acTypeClicked(e.target.value)}}/>
  <label className="form-check-label text-dark" for="inlineRadio1">Acc To Acc</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Selected" onClick={e=>{acTypeClicked(e.target.value)}}/>
  <label className="form-check-label text-dark" for="inlineRadio2">Selected Account</label>
</div>
{
  isSelectAc?
  <> <h6 className="form-check-label text-dark" for="inlineRadio2">Selected Account:</h6>
  <div className={classes.AccCheckboxDiv}>
{        ldgAcList.map((data,i)=>
    {
 return( 
  <div className="my-2 px-2 SeledtedAccountDiv">
 <input className="form-check-input mt-0" type="checkbox" name="Mcode" id={data.Mcode} value={data.Mcode}    checked={selectedCheckbox === i} onChange={(e) => {
              handleLedgerChange(e) ;
              handleCheckboxChange(i);
            }}/>
 <label className="form-check-label mx-2" for="inlineRadio2">{data.Acname}</label>
  </div>
)
    })}
      </div>
     </>
  :
  <div>
<h6 className="form-check-label text-dark my-1" for="inlineRadio2">From Account :</h6>
<select className={`form-select ${classes.AccInput}`} name="fromAc"onChange={e=>{onhandleAcChanges(e)}}>
  <option  value="">--Select From Account--</option>
{
   ldgAcList.map((data,i)=>
   {
return(<option key={i} value={data.Mcode}>{data.Acname}</option>)
   })
 }
</select>
<h6 className="form-check-label text-dark my-1" for="inlineRadio2">To Account :</h6>
<select className={`form-select ${classes.AccInput}`} name="toAc" onChange={e=>{onhandleAcChanges(e)}}>
<option value="">--Select To Account--</option>
{
   ldgAcList.map((data,i)=>
   {
return(<option key={i} value={data.Mcode}>{data.Acname}</option>)
   })
 } 
</select>
</div>
}
<div className="row">
<div className="col-sm-6 col-6 col-md-6">
<div className="mb-3">
<h6 className="form-check-label text-dark my-1" for="inlineRadio2">From Date :</h6>
  <input type="date" className="form-control" value={ledger.FormDate} id="FDate" name="FormDate"onChange={e=>{handleLedgerChange(e)}}/>
</div>
</div>
<div className="col-sm-6 col-6 col-md-6">
      <div className="mb-3">
      <h6 className="form-check-label text-dark my-1" for="inlineRadio2">To Date :</h6>
  <input type="date" className="form-control" value={ledger.ToDate} id="ToDate"name="ToDate"onChange={e=>{handleLedgerChange(e)}}/>
</div>
</div>
</div>
        <input type="checkbox" name="chkLocalCurrency" onChange={e=>{handleLedgerChange(e)}} checked={localcurrencycheck} />&nbsp;&nbsp;
         <span className="text-dark">Report In Local Currency</span>
      <div className="my-1">
      <h6>{nameOfAcc}</h6>
      </div>
         <div className="d-flex mt-2" style={{width:'98%'}}>
         <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} onClick={getLedgerReportInNxtBtn} disabled={ldgAccList?.length<2?true:false}>NEXT</Button>&nbsp;
         </div>
         <div className="d-flex mt-2" style={{width:'98%'}}>
        {/* <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} disabled={ldgReport?.length==0?true:false}> <CSVLink data={ldgReport} className="text-dark">EXCEL</CSVLink></Button> &nbsp; */}
          <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} onClick={openpop}>EXcel</Button>&nbsp;
          <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} onClick={getLedgerReportInNxtBtn}>VIEW</Button>&nbsp;
          <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`} onClick={openpop}>PDF</Button>&nbsp;
          <Button variant="contained" className={`${classes[companyColor?.split(',')[0]]}`}  onClick={goHome}>EXIT</Button>&nbsp;
         </div>

        </div>
        <div className="balacepoupformright">
                {LedgerTblRow.length > 0 ? (
   <ReportTbl
   dataArray={ldgReport}
   TblColumns={LedgerTblRow.filter((column) => {
     // Define the condition to exclude columns
     return (
       column.field !== 'Id' &&
       column.field !== 'VCDT' &&
       column.field !== 'Company' &&
       column.field !== 'VCTP' &&
       column.field !== 'VCNO'
     );
   }).map((column) => {
     // Replace '@' with 'Conversion Rate'
     if (column.field === '@') {
       return { ...column, headerName: 'Conversion Rate' };
     }
     return column;
   })}
   headerColor={companyColor}
 />
 
  ) :(
    <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
      <thead className={classes[companyColor?.split(',')[0]]}> 
        <tr>
          <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>

        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
        
        </tr>
      </tbody>
    </table>
  )}
</div>
       
      </div>
  </>  
  
  );
}

