import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, TextField,
  Checkbox
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ReportTbl from '../CollectionReport/ReportTbl';
import { useTheme } from "@material-ui/styles";
import ComingSoonPopup from '../PopUP/PopUp';
// import { CSVLink } from "react-csv";
import useStyles from "./style";
// styles

import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { GET_All_ACCOUNT, LOGIN_DETAILS, GET_LEDGER_REPORT, GET_GRAPH_DATA, GET_CATEGORY_SALE_REPORT, COMPANY_CODE, GET_ALL_WAREHOUSES, GET_ALL_TRANSATIN_TYPE, GET_ALL_PARTY, GET_ALL_SPECIFIC_PARTY, GET_PARTY_STOCK_REPORT, GET_ITEMS, GET_ALL_CATEGORIES, GET_GROUP_SELECTION } from '../../../constant';
import axios from "axios";

export default function Client(props) {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const [isSelectAc, setIsSelectAc] = useState(false);
  const [ldgReport, setLdgReport] = useState([]);
  const[popup,setpopup]=useState(false);
  const [tabledata, settabledata] = useState([]);
  const [ldgAccList, setLdgAccList] = useState([]);
  const [ldgAcList, setLdgAcList] = useState([]);
  const [isLoading, setisLoading] = useState(false)
  const [selectedLdgAccIndex, setSelectedLdgAccIndex] = useState(0);

  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(today)    // need to set here today variable 
  const [enddate, setenddate] = useState(nextday);
  const [ChartCategory, setChartCategory] = useState([])
  const [warehouse,setwarehouse] = useState([])
  const [transactionType,settransactionType] = useState([])
  const [allpartyTyp,setallpartyTyp] = useState([])
  const [specificParty,setspecificParty] = useState([])
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedwarehouse, setcheckedwarehouse] = useState([]);
  const [checkedtransactionTYp, setcheckedtransactionTYp] = useState([]);
  const [isCheckedCategory,setisCheckedCategory] = useState(false)
  const [SelectedToValue,setSelectedToValue] = useState("")
  const [SelectedfromValue,setSelectedfromValue] = useState("")
  const [selectedWarehouse, setSelectedWarehouse] = useState(false);
  const [rowData, setRowData] = useState([]);
  const[partystockledgerclm,setpartystockledgerclm]=useState([]);


  const [companyColor, setcompanyColor] = useState("")
  const LedgerTblRow = [
    {
      
    
      renderCell: (params) => {

        return (
          <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
        );
      }
    },
    { field: 'ACNAME', headerName: 'ACNAME', width: 150 },
    { field: 'Amount', headerName: 'Amount', width: 150 },
    { field: 'BARCODE', headerName: 'BARCODE', width: 150 },
    { field: 'BILDT', headerName: 'BILDT', width: 150 },
    { field: 'BILNO', headerName: 'BILNO', width: 150 },
    { field: 'CHDATE', headerName: 'CHDATE', width: 150 },
    { field: 'DOCNO', headerName: 'DOCNO', width: 150 },
    { field: 'INOUT'  , headerName: 'INOUT', width: 150 },
    { field: 'ITEM'  , headerName: 'ITEM', width: 150 },
    { field: 'Issued'  , headerName: 'Issued', width: 150 },
    { field: 'QTYBAL'  , headerName: 'QTYBAL', width: 150 },
    { field: 'Rate'  , headerName: 'Rate', width: 150 },
    { field: 'SPECS'  , headerName: 'SPECS', width: 150 },
    { field: 'TP'  , headerName: 'TP', width: 150 },
    { field: 'UNIT'  , headerName: 'UNIT', width: 150 },
    { field: 'VALBAL'  , headerName: 'VALBAL', width: 150 },
    { field: 'Received'  , headerName: 'Received', width: 150 },
    { field: 'WASTE'  , headerName: 'WASTE', width: 150 },  
  ]
  const [ledgerAc, setLedgerAc] = useState({
    "FYear": LOGIN_DETAILS.FINANCIAL_YEAR
  });
  const [ledger, setLedger] = useState({
    "FormDate": new Date(),
    "ToDate": new Date(),
    "chkLocalCurrency": 0,
    "Mcode": ""
  });

  useEffect(() => {
// item category 
    axios.post(GET_GROUP_SELECTION,
      { "CompanyNumber": COMPANY_CODE, "FormDate": startdate, "ToDate": enddate })
      .then((response) => {
        console.log(" ITEM Category ")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setChartCategory(response.data.Data)

        }
      })

//all ware house
      axios.post(GET_ALL_WAREHOUSES,
        { "CompanyNumber": COMPANY_CODE })
        .then((response) => {
          console.log("ALL WARE HOUSE ")
          console.log(response)
          if (response.data.IsSuccess === true) {
            console.log(response.data.Data);
            setwarehouse(response.data.Data)
  
          }
        })

        // ALL TRANSACTIO TYPE 
      axios.post(GET_ALL_TRANSATIN_TYPE,
        { "CompanyNumber": COMPANY_CODE })
        .then((response) => {
          console.log("TRANSACTIION TYPE")
          console.log(response)
          if (response.data.IsSuccess === true) {
            console.log(response.data.Data);
            settransactionType(response.data.Data)
  
          }
        })

   // get all party 
   axios.post(GET_ALL_PARTY,
    { "CompanyNumber":COMPANY_CODE })
    .then((response) => {
      console.log("get all party ")
      console.log(response)
      if (response.data.IsSuccess === true) {
        console.log(response.data.Data);
        setallpartyTyp(response.data.Data)

      }
    })

    // get specific party 
    axios.post(GET_ALL_SPECIFIC_PARTY,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log("get specific party  ")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setspecificParty(response.data.Data)
  
        }
      })

  }, [startdate, enddate])


  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
    //document.querySelector('.TOGGLEicon').click();
    var a = window.location.href;
    axios.post(GET_All_ACCOUNT, ledgerAc
    ).then((response) => {
      if (response.data.IsSuccess === true) {
        setLdgAcList(response.data.Data);
      }
      else {
        // var emptList=[];
        setLdgAcList([]);
      }
    })
  }, []);


  const acTypeClicked = (type) => {
    if (type === "Selected") {
      setIsSelectAc(true);
    }
    else {
      setIsSelectAc(false);
    }
    setSelectedLdgAccIndex(0);
    setLedger({ ...ledger, "Mcode": "" });
  }
  const getLedgerReportInNxtBtn = () => {
    setisLoading(true)
    setSelectedLdgAccIndex(selectedLdgAccIndex + 1);
    axios.post(GET_LEDGER_REPORT, {
      "FormDate": ledger.FormDate,
      "ToDate": ledger.ToDate,
      "chkLocalCurrency": ledger.chkLocalCurrency,
      "Mcode": ldgAccList[selectedLdgAccIndex + 1]
    }
    ).then((response) => {
      if (response.data.IsSuccess === true) {
        setLdgReport(response.data.Data);
        setisLoading(false)

      }
      else {
        // var emptList=[];
        setLdgReport([]);
        setisLoading(false)
      }
    })
  }
  const onhandleAcChanges = (e) => {
    setLdgAccList(ldgAccList => [...ldgAccList, e.target.value]);
    setLedger({ ...ledger, "Mcode": e.target.value });
  }
  const getLedgerReport = () => {
    setisLoading(true)
    if (ledger.FormDate === "" || ledger.ToDate === "" || ledger.Mcode === "") {
      alert("Account and from date and to date should not blank.");
      setisLoading(false)
    }
    else {
      axios.post(GET_LEDGER_REPORT,
        {
          "FormDate": ledger.FormDate,
          "ToDate": ledger.ToDate,
          "chkLocalCurrency": ledger.chkLocalCurrency,
          "Mcode": ldgAccList[0]
        }
      ).then((response) => {
        if (response.data.IsSuccess === true) {
          setLdgReport(response.data.Data);
          setSelectedLdgAccIndex(0);
          setisLoading(false)
        }
        else {
          // var emptList=[];
          setLdgReport([]);
          setisLoading(false)
        }
      })
    }
  }

  function getValues() {
    var checkboxes = document.querySelectorAll('#itemCategryChckbox');
    var  itemCategories = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
    itemCategories = itemCategories.map(str => str.replace(/\r?\n|\r/g, '')).join(",")
  //
  var checkboxes = document.querySelectorAll('#warehouseChckbox');
  var  warehouses = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
  warehouses = warehouses.map(str => str.replace(/\r?\n|\r/g, '')).join(",")
  //
  var checkboxes = document.querySelectorAll('#transactionChckbox');
  var  transactionTypes = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
  transactionTypes = transactionTypes.map(str => str.replace(/\r?\n|\r/g, '')).join(",")
   
    return [itemCategories, warehouses, transactionTypes];
  }

const handleWarehouseCheckboxChange = (event) => {
    setSelectedWarehouse(event.target.checked);
  };
const getpartyStockView =()=>{
  if (!selectedWarehouse)  {
    alert('Please select a warehouse.');
    return;
  }
  setisLoading(true); 
  console.log(startdate)
  console.log(enddate)
  const [itemCategories, warehouses, transactionTypes] = getValues();
var data = {
  "CompanyNumber":COMPANY_CODE,
  "fromParty":SelectedfromValue,
  "toParty":SelectedToValue,
  "strIcode":"",
  "FormDate":startdate,
  "ToDate":enddate,
  "Warehouse":warehouses,
  "Category":itemCategories,
  "Transaction":transactionTypes,
} 
  axios.post(GET_PARTY_STOCK_REPORT, data
    )
    .then((response) => {
      console.log(warehouses)
      console.log(itemCategories)
      console.log(transactionTypes)
      console.log("testing data",response);
      if (response.data.IsSuccess === true) {
        var mydata = response.data.Data?.map((item, i) => {
          item.CHDATE = item.CHDATE.slice(0, 10);
          return { ...item };
        });
      // if (response.data.IsSuccess === true) {
      //   var  mydata = response.data.Data?.map((item,i)=>({...item,CHDATE:item.CHDATE.slice(0,10)}))
        
        var obj ={}
        var saleHeader =[]
        var keys = Object.keys(mydata[0])
      keys.forEach((data,i)=>{
       var newobject = { field: data, headerName: data, width: 150 }
       saleHeader.push(newobject)
      })
        setpartystockledgerclm(saleHeader);
        setRowData(mydata); 
      } 
        setisLoading(false); 
      })
    .catch((error) => {
      console.error('Error fetching data:', error);

    });
  };

  const handleLedgerChange = (e) => {
    if (e.target.name === "chkLocalCurrency") {
      setLedger({ ...ledger, [e.target.name]: e.target.checked });
    }
    else {
      if (e.target.name === "Mcode") {
        if (e.target.checked) {
          setLdgAccList(ldgAccList => [...ldgAccList, e.target.value]);
        }
        else {
          if (ldgAccList.indexOf(e.target.value) > -1) {
            ldgAccList.splice(ldgAccList.indexOf(e.target.value), 1);
          }
        }
      }
      setLedger({ ...ledger, [e.target.name]: e.target.value });
    }
  }
  const goHome =()=>{
    history.push('/dashboard')
  }
  const openpop =()=>{
    setpopup(true);
   }
   const closePopup = () => {
     setpopup(false);
   };
   
  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
       {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
      <div className="balacepoupformmain partystock_main" >
        <div className="balacepoupformleft" style={{width: "35%"}} >

          <div className="row ">
            <div className="col-sm-6 col-6 col-md-6">
              <div className="mb-3">
                <h6 className="form-check-label text-dark my-1 " for="inlineRadio2">From Date :</h6>
                <input type="date" className="form-control" id="FDate" name="FormDate" value={startdate}
                  onChange={(e) => setstartdate(e.target.value)} />
              </div>
            </div>
            <div className="col-sm-6 col-6 col-md-6">
              <div className="mb-3">
                <h6 className="form-check-label text-dark my-1" for="inlineRadio2">To Date :</h6>
                <input type="date" className="form-control" id="ToDate" name="ToDate" value={enddate}
                  onChange={(e) => setenddate(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="scrolltype">
            <div className="row PartyStockLedger_2">
              <div className="col-sm-12 col-12 col-md-12">
                <p className="form-check-label text-dark my-1" for="inlineRadio2">Item Category</p>
                <div className="scrollerDiv"  id="categorydata">
                  {ChartCategory.length == 0 ? "NO Category Found" : ""}
              {
                ChartCategory?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" 
                  id="itemCategryChckbox"
                   className="form-check-input"  
                   name=""
                   value={item.BCD} 
                     /> &nbsp;{item.DESCRIPTION}</li>
                ))
              }
                </div>

              </div>
              
              <div className="col-sm-6 col-6 col-md-6">
                <p className="form-check-label text-dark my-1" for="inlineRadio2">Warehouse</p>
                <div className="scrollerDiv">
                {warehouse.length == 0 ? "NO warehouse Found" : ""}
              {
                warehouse?.map((item ,i)=>(
                  <li>
                  <input type="checkbox"
                   className="form-check-input"
                    id="warehouseChckbox" 
                    name=""
                     value={item.Type} 
                     onChange={handleWarehouseCheckboxChange} 
                     /> &nbsp;{item.Category}</li>
                ))
              }
                </div>
              </div>
              <div className="col-sm-6 col-6 col-md-6">
                <p className="form-check-label text-dark my-1" for="inlineRadio2">Transaction Type</p>
                <div className="scrollerDiv">
                {transactionType.length == 0 ? "NO Transaction Type Found" : ""}
              {
                transactionType?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" className="form-check-input" id="transactionChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
                ))
              }
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="mb-1 PartyStockLedger_2grid">
            <span style={{ color: '#000' }}>From Party &nbsp;</span>
            <select  style={{width:'190px'}}  onChange={(e) => setSelectedfromValue(e.target.value)}  >
              <option value="">Please select any value</option>
              {
                allpartyTyp?.map((item,i)=>(
                  <option value={item.Name}>{item.Name}</option>
                ))
              }
            </select>

            <br />
            <br />
            <span style={{ color: '#000' }}>To Party &nbsp;</span>
            <select  style={{width:'190px'}}  onChange={(e) => setSelectedToValue(e.target.value)}>
              <option value="">Please select any value</option>
              {
                allpartyTyp?.map((item,i)=>(
                  <option value={item.Name}>{item.Name}</option>
                ))
              }
            </select>
          </div>
          <br />
          <div className="mb-2 PartyStockLedger_2grid">
            <span style={{ color: '#000' }}>Print Type &nbsp;</span>
            <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="AToA" defaultChecked={true} />
              <label className="form-check-label text-dark" for="inlineRadio1">Detail</label>

            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Selected" />
              <label className="form-check-label text-dark" for="inlineRadio2">Summary</label>
            </div>
          </div>
          <br />
          <div className="mb-2 PartyStockLedger_2grid">
            <span style={{ color: '#000' }}>Print Narration &nbsp;</span>
            <div className="form-check form-check-inline ">

              <input className="form-check-input" type="radio" name="inlineRadioOption" id="inlineRadio1" value="AToA" defaultChecked={true} />
              <label className="form-check-label text-dark" for="inlineRadio1">Yes</label>

            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOption" id="inlineRadio2" value="Selected" />
              <label className="form-check-label text-dark" for="inlineRadio2">No</label>
            </div>
          </div>
          <br />
          <div className="mb-2">
            <span style={{ color: '#000' }}>Specific Item &nbsp;</span>
            <select  style={{width:'150px'}}>
              <option value="">Select Specific Item</option>
              {
                specificParty?.map((item,i)=>(
                  <option value={item.Mcode}>{item.Name}</option>
                ))
              }
            </select>
          </div>
          <br />
          <div className="row PricedStockLedger_2" style={{position:'sticky',bottom:"0px"}}>
          <div className="d-flex mt-2" style={{ }}>

            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>EXCEL </Button>&nbsp;
            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={getpartyStockView}>VIEW</Button>&nbsp;
            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>PDF</Button>&nbsp;
            <Button variant="contained" className={classes[companyColor?.split(',')[0]]}  onClick={goHome}>EXIT</Button>
          </div>
          </div>
        </div>
        <div className="balacepoupformright">
      <div className=''>
    {partystockledgerclm.length > 0 ? (
      <ReportTbl dataArray={rowData}
       TblColumns={partystockledgerclm}
        headerColor={companyColor} 
         />
    ) :(
      <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
        <thead className={classes[companyColor?.split(',')[0]]}> 
          <tr>
            <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>
  
          </tr>
        </thead>
        <tbody>
          <tr>
          <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
          
          </tr>
        </tbody>
      </table>
    )}
   </div>
  </div>
       
      </div>
    </>
  );
}