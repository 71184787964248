import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import ReportTbl from '../CollectionReport/ReportTbl';
import { COMPANY_CODE, GET_AGEING_REPORT, GET_AREA, GET_All_ACCOUNT, GET_All_ACCOUNT_GROUP, GET_CATEGORY_STOCK_STATUS, GET_FRANCHISE, GET_LEDGER_REPORT, LOGIN_DETAILS } from '../../../constant';
import ComingSoonPopup from '../PopUP/PopUp'

function AgeingAnalysis() {
    const classes = useStyles();
    const history = useHistory();
    const [TrailBalance, setTrailBalance] = useState([]);
    // const [nameOfAcc,setNameOfAcc] = useState("");
    const [isLoading, setisLoading] = useState(false)
    const [ldgReport, setLdgReport] = useState([]);
    const [ldgAccList, setLdgAccList] = useState([]);
    const [ldgAcList, setLdgAcList] = useState([]);
    const [LdgrAcList,setLdgrAcList]= useState([]);
    const [companyColor, setcompanyColor] = useState("")
    const [selectedLdgAccIndex, setSelectedLdgAccIndex] = useState(0);
    const [fromMcode, setfromMcode] = useState("")
    const [AllCategories, SetAllCategories] = useState([])
    const [SelectedCAtegoryValue, setSelectedCAtegoryValue] = useState('')
    const [AllArea, SetAllArea] = useState([])
    const [ageingcolm, setageingcolm] = useState([])
    const [Allfranchise, SetAllfranchise] = useState([])
    const [selectedFranchise, setselectedFranchise] = useState('')
    const [AgeingData, setAgeingData] = useState([])
    var today = new Date().toISOString().slice(0, 10);
    const [todaydate,settodaydate] = useState(today)
    const[popup,setpopup]=useState(false)
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [isdetailed,setisdetailed] = useState(true)
    const [selectedRadio, setSelectedRadio] = useState('true');
    const [selectedAgeing, setselectedAgeing] = useState('true');
     // Handle radio button change event
     const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
      };
      const handleChange = (e) => {
        setselectedAgeing(e.target.value);
      };
    const [ledgerAc, setLedgerAc] = useState({
        "FYear": LOGIN_DETAILS.FINANCIAL_YEAR,
        "CompanyNumber": COMPANY_CODE
    });


    useEffect(() => {
        setcompanyColor(localStorage.getItem('cmpnyType'))
        // fetchData();
        axios.post(GET_All_ACCOUNT, ledgerAc
        ).then((response) => {

            if (response.data.IsSuccess === true) {

                // console.log("all grouped account ", response.data.Data)
                setLdgAcList(response.data.Data);
            }
            else {
                // var emptList=[];
                setLdgAcList([]);
            }
        })

        axios.post(GET_All_ACCOUNT_GROUP, ledgerAc
            ).then((response) => {
    
                if (response.data.IsSuccess === true) {
    
                    // console.log("all grouped account ", response.data.Data)
                    setLdgrAcList(response.data.Data);
                }
                else {
                    // var emptList=[];
                    setLdgrAcList([]);
                }
            })

        // get all category 

        axios.post(GET_CATEGORY_STOCK_STATUS, {
            "CompanyNumber": COMPANY_CODE,
        }
        ).then((response) => {
            // console.log(response)
            if (response.data.IsSuccess === true) {
                SetAllCategories(response.data.Data);
            }
            else {
                // var emptList=[];
                SetAllCategories([]);
            }
        })

        // get area api
        axios.post(GET_AREA, {
            "CompanyNumber": COMPANY_CODE,
        }
        ).then((response) => {
            // console.log(response)
            if (response.data.IsSuccess === true) {
                // console.log("area", response.data.Data)
                SetAllArea(response.data.Data);
            }
            else {
                // var emptList=[];
                SetAllArea([]);
            }
        })

        //  get all frachise
        axios.post(GET_FRANCHISE, {
            "CompanyNumber": COMPANY_CODE,
        }
        ).then((response) => {
            // console.log(response)
            if (response.data.IsSuccess === true) {
                // console.log("franchise", response.data.Data)
                SetAllfranchise(response.data.Data);
            }
            else {
                // var emptList=[];
                SetAllfranchise([]);
            }
        })
    }, [])

    const handleSelectAllChange = () => {
        const checkboxes = document.querySelectorAll('#accountGroup, #area input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.checked =false;
        });
    };

    const TrailBalanceRow = [
        { field: 'Voucher', headerName: 'Voucher', width: 150 },
        { field: 'Nart', headerName: 'Naration', width: 150 },
        { field: 'Dabit', headerName: 'Debit', width: 150 },
        { field: 'Credit', headerName: 'Credit', width: 150 },
        { field: 'Currency', headerName: 'Currency', width: 150 },
        { field: 'atVal', headerName: '@', width: 150 },
    ]
    const [ledger, setLedger] = useState({
        "FormDate": new Date(),
        "ToDate": new Date(),
        "Mcode": "",
    });
    const onhandleAcChanges = (e) => {
        if (e.target.name == "fromAc" || e.target.name == "toAc") {
            let fromIndex = 0, toIndex = 0;
            ldgAcList.some((element, i) => {
                if (element.Mcode === e.target.value) {
                    e.target.name == "fromAc" ? fromIndex = i : toIndex = i;
                }
                setfromMcode(ldgAcList[fromIndex].Acname)
            });
            if (e.target.name == "toAc") {
                for (var i = fromIndex; i <= toIndex; i++) {
                    debugger;
                    setLdgAccList(ldgAccList => [...ldgAccList, ldgAcList[i].Mcode]);
                }
            }

        }
        else {
            setLdgAccList(ldgAccList => [...ldgAccList, e.target.value]);
        }
        setLedger({ ...ledger, "Mcode": e.target.value });


    }
    const fetchData = () => {
        // here we getting the all checked  values from ID of Parent Div in js :-)
        const AccountGroup = Array.from(document.querySelectorAll('#accountGroup input:checked')).map(checkbox => checkbox.value);
        const Selectedarea = Array.from(document.querySelectorAll('#Area input:checked')).map(checkbox => checkbox.value);
        const SelectedFranchise = Array.from(document.querySelectorAll('#franchise input:checked')).map(checkbox => checkbox.value);
       
       
        var isDetailed = JSON.parse(document.querySelector('input[name="isDetailed"]:checked').value) ? 1: 0;
        var isbillDate = JSON.parse(document.querySelector('input[name="isbillDate"]:checked').value) ? 1 :0 ;
    console.log("detailed data",isDetailed)
    console.log("Bill date data",isbillDate)
        const apiUrl = GET_AGEING_REPORT;
        const requestData = {

            CompanyNumber: COMPANY_CODE,
            "ToDate":todaydate,
            strAccountGroup: AccountGroup?.toString(),
            strArea: Selectedarea,
            strCategory: "",
            strFranchise: SelectedFranchise,
            AccountFrom: fromMcode,
            AccountTo: ledger.Mcode,
            BillDate: isbillDate,
            Detailed:isDetailed
        };
console.log("data", requestData)
        setisLoading(true);
        axios.post(apiUrl, requestData)
            .then(response => {
                setisLoading(false);
                if(response.data.IsSuccess == true){
                    var newData = response.data.Data.map((item,i)=>({...item,sno:i+1}))
                    // console.log("ageing anylisi report ",newData);
                //    console.log("ageing tbl ", newData[0]);
                   setAgeingData(newData);
                   let obj = {};
                   const keys = Object.keys(newData[0]);
                   const ageingcolm = []; 
                   keys.forEach((data, i) => {
                       if (i === keys.length - 1) {
                       } else {
                           const newObj = { "field": data, "headerName": data, width: 250 };
                           ageingcolm.push(newObj);
                       }
                   });
                   setageingcolm(ageingcolm);
                }
                else{
                    setAgeingData([]);  //  if their is no data then blank table [remove all objects ]
                }
              
            })
            .catch(error => {
                // console.error(error);
                setisLoading(false);
            });

        //
        axios.post(GET_LEDGER_REPORT, {
            "FormDate": ledger.FormDate,
            "ToDate": ledger.ToDate,
            "chkLocalCurrency": ledger.chkLocalCurrency,
            "Mcode": ldgAccList[selectedLdgAccIndex + 1],
            "CompanyNumber": COMPANY_CODE
        }
        ).then((response) => {
            // console.log(response)
            // console.log(response.data.Data)
            if (response.data.IsSuccess === true) {
                setLdgReport(response.data.Data);
                setisLoading(false)
            }
            else {
                setLdgReport([]);
                setisLoading(false)
            }
        })
    };

    const goHome =()=>{
        history.push('/dashboard')
      }
      const openpop =()=>{
        setpopup(true);
       }
       const closePopup = () => {
         setpopup(false);
       };
       
    return (
        <>
            {
                isLoading ? <div className='Commonloader'>
                </div> : null
            }
             {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
            <div className="balacepoupformmain AgeingAnalysis_main" >
                <div className="balacepoupformleft  allfont" style={{width:"35%"}}>
                    <div className="row">
                        <p className='text' style={{ marginBottom: "-10px" }}> Account Group</p>
                        <div className="col-sm-6 col-6 col-md-6" >
                            <div className="scrollerDiv" id='accountGroup'>
                                {
                                    LdgrAcList.map((item, i) => (
                                        <> <input type='checkbox' value={item.BCD} /> {item.DESCRIPTION}<br /></>
                                    ))
                                }

                                 </div>
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="clearAllCheckbox"
                                    onChange={handleSelectAllChange}
                                    />
                                &nbsp; Clear All
        </div>
                        <div className="col-sm-6 col-6 col-md-6">
                            <div className="scrollerDiv">


                            </div>
                            &nbsp;<input type="checkbox"
                             className="form-check-input"
                              id="clearAllCheckbox" 
                              onChange={handleSelectAllChange}
                              />&nbsp; Clear All
                        </div>

                    </div>

                    <div className='row '>
                        <div className="col-sm-4 col-4 col-md-4" >
                            <p className='text-center' style={{ marginBottom: "-10px" }}>Category </p>
                            <div className="scrollerDiv">
                                {/* <select className='stockwarehouse' style={{}} onChange={(e) => setSelectedCAtegoryValue(e.target.value)}>
                                    <option value="">ALL CATEGORIES</option>
                                    {
                                        AllCategories.map((item, i) => (
                                            <option value={item.BCD}>{item.NAME}</option>
                                        ))
                                    }
                                </select> */}

                            </div>
                            &nbsp;<input type="checkbox" className="form-check-input"  id="clearAllCheckbox" 
                              onChange={handleSelectAllChange}/> &nbsp; Clear All
                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <p className='text-center' style={{ marginBottom: "-10px" }}>Area</p>
                            <div className="scrollerDiv" id='Area'>
                                {AllArea.map((item, i) => (
                                    <span><input key={i} type='checkbox' value={item.ABVR} />&nbsp;<span>{item.DESCRIPTION}</span><br /></span>
                                ))}

                            </div>
                            &nbsp;<input type="checkbox" className="form-check-input"  id="clearAllCheckbox" 
                              onChange={handleSelectAllChange} />&nbsp; Clear All

                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <p className='text-center' style={{ marginBottom: "-10px" }}>Franchise</p>
                            <div className="scrollerDiv" id="franchise">
                                {
                                    Allfranchise.map((item, i) => (<>
                                        <input type='checkbox' value={item.AVBR} key={i} onChange={(e) => setselectedFranchise(e.target.value)} />&nbsp;{item.DESCRIPTION}<br />
                                    </>))
                                }

                            </div>
                            &nbsp;<input type="checkbox" className="form-check-input"
                              id="clearAllCheckbox" 
                              onChange={handleSelectAllChange}/>&nbsp; Clear All
                        </div>
                        <div className="col-sm-3 col-3 col-md-3">
                            <div className="">

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <p className="form-check-label text-dark my-1" for="">Date To : </p>
                            </div>
                        </div>
                        <div className="col-sm-4 col-4 col-md-4" style={{ marginBottom: "8px", marginTop: "3px" }}>
                            <div className="">
                                <li >
                                    <input type="date" className="" id="" name=""value={todaydate} onChange={(e)=>settodaydate(e.target.value)} />
                                </li>
                            </div>

                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <li >
                                    &nbsp;  <input type="checkbox" className="form-check-input" id="two" name="" value="" />&nbsp; Exclude
                                </li>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Account From  :</p>
                            </div>
                        </div>
                        <div className="col-sm-8 col-8 col-md-8" style={{ marginBottom: "8px" }}>
                            <div className="">
                                <select className={`form-select ${classes.AccInput}`} name="fromAc" onChange={e => { onhandleAcChanges(e) }}>
                                    <option>Select Account</option>
                                    {
                                        ldgAcList.map((data, i) => {
                                            return (<option key={i} value={data.Mcode}>{data.Acname}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <p className="form-check-label text-dark my-1" for="inlineRadio2">Account To  :</p>
                            </div>
                        </div>
                        <div className="col-sm-8 col-8 col-md-8" style={{ marginBottom: "8px" }}>
                            <div className="">
                                <select className={`form-select ${classes.AccInput}`} name="toAc" onChange={e => { onhandleAcChanges(e) }}>
                                    <option >Select Account</option>
                                    {
                                        ldgAcList.map((data, i) => {
                                            return (<option key={i} value={data.Mcode}>{data.Acname}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <p className="form-check-label text-dark my-1" for="inlineRadio2"> Detail/Summary </p>
                            </div>
                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <li >
                                    <input type="radio" className="form-check-input" id="detail" name="isDetailed" value={true}
                                      checked={selectedRadio === 'true'} // Reflect the selected state
                                     onChange={handleRadioChange} />&nbsp; Detailed
                                </li>
                            </div>    

                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <input type="radio" className="form-check-input" id="detaile" name="isDetailed" value={false} 
                                checked={selectedRadio === 'false'} // Reflect the selected state
                                onChange={handleRadioChange}
                               />&nbsp; Summarised

                            </div>
                        </div>
                        <div className="col-sm-3 col-3 col-md-3">
                            <div className="">

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <p className="form-check-label text-dark my-1" for="inlineRadio2"> Ageing By </p>
                            </div>
                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <li >
                                    <input type="radio" className="form-check-input" id="bill" name="isbillDate" value={true}
                                    checked={selectedAgeing === 'true'} 
                                     onChange={handleChange} />&nbsp; Bill Date
                                </li>
                            </div>
                        </div>
                        <div className="col-sm-4 col-4 col-md-4">
                            <div className="">
                                <input type="radio" className="form-check-input" id="billdate" name="isbillDate" value={false}
                                  checked={selectedAgeing === 'false'} 
                                  onChange={handleChange} 
                                 />&nbsp; Ledger Date
                            </div>
                        </div>
                        <div className="col-sm-3 col-3 col-md-3">
                            <div className="">

                            </div>
                        </div>
                    </div>


                    <div className='row text-center'>
                        <div className='col-sm-12 col-12 col-lg-12'>
                            <p>  Credit Limit (Days) In Ascending Order</p>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-6 col-md-6" >
                            <div className='col'>Credit Limit 1 <input type="text" style={{ width: "40px", marginBottom: "3px" }} /></div>
                            <div className='col'>Credit Limit 2 <input type="text" style={{ width: "40px", marginBottom: "3px" }} /></div>
                            <div className='col'>Credit Limit 3 <input type="text" style={{ width: "40px", marginBottom: "3px" }} /></div>
                        </div>
                        <div className="col-sm-6 col-6 col-md-6">
                            <div className='col'>Credit Limit 4 <input type="text" style={{ width: "40px", marginBottom: "3px" }} /></div>
                            <div className='col'>Credit Limit 5 <input type="text" style={{ width: "40px", marginBottom: "3px" }} /></div>
                            <div className='col'>Credit Limit 6 <input type="text" style={{ width: "40px", marginBottom: "3px" }} /></div>
                        </div>
                    </div>

                    <div className='row' style={{position:'sticky',bottom:"0px"}}>
                        <div className='col-sm-3'>Line Per<input type="text" style={{ width: "30px", marginTop: "10px", marginBottom: "20px" }} /> </div>
                        <div className='col-sm-3'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>EXCEL</Button></div>
                        <div className='col-sm-3'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={fetchData} >VIEW</Button></div>
                        <div className='col-sm-3'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>PDF</Button></div>
                        <div className='col-sm-3'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]}  onClick={goHome}>EXIT</Button></div>
                    </div>


                </div>
                <div className="balacepoupformright">
                {ageingcolm.length > 0 ? (
    <ReportTbl
      dataArray={AgeingData}
      TblColumns={ageingcolm}
      headerColor={companyColor}
       
    />
  ) : (
    <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
      <thead className={classes[companyColor?.split(',')[0]]}> 
        <tr>
          <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>

        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
        
        </tr>
      </tbody>
    </table>
  )}
</div>              
            </div>
        </>
    );
}

export default AgeingAnalysis;