import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,TextField,
  Checkbox
} from "@material-ui/core";
import {useHistory } from "react-router-dom"
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./styles";
// import Table from "./components/Table/Table";
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable from "mui-datatables";
import MoreOption from "../EmployeeMaster/components/moreOption/MoreOption";
import { DataGrid,GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useEffect } from "react";
import {GET_All_ACCOUNT,LOGIN_DETAILS,GET_LEDGER_REPORT} from '../../constant';
import axios from "axios";
import CollectionReport from "./CollectionReport/CollectionReport";
import LedgerReport from "./LedgerReport/LedgerReport";
import PartyStockLedger from "./PartyStockLedger/PartyStockLedger";
import TrialBalance from "./TrialBalance/TrialBalance";
import Gstr from "./Gstr/Gstr";
import AccountStatus from "./AccountStatus/AccountStatus";
import AllwarehouseStock from "./AllwarehouseStock/AllwarehouseStock";
import PricedStockLedger from "./PricedStockLedger/PricedStockLedger";
import SaleRegister from "./SaleRegister/SaleRegister";
import PurchaseRegister from "./PurchaseRegister/Purchaseregister"
import StockStatus from "./StockStatus/StockStatus";
import StockStatement from "./StockStatement/StockStatement"; 
import FsnAnalysis from "./FsnAnalysis/FsnAnalysis";
// import AgeingAnalysis from "./AgeingAnalysis/AgeingAnalysis";
import {
  useParams
} from "react-router-dom";
import AgeingAnalysis from "./AgeingAnalysis/AgeingAnalysis";
import ProfitLoss from "./ProfitLoss/ProfitLoss";
import BalanceSheet from "./BalanceSheetReport/BalanceSheetReport";
import IndentStatusReport from "./IndentStatus/IndentStatus";
import SaleOrderStatus from "./SaleOrderStatus/SaleOrderStatus";
import PurchaseOrderStatus from "./PurchaseOrderStatus/PurchaseOrderStatus";
export default function Reports(props) {
  let { reportType } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const [formdetails,setformdetails] = useState([])
  const [tabledata,settabledata] = useState([])
  const [reportname,setreportname]  = useState("")
  const [isNxtdis,setIsNxtdis]  = useState(false);
  const [isSelectAc,setIsSelectAc]  = useState(false);
  const [ldgAcList,setLdgAcList] = useState([]);
  const [ldgReport,setLdgReport] = useState([]);
  const [ldgAccList,setLdgAccList] = useState([]);
    const [selectedLdgAccIndex,setSelectedLdgAccIndex] = useState(0);
  const [startdate,setstartdate] = useState("")
  const [enddate,setenddate] = useState("")
  const [collectionreporttbl,setcollectionreporttbl] = useState([]);
  //const [collectionreporttbl,setcollectionreporttbl] = useState([])
  const [isReportPage,setisReportPage] = useState(false)
  const [maintbl,setmaintbl]  = useState(true)
  const [salecollectiontbl,setsalecollectiontbl] =useState(false);
  const [ledgerAc,setLedgerAc] = useState({
    "FYear":LOGIN_DETAILS.FINANCIAL_YEAR
  });
  //const ToDate=`${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}`;
  const collectionsummaryclm =[
    {
        field: "CreatedDate",
        headerName: "Created AT",
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
      
          return (
            <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
          );
        }
      },
    { field: 'Type', headerName: 'Type', width: 100 },
    { field: 'UserName', headerName: 'User Name', width: 160 },
    { field: 'SystemName', headerName: 'System Name', width: 160   },
    { field: 'Amount', headerName: 'Amount', width: 100   },
    { field: 'Paytm', headerName: 'Paytm', width: 130 },
    { field: 'cash', headerName: 'Cash', width: 130 },
    { field: 'Credit', headerName: 'Credit', width: 150 },
  ]
  const [ledger,setLedger] = useState({
    "FormDate":new Date(),
    "ToDate":new Date(),
    "chkLocalCurrency":0,
    "Mcode":""
  });

  const goBackPage=()=>
  {
     //navigate(-1);
    history.goBack();
  }
  const handleLedgerChange=(e)=>
  {
    if(e.target.name==="chkLocalCurrency" )
    {
      setLedger({...ledger,[e.target.name]:e.target.checked});
    }
    else
    {
      if(e.target.name==="Mcode")
      {
        if(e.target.checked)
        {
          setLdgAccList(ldgAccList => [...ldgAccList,e.target.value] );
        }
        else{
          if(ldgAccList.indexOf(e.target.value)>-1)
          {
            ldgAccList.splice(ldgAccList.indexOf(e.target.value), 1);
          }
        }
      }
        setLedger({...ledger,[e.target.name]:e.target.value});
    }
  }
  useEffect(() => {
   
    var currentUrl = window.location.href;
    if (currentUrl.indexOf("reports") !== -1) {
      setisReportPage(true)
  
    } else {
      setisReportPage(false) 
       }
  //  document.querySelector('.TOGGLEicon').click();
  setreportname(currentUrl.split("/")[3]);
  },[reportType]);
const acTypeClicked=(type)=>
{
  if(type==="Selected")
  {
    setIsSelectAc(true);
  }
  else{
    setIsSelectAc(false);
  }
  setSelectedLdgAccIndex(0);
  setLedger({...ledger,"Mcode":""});
}
const getLedgerReportInNxtBtn=()=>
{
  //localStorage.setItem('ldgAccList_index', selectedLdgAccIndex);

  setSelectedLdgAccIndex(selectedLdgAccIndex+1);

}
const onhandleAcChanges=(e)=>
{
  setLdgAccList(ldgAccList => [...ldgAccList,e.target.value] );
  setLedger({...ledger,"Mcode":e.target.value});
}
const getLedgerReport=()=>
{
  if(ledger.FormDate===""||ledger.ToDate===""||ledger.Mcode==="")
  {
    alert("Account and from date and to date should not blank.");
  }
  else{
    axios.post(GET_LEDGER_REPORT,
      {
        "FormDate":ledger.FormDate,
        "ToDate":ledger.ToDate,
        "chkLocalCurrency":ledger.chkLocalCurrency,
        "Mcode":ldgAccList[0]
      }
      ).then((response)=>{
        if(response.data.IsSuccess === true){
          setLdgReport(response.data.Data);
          setSelectedLdgAccIndex(0);
        }
        else{
          // var emptList=[];
          setLdgReport([]);
        }
      })
  }
}

  const LedgerTblRow =[
    { field: 'CreatedDate', headerName: 'Created At', width: 150   },
    { field: 'Voucher', headerName: 'Voucher', width: 150   },
    { field: 'Nart', headerName: 'Naration', width: 150 },
    { field: 'Dabit', headerName: 'Debit', width: 150 },
    { field: 'Credit', headerName: 'Credit', width: 150 },
    { field: 'Currency', headerName: 'Currency', width: 150 },
    { field: 'atVal', headerName: '@', width: 150 },
  ]
  const acconutstatusclm =[
    { field: '+', headerName: '+', width: 30   },
    { field: 'Sno', headerName: 'S No', width: 90   },
    { field: 'AccountName', headerName: 'Account Name', width: 140 },
    { field: 'OpeningDebit', headerName: 'Opening Debit', width: 140 },
    { field: 'OpeningCredit', headerName: 'Opening Credit', width: 150   },
    { field: 'Debit', headerName: 'Debit', width: 130   },
    { field: 'Credit', headerName: 'Credit', width: 130 },
    { field: 'ClosingDebit', headerName: 'Closing Debit', width: 150 },
    { field: 'ClosingCrebit', headerName: 'Closing Credit', width: 150 },
    { field: 'Currency', headerName: 'Currency', width: 150 },

  ]


const ClientMoreOption=()=>
{

}
  const ClientList = [
    [1, "Yonkers", "NY","nxjvkxkjfvkjfk","7467547474","deepali","7587568686","jhht7887"],
    [2, "Hartford", "CT","nxjvkxkjfvkjfk","7467547474","deepali","7587568686","jhht7887"],
    [3, "Tampa", "FL","nxjvkxkjfvkjfk","7467547474","deepali","7587568686","jhht7887"],
    [4, "Dallas", "TX","nxjvkxkjfvkjfk","7467547474","deepali","7587568686","jhht7887"],
    [5, "Hartford", "CT","nxjvkxkjfvkjfk","7467547474","deepali","7587568686","jhht7887"],
   
  ];
  const ClientColumn=["Client Id",
   "Client Name",
   "Address",
   "Mobile No.",
   "Contact Name",
   "Contact Number",
   "GST Number",
   {
    label: "",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <MoreOption className={classes.showMore} onClick={e=>{ClientMoreOption()}}/>
            )
        }
    }
  }]

const handlesaleSummaryRbtn =()=>{
  setsalecollectiontbl(true)
  setmaintbl(false)

}

  return (
    <>
       <Grid  >
       {/* {
        isReportPage ? 
        <p className="report_reportname" > &nbsp;&nbsp;Reports&nbsp; &gt; &nbsp;<span style={{textTransform:'capitalize'}}>{reportname}</span></p>
 :null
       } */}
      
 {
  reportname == "generalLeadure" ? 
  <>
      <LedgerReport reportname={reportname}/>
  </>:""
 }
 {
  reportname == "PartyStockLedger" ? 
  <>
  
      <PartyStockLedger reportname={reportname}/>
  </>:""
 }
  {
  reportname == "AgeingAnalysis" ? 
  <>
  
      <AgeingAnalysis reportname={reportname}/>
  </>:""
 }
 {
  reportname =="AccountStatus" ? 
  <>
      <AccountStatus reportname={reportname}/>
  </>:""  
 } 

{
  reportname == "AllwarehouseStock" ? 
  <>  
      <AllwarehouseStock reportname={reportname}/>
  </>:""
 }    
 {
  reportname == "TrailBalance" ? 
  <>
  
      <TrialBalance reportname={reportname}/>
  </>:""
 }   
 
  {
  reportname =="collectionReport" ? 
<CollectionReport/>
   :""
  
 } 
   {
  reportname =="Gstr" ? 
<Gstr/>
   :""
  
 } 

{
  reportname =="pricedstockledger" ? 
<PricedStockLedger/>
   :""
  
 } 

{
  reportname =="saleregister" ? 
<SaleRegister/>
   :""
  
 }
{
  reportname =="purchaseregister" ? 

<PurchaseRegister/>
   :""
  
 }
{
  reportname =="stockstatus" ? 
<StockStatus/>
   :""
  
 }

 {
  reportname =="stockstatement" ?
<StockStatement/>
  :""
  
 }

{
  reportname =="profitloss" ?
<ProfitLoss/>
  :""
  
 }
 
 {
  reportname =="balancesheet" ?
  <BalanceSheet/>
  :""

 }
  
  {
  reportname =="indentstatus" ?
  <IndentStatusReport/>
  :""

 }
  
  {
  reportname =="purchaseorderstatus" ?
  <PurchaseOrderStatus/>
  :""

 }
  
  {
  reportname =="saleorderstatus" ?
  <SaleOrderStatus/>
  :""

 }
  {

reportname =="fsnanalysis" ?

<FsnAnalysis/>

:""



}
 </Grid>
    </>
  );
}

