import { Button, Grid } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import useStyles from "../PartyStockLedger/style";
import { COMPANY_CODE, GET_ALL_CATEGORIES, GET_PROFIT_AND_LOSS, GET_VIEW_ACCOUNT_REPORT } from '../../../constant';
import axios from 'axios';
import ReportTbl from '../CollectionReport/ReportTbl';
import ComingSoonPopup from '../PopUP/PopUp';
function ProfitLoss() {
  const classes = useStyles();
  const history = useHistory();
  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [fromdate, setfromdate] = useState(today)
  const [todate, settodate] = useState(nextday)
  const [isLoading,setisLoading] = useState(false);
  const[popup,setpopup]=useState(false);
  const [acconutstatus, setacconutstatus] = useState([]);
  const [companyColor, setcompanyColor] = useState("")
  const [AllCategories,SetAllCategories] =useState([])
  const [selectedValue, setSelectedValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
const [acconutstatusclm,setacconutstatusclm] = useState([])


  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
    //API FOR GET ALL CATEGORIES
    axios.post(GET_ALL_CATEGORIES, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
      console.log(response)
      if (response.data.IsSuccess === true) {
        SetAllCategories(response.data.Data);
      }
      else {
        // var emptList=[];
        SetAllCategories([]);
      }
    })

  }, [])




  const handleShowData = () => {
    if (document.querySelector('input[name="openingbalanceYes"]:checked') == null || document.querySelector('input[name="printstatement"]:checked' == null)) {
      alert("Please Select opening balance and Print Statement ! ");
    } else {
      debugger;
      var opendingbalance = JSON.parse(document.querySelector('input[name="openingbalanceYes"]:checked')?.value) || false;
      var printstatement = document.querySelector('input[name="printstatement"]:checked')?.value || false;
      var displayval = Number(document.querySelector('#displayLevel')?.value) || 3;
      setisLoading(true);
      axios.post(GET_PROFIT_AND_LOSS, {
        "CompanyNumber": COMPANY_CODE,
        "FormDate": fromdate,
        "ToDate": todate,
        "Detailed": opendingbalance,
        "Summarise": printstatement,
        "count": displayval,
      }).then((response) => {
        
        console.log(response);
        if (response.data.IsSuccess === true) {
          setisLoading(false);
          var newdata = response?.data.Data.map((item, i) => ({ ...item, sno: i + 1 }));
          setacconutstatus(newdata);
  
          const keys = Object.keys(response.data.Data[0]);
          const profitlossCol = [];
          keys.forEach((data, i) => {
            if (data.toLowerCase() !== "") { 
              const newObj = { "field": data, "headerName": data, width: 250 };
              profitlossCol.push(newObj);
            }
          });
  
          setacconutstatusclm(profitlossCol);
        } else {
          setacconutstatus([]);
        }
      });
    }
  };
  

  // with opening blance
  const goHome =()=>{
    history.push('/dashboard')
  }
  const openpop =()=>{
    setpopup(true);
   }
   const closePopup = () => {
     setpopup(false);
   };
   
  return (
    <>
    {
      isLoading ? <div className='Commonloader'>
      </div> : null
    }
     {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
    <div className="balacepoupformmain " >
      <div className='balanceformleft'>
          <div className='rowprofitlosswd '>

            <p className=''  style={{display:""}}>Statement For The Period From: &nbsp;&nbsp;
            <span className='datefromrow'>
              <input type="date"  value={fromdate} style={{}} onChange={(e) => setfromdate(e.target.value)} />
              </span>
            <span className='datetorow'>
              &nbsp;&nbsp;Period To:&nbsp;&nbsp;
            
              <input type="date" value={todate} onChange={(e) => settodate(e.target.value)} />
            </span> </p>
        
          </div>
          
          <div>
                <p className="" id="openingBalance" style={{display:"flex"}} >With Opening Balance&nbsp;&nbsp;
                    <input type="radio" className="form-check-input" id="" name="openingbalanceYes" value="true" 
                    
                    />&nbsp;&nbsp;Yes&nbsp;&nbsp;
                    <input type="radio" className="form-check-input" id="" name="openingbalanceYes" value="false"
                     
                      />&nbsp;&nbsp;No &nbsp;&nbsp; 
                   </p>
            </div>
            <div>
                <p className="" style={{display:"flex"}} for="inlineRadio2">Print Statement&nbsp;&nbsp;
                    <input type="radio" className="form-check-input" id="" name="printstatement" value="true" />&nbsp;&nbsp;Statement Only&nbsp;&nbsp;
                    <input type="radio" className="form-check-input" id="" name="printstatement" value="false" />&nbsp;&nbsp;Statement With Schedule&nbsp;&nbsp;
                    </p>  
            </div>
            <div>
            <p className="" style={{display:"flex"}} >Display Level&nbsp;&nbsp;&nbsp;
            <input type='number' id='displayLevel' style={{paddingRight:"6px"}}  disabled
             ></input>
            </p>
            </div>
            </div>

            <div className='profitlosstbl'>
    {acconutstatusclm.length > 0 ? (
      <ReportTbl dataArray={acconutstatus}
       TblColumns={acconutstatusclm}
        headerColor={companyColor} 
          />
    ) : (
      <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
        <thead className={classes[companyColor?.split(',')[0]]}> 
          <tr>
            <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>
  
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000" }}>NO Data Found &nbsp;</td>
          
          </tr>
        </tbody>
      </table>
    )}
   
  </div>
      {/* <div className='table animatingBottom whiteheader' style={{ height: "500px", width: "100%", background: '#fff' }}>
        <DataGrid
          rows={acconutstatus}
          columns={acconutstatusclm}
          pageSize={7}
          className={classes[companyColor?.split(',')[0]]}
          getRowId={(row) => row.sno}
          {...acconutstatus}
        />
      </div> */}
      <div className='profitbtnn' >
                <div className='col ' >
                 <div className='' style={{width:"100%",paddingBottom:"28px", display:"flex"}} >  
                 <Button variant='contained' style={{width:"25%"}} className={classes[companyColor?.split(',')[0]]}onClick={openpop}>Excel </Button>&nbsp;
                <Button variant="contained" style={{width:"25%"}} className={classes[companyColor?.split(',')[0]]}  onClick={handleShowData} >View</Button>&nbsp;  
                <Button variant="contained"  style={{width:"25%"}} className={classes[companyColor?.split(',')[0]]}  onClick={openpop}>Pdf</Button>&nbsp;
                <Button variant="contained"  style={{width:"25%"}} className={classes[companyColor?.split(',')[0]]}  onClick={goHome} >Exit</Button>
                </div> 
                </div>
            </div>

    </div>
    </>
  );
}
export default ProfitLoss;