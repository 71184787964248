import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button1 } from '@material-ui/core';

import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import axios from "axios";
// styles
import useStyles from "./styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GET_ALL_CATEGORIES, COMPANY_CODE, GET_ITEMS } from "../../constant";

export default function ItemsList(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [quantity, setQuantity] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [companyColor, setcompanyColor] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0); 
  const [selectedDate, setSelectedDate] = useState('');
  // const [showPopup, setShowPopup] = useState(false);

  
  const pageSize = 30;

  useEffect(() => {
    axios.post(GET_ALL_CATEGORIES, { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log(response)
        if (response.data.IsSuccess === true) {
          setCategories(response.data.Data)
        }
      })
  }, []);

  const handleIncrease = (index) => {
    const updatedItems = [...items];
    const updatedItem = { ...updatedItems[index] };
    updatedItem.perItemQty += 1;
    updatedItems[index] = updatedItem;
    setItems(updatedItems);

    // Update the total amount
    setTotalAmount(totalAmount + updatedItem.saleRate);
  };

  const handleDecrease = (index) => {
    const updatedItems = [...items];
    const updatedItem = { ...updatedItems[index] };
    if (updatedItem.perItemQty > 0) {
      updatedItem.perItemQty -= 1;
      updatedItems[index] = updatedItem;
      setItems(updatedItems);

      // Update the total amount
      setTotalAmount(totalAmount - updatedItem.saleRate);
    }
  };

  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType').split(",")[0])
    var a = window.location.href;
  });

  const handlePurchase = () => {
    // setShowPopup(true);
    // console.log("Purchase action triggered");
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const filteredItems = items.filter((item) =>
    item.itemName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const paginatedItems = filteredItems.slice(
    (page - 1) * pageSize,
    page * pageSize
  );
  var settings = {
    autoplay: true,
    speed: 1000,
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }]
  };

  const fetchItemsByCategory = async (categoryCode) => {
    try {
      const response = await axios.post(GET_ITEMS, {
        CompanyNumber: COMPANY_CODE,
        Category: categoryCode
      });
      const itemList = response.data.Data;
      setItems(itemList);
      setTotalPages(Math.ceil(itemList.length / pageSize));
      setPage(1); //  the first page
     
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const setCateg = (categoryCode) => {
    setSelectedCategory(categoryCode);
  };

  useEffect(() => {
    if (selectedCategory !== "") {
      fetchItemsByCategory(selectedCategory);
    }
  }, [selectedCategory]);

  const calculateAmount = (saleRate, quantity) => {
    return quantity * saleRate;
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <>
      {isLoading ? <div className='Commonloader'></div> : null}
      <center><h6 style={{ fontWeight: '600', marginTop: "30px" }}>Client Name: James Allen</h6></center>
      <div className='accountsearchbilling'>
        <Grid container spacing={6} className="pb-0">
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <form className="search">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  placeholder="Search..."
                  style={{
                    height: '45px',
                    width: '80%',
                    padding: '5px',
                    border: '1px solid #fff',
                    borderRadius: '20px 0px 0px 20px',
                    boxShadow: '0px 2px 2px rgba(137, 137, 137, 0.25)',
                  }}
                />
                <button
                  style={{
                    backgroundColor: "#fff",
                    marginLeft: '6px',
                    padding: '5px 10px',
                    height: '45px',
                    border: '1px solid #fff',
                    borderRadius: "0px 20px 20px 0px",
                    cursor: 'pointer',
                    boxShadow: '0px 2px 2px rgba(137, 137, 137, 0.25)',
                  }}
                >
                  <SearchIcon name="apps-outline"></SearchIcon>
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>

      <div className="slickslider2" style={{ margin: "30px" }}>
       
         <Slider {...settings}>
          {categories?.map((category, index) => (
            <div key={index}>
              <div className={`card py-1 ${classes.row3Card}`}>
                <div className="card-body" onClick={() => setCateg(category.categoryCode)}>
                  <h6 className={`card-title my-0 fw-bold`}>{category.categoryName}</h6>
                </div>
              </div>
            </div>
          ))}
        </Slider>

      </div>
     
      <Grid container spacing={6} className={companyColor}>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
          <div className={`card ${classes.Card}`}>
            <div className="card-body d-flex justify-content-between align-items-center">
              <div style={{ flex: '1 1 50%' }}>
                <h6 className={`card-title my-0 fw-bold`}>Item Name</h6>
              </div>
              <div className="d-flex align-items-center" style={{ flex: '1 1 25%' }}>
                <div className="d-flex align-items-center">
                  <h6 className={`card-title my-0 fw-bold`} style={{marginLeft:"22px"}}>Quantity</h6>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h6 className={`card-title my-0 fw-bold`}>Amount</h6>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        {paginatedItems.map((item, index) => (
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0" key={index}>
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body d-flex justify-content-between align-items-center">
              <div  style={{ flex: '1 1 50%' }}>
                <h6 className={`card-title my-0 fw-bold`}>{item.itemName}</h6>
                <h6 className={`card-subtitle mt-2 text-muted`}>{item.saleRate}</h6>
              </div>
              <div className="d-flex align-items-center" style={{ flex: '1 1 25%' }}>
                <div className="d-flex align-items-center">
                  <button className={`btn btn-primary btn-sm rounded-circle me-2 special-button ${classes[companyColor + 'BG']}`} onClick={() => handleDecrease(index)}>
                    <span className="button-label">-</span>
                  </button>
                  <h6 className={`card-subtitle m-1 text-muted`}>{item.perItemQty}</h6>
                  <button className={`btn btn-primary btn-sm rounded-circle me-2 special-button ${classes[companyColor + 'BG']}`} onClick={() => handleIncrease(index)}>
                    <span className="button-label">+</span>
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-center" >
                <div className="d-flex align-items-center">
                  <h6 className={`card-title my-0 fw-bold`}>Rs. {calculateAmount(item.saleRate, item.perItemQty)}</h6>
                </div>
              </div>
            </div>
          </div>
        </Grid>
         ))}
        <div className="fixed-card custom-fixed-card" style={{width:"100%"}}>  
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
          <div className={`card ${classes.Card}`}>
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h6 className={`card-title my-0 fw-bold`}>Total</h6>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h6 className={`card-title my-0 fw-bold`}></h6>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h6 className={`card-title my-0 fw-bold`}>Rs. {totalAmount}</h6>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <div className={`card ${classes.Card}`}>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px' }}>
        <div>
      <label htmlFor="date" style={{margin:"15px"}}>Pick Date:</label>
      <input
        type="date"
        id="date"
        value={selectedDate}
        placeholder="Select Date"
        onChange={handleDateChange}
      />
    </div>
          <Button variant="contained" color="primary" onClick={handlePurchase} style={{color:"#fff"}}>Place Order</Button>
        </div>
        </div>
      
        </div>
      </Grid>
      {/* <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
      <DialogTitle>Order Confirmation</DialogTitle>
      <DialogContent>
        <p>Are you sure you want to place the order?</p>
      </DialogContent>
      <DialogActions>
        <Button1 onClick={() => setShowPopup(false)}>Cancel</Button1>
        <Button1 onClick={() => {
          setShowPopup(false);
          // Handle the purchase action here
          // You can use the selectedCategory and quantity values for further processing
          console.log("Purchase action triggered");
        }} color="primary">
          Confirm
        </Button1>
      </DialogActions>
    </Dialog> */}
    </>
  );
}
