import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  tableViewContainer: {
    marginTop: "3%",

  },
        tableOverflow: {
    overflow: 'auto'
  },
  formContainter:{
    // backgroundImage: "linear-gradient(96deg, #526bf726, #f54b4b21)",
    paddingLeft:"5%",
    paddingRight:"5%",
    background:"#c1c1c140",
    paddingLeft:theme.spacing(3),
    paddingRight:theme.spacing(3),
    paddingTop:theme.spacing(1),
    paddingBottom:theme.spacing(4),
  },

}));
