import React from 'react';

function ComingSoonPopup({ onClose }) {
  const closeButtonStyle = {
    background: "#7d848b",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "4px",
    transition: "background 0.3s, color 0.3s", // Add transition for smooth hover effect
  };

  const closeButtonHoverStyle = {
    background: "#666", // Change the background color on hover
    color: "#fff", // Change the text color on hover
  };

  return (
    <div className="comingSoonPopupOverlay">
      <div className="comingSoonPopup">
        <div className="comingSoonPopupContent">
          <p><strong>This feature is coming soon.</strong></p>
          <button
            onClick={onClose}
            style={closeButtonStyle}
            onMouseEnter={(e) => Object.assign(e.target.style, closeButtonHoverStyle)}
            onMouseLeave={(e) => Object.assign(e.target.style, closeButtonStyle)}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default ComingSoonPopup;
