import { makeStyles } from "@material-ui/styles";


export default  makeStyles((theme) => ({
    formContainer: {
      width: '400px',
      margin: '0 auto',
      padding: theme.spacing(2),
      border: '1px solid #ccc',
      borderRadius: '5px',
      background: '#f9f9f9',
    },
    formGroup: {
      marginBottom: theme.spacing(2),
    },
    label: {
      display: 'block',
      marginBottom: theme.spacing(1),
    },
    input: {
      width: '100%',
      padding: theme.spacing(1),
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxSizing: 'border-box',
    },
    textarea: {
      width: '100%',
      padding: theme.spacing(1),
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxSizing: 'border-box',
      resize: 'vertical',
    },
    select: {
      width: '100%',
      padding: theme.spacing(1),
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxSizing: 'border-box',
    },
    button: {
      padding: theme.spacing(1, 2),
      background: '#4caf50',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    
    pos: {
        color: theme.palette.primary.POS_primary,
      
      },
      production: {
        color: `#111`,

      },
      order_management: {
        color: theme.palette.primary.OrderMngmt_primary,
        
      },
      restaurantBG: {
        backgroundColor: theme.palette.primary.Rastorant_primary,
        color: theme.palette.primary.whiteColor,
      
      },
      posBG: {
        backgroundColor: theme.palette.primary.POS_primary,
        color: theme.palette.primary.whiteColor,
      },
      productionBG: {
        backgroundColor: theme.palette.primary.Production_primary,
        color: theme.palette.primary.whiteColor, 
      },
      order_managementBG: {
        backgroundColor: theme.palette.primary.OrderMngmt_primary,
        color: theme.palette.primary.whiteColor,
      },
      
      dashSideBtnMain:
{
   textAlign: "end"
 },
 dashSideBtn:
 {
fontSize:"12px",
 backgroundColor: "#fff",
  color: 'Black',
  marginRight:'5px',
   padding: '8px 10px',
   height:"35px",
   width: "115px",
   border: "1px solid #CDCDCD",
    boxShadow: `0px 1px 1px ${theme.palette.text.light}`,
    borderRadius: "10px"
},
  }));
  