import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel, ListItemText
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import CountUp from 'react-countup';
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Label,
  Area,
  PieChart, BarChart, Bar,
  Pie, Tooltip,
  Cell, LineChart,
  YAxis,
  XAxis, CartesianGrid, LabelList
} from "recharts";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import axios from "axios";
// styles
import useStyles from "./styles";
import noti from '../../images/hrx.png'
// Utiles
import PageTitle from "../../Utiles/PageTitle/PageTitle";
import Searchbar from "../../Utiles/Searchbar/SearchBar";
import TotalBilImg from '../../images/pos/totalBillImg.png';
import SalesAnalysisImg from '../../images/pos/SalesAnalysis.png';
import loadbtn from '../../images/buttonloader.gif';
import SaleItemImg from '../../images/pos/SaleItem.png';
import Alertmessage from "../../Utiles/Alert/Alertmessage";
import {
  COMPANY_CODE, GET_CATEGORY_SALE_REPORT, GET_TOTAL_COLLECTION_BY_DATE, GET_GRAPH_DATA,GET_ALL_WAREHOUSES,
  GET_ITEMS_BELOW_ROL, GET_SALES_ANALYSIS_COUNT, GET_TOP_TEN_SALE_ITEM,
  GET_TOTAL_BILL_OF_DAY, LOGIN_DETAILS, GET_COLLECTION_REPORT
} from "../../constant";

const mainChartData = getMainChartData();
export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();

  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(today)    // need to set here today variable 
  const [enddate, setenddate] = useState(nextday);
  const [todaysdate, settodaysdate] = useState("")



  // local
  const [billoftheday, setbilloftheday] = useState(0);
  const [loginname, setloginname] = useState(localStorage.getItem('username'))
  const [topTenSaleItem, settopTenSaleItem] = useState([])
  const [ChartCategory, setChartCategory] = useState([])
  const [salsAnalysis, setsalsAnalysis] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [CurrentLiveEnquirispopupdata, setCurrentLiveEnquirispopupdata] = useState([])
  const [pendingRFQspopupdata, setpendingRFQspopupdata] = useState([])
  const [Pendingpopopupdata, setPendingpopopupdata] = useState([])
  const [debitorbalancedata, setdebitorbalancedata] = useState([])
  const [creditorbalancedata, setcreditorbalancedata] = useState([])
  const [graphDate, setGraphDate] = useState([]);
  const [popupHeader, setpopupHeader] = useState("")
  const [pendingreqpopup, setpendingreqpopup] = useState(false)
  const [currentlivepopup, setcurrentlivepopup] = useState(false)
  const [pendingPOpopup, setpendingPOpopup] = useState(false)
  const [dibitorbalancepopup, setdibitorbalancepopup] = useState(false)
  const [crediorbalancepopup, setcrediorbalancepopup] = useState(false)
  const [SelectedRows, setSelectedRows] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [isform, setisform] = useState(false)
  const [companyColor, setcompanyColor] = useState("")
  const [companyBG, setcompanyBG] = useState("")
  const [ItemsBelowRol, setItemsBelowRol] = useState([]);
  const [nowdate, setnowdate] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const[warehouse,setwarehouse]=useState([])
  const [selectedOption, setSelectedOption] = useState('');
  const [collectionData, setCollectionData] = useState({
    "FormDate": startdate,
    "ToDate": enddate,
    "CompanyNumber": COMPANY_CODE,
    "strWh": "",
    "SystemName": "",
    "Euser": "",
    "Category": ""
  });
  const color = [
    "#8884d8", "#e01ee9", "#34C2FF", "#4986FF", "#2055BD", "#1204c1", "#883be5", "#d65fff", "#e12856", "#e13e3e"
  ]
  const color2 = [
    "#883be5", "#d65fff", "#e01ee9", "#e12856", "#e13e3e"
  ]
  const PieChartData = [
    { name: "Group A", value: ChartCategory[0]?.Quantity, color: "primary" },
    { name: "Group B", value: ChartCategory[1]?.Quantity, color: "secondary" },
    { name: "Group C", value: ChartCategory[2]?.Quantity, color: "warning" },
    { name: "Group D", value: ChartCategory[3]?.Quantity, color: "success" },
    { name: "Group E", value: ChartCategory[4]?.Quantity, color: "success" },
    { name: "Group F", value: ChartCategory[5]?.Quantity, color: "success" },
    { name: "Group G", value: ChartCategory[6]?.Quantity, color: "success" },
    { name: "Group H", value: ChartCategory[7]?.Quantity, color: "success" },
    { name: "Group I", value: ChartCategory[8]?.Quantity, color: "success" },
    { name: "Group J", value: ChartCategory[9]?.Quantity, color: "success" },
  ];
  const [TotalCollection, setTotalCollection] = useState([]);


  const [popupclass, setpopupclass] = useState("display-none")
  const currentliveenquryclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 240 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 }

  ]

  const pendingRFQsclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 150 },
    { field: 'topVendorName', headerName: 'VENDOR NAME', width: 150 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 150 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 },


  ]
  const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]
  const debitorbalancedataclm = [
    { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150 },
    { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150 },
    { field: 'Debit', headerName: 'DEBITED', width: 80 },
    { field: 'credit', headerName: 'CREDIT', width: 80 },
    { field: 'balance', headerName: 'BALANCE', width: 100 },
  ]

  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return months[monthNumber - 1] || null;
  }


  useEffect(() => {
    // setTimeout(() => {
    //   document.querySelector('.TOGGLEicon').click();
    // },[])

    setcompanyColor(localStorage.getItem('cmpnyType').split(",")[0])

    // const button1 = document.getElementById('currentyearbtn');
    // const button2 = document.getElementById('lastyearbtn');

    // button1.addEventListener('click', () => {
    //   button1.classList.remove('activegrphbtn');
    //   button2.classList.add('activegrphbtn');
    // });

    // button2.addEventListener('click', () => {
    //   button2.classList.remove('activegrphbtn');
    //   button1.classList.add('activegrphbtn');
    // });


    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
    //---------- GET ITEM BELOW ROLFOR DASHBOERD----------------
    axios.post(GET_ITEMS_BELOW_ROL, { "CompanyNumber": COMPANY_CODE }).then((response) => {
      console.log(response)
      if (response.data.IsSuccess === true) {
        console.log(response.data.Data)

        setItemsBelowRol(response.data.Data);
      }
      else {
        setItemsBelowRol([])
      }
    })

    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    console.log(formattedDate);


    settodaysdate(formattedDate)
    getreports(startdate, enddate)

  }, []);

  // for graph 

  useEffect(() => {
    //---------- GET GRAPH DATA FOR DASHBOERD----------------

    function yearago(startyr, endyr) {
      const today = new Date();
      const currentYear = today.getFullYear();
      startyr = new Date(currentYear, 0, 1); // January 1st of current year
      startyr.setDate(startyr.getDate() + 1); // add one day to the start date
      endyr = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Today's date

      console.log(startyr.toISOString().slice(0, 10)); // formatted start date
      console.log(endyr.toISOString().slice(0, 10)); // formatted end date

      return {
        startyr: startyr.toISOString().slice(0, 10),
        endyr: endyr.toISOString().slice(0, 10)
      };
    }
    const { startyr, endyr } = yearago()

    axios.post(GET_GRAPH_DATA, { "CompanyNumber": COMPANY_CODE, "fromDate": "2022-01-01", "toDate": "2023-12-31" })
      .then((response) => {
        console.log(response.data.Data)
        if (response.data.IsSuccess === true){
          setGraphDate(response.data.Data);
        }
        else {
          setGraphDate([]);
        }
      })
  }, [])


  const getyeargraph = (name) => {
    if (name == "current") {
      function yearago(startyr, endyr) {
        const today = new Date();
        const currentYear = today.getFullYear();
        startyr = new Date(currentYear, 0, 1); // January 1st of current year
        startyr.setDate(startyr.getDate() + 1); // add one day to the start date
        endyr = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Today's date

        console.log(startyr.toISOString().slice(0, 10)); // formatted start date
        console.log(endyr.toISOString().slice(0, 10)); // formatted end date

        return {
          startyr: startyr.toISOString().slice(0, 10),
          endyr: endyr.toISOString().slice(0, 10)
        };

      }
      const { startyr, endyr } = yearago()
      axios.post(GET_GRAPH_DATA, { "CompanyNumber": COMPANY_CODE, "fromDate": startyr, "toDate": endyr })
        .then((response) => {
          if (response.data.IsSuccess === true) {
            setGraphDate(response.data.Data);
          }
          else {
            setGraphDate([]);
          }
        })

    }
    else if (name == "last") {

      function yearago(startyr, endyr) {
        const today = new Date();
        const currentYear = today.getFullYear() - 1;
        startyr = new Date(currentYear, 0, 1); // January 1st of current year
        startyr.setDate(startyr.getDate() + 1); // add one day to the start date


        const lasttoday = new Date();
        const lastyear = lasttoday.getFullYear();
        endyr = new Date(lastyear, 0, 1); // dec 

        console.log(endyr.toISOString().slice(0, 10)); // 

        console.log(startyr.toISOString().slice(0, 10)); // 

        return {
          startyr: startyr.toISOString().slice(0, 10),
          endyr: endyr.toISOString().slice(0, 10)
        };

      }
      const { startyr, endyr } = yearago()
      axios.post(GET_GRAPH_DATA, { "CompanyNumber": COMPANY_CODE, "fromDate": startyr, "toDate": endyr })
        .then((response) => {
          if (response.data.IsSuccess === true) {
            setGraphDate(response.data.Data);
          }
          else {
            setGraphDate([]);
          }
        })
    }
  }




  // Get Report Function => getreports(startdate , enddate)

  const getreports = (start, end) => {

    //---------- Total Collection----------------

    axios.post(GET_TOTAL_BILL_OF_DAY, { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log("totoal bill of the day ")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data.BillCount);
          setbilloftheday(response.data.Data.BillCount)
        }
      })

    //---------- Total Collection----------------

    collectionData.FormDate = start
    collectionData.ToDate = end

    //-----------GET TOTAL COLLECTION------------- 
    axios.post(GET_COLLECTION_REPORT, collectionData)
      .then((response) => {
        console.log("totla collection newdata")
        console.log(response)
        if (response.data.IsSuccess === true) {

          const newData = response.data.Data.filter(row => row["User Name"] == "Total Collection");
          console.log("new data result")
          console.log(newData);
          // for now sale analysis count 
          const a = newData[0].Amount;
          console.log(a)
          setsalsAnalysis(a)


          const filteredData = {};

          Object.keys(newData[0]).forEach(key => {
            if (key !== 'Date' && key !== 'User Name' && key !== 'System Name' && key !== 'Id' && key !== 'Amount') {
              if(newData[0][key] != 0){
                filteredData[key] = newData[0][key];
              }
              
            }
          });


          setTotalCollection(filteredData)
        }
        else {
          setTotalCollection([])
          setsalsAnalysis(0)
        }

      })
    //---------- Sales Analysis----------------
    // axios.post(GET_SALES_ANALYSIS_COUNT, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
    //   .then((response) => {
    //     console.log("sale analysis")
    //     console.log(response)
    //     if (response.data.IsSuccess === true) {
    //       console.log(response.data.Data.BillCount);

    //       const formattedNumber = formatNumberIndianStyle(response.data.Data.BillCount.toString());
    //       console.log(formattedNumber);

    //       setsalsAnalysis(formattedNumber)

    //     }
    //   })
      
    /**all warehouses dropdown */
    axios.post(GET_ALL_WAREHOUSES,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log("ALL WARE HOUSE ")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setwarehouse(response.data.Data)

        }
      })
    //---------- TOP TEN SALE ITEMS----------------

    axios.post(GET_TOP_TEN_SALE_ITEM, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
      .then((response) => {
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log("sdfs")
          console.log(response.data.Data);
          settopTenSaleItem(response.data.Data)
        }
        else {
          console.log("No data found");
          settopTenSaleItem([]);
        }
      })

    //---------- Category pie chart  ----------------

    axios.post(GET_CATEGORY_SALE_REPORT,
      { "CompanyNumber": COMPANY_CODE, "FormDate": start, "ToDate": end })
      .then((response) => {
        console.log("Category pie chart")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          var array = response.data.Data;
          if (array.length > 0) {
            array.sort((a, b) => b.Quantity - a.Quantity);
            setChartCategory(array)

          }



        }
        else {
          setChartCategory([])
        }
      })



    // //---------- GET GRAPH DATA FOR DASHBOERD----------------
    // axios.post(GET_GRAPH_DATA, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
    //   .then((response) => {
    //     if (response.data.IsSuccess === true) {
    //     setGraphDate(response.data.Data);
    //     }
    //     else{
    //     setGraphDate([]);
    //     }
    //   })

  }

  console.log(graphDate);
  var nowexactdate = function getFormettedDate() {
    var datentime = ""
    let today = new Date();

    // Get day, month, and year separately
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    // Create an array of month names
    let monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Get the month name from the array
    let monthName = monthNames[month - 1];
    datentime = `${day} ${monthName} ${year}`
    return datentime

  }

  const CustomBarLabel = ({ x, y, width, value }) => (
    <text x={x + width / 2} y={y - 4} dy={-4} textAnchor="middle" fill="#666">
      {value}
    </text>
  );

  const getreportallwarehouse = (e) => {
    setActiveButton(e.target.name)

    const today = new Date();
    const oneMonthsAgo = new Date();
    oneMonthsAgo.setMonth(today.getMonth() - 1);
    const formattedDate = oneMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  } 

  const getreportseightmonths = (e) => {
    setActiveButton(e.target.name)

    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  }

  const getreportssixmonths = (e) => {

    setActiveButton(e.target.name)
    // ActiveButton
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 6);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))
  }
  //date before respective Months => (a) 
  // setActiveButton("six")

  const getreportsthreemonths = (e) => {
    setActiveButton(e.target.name)
    // setActiveButton("eight")
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 8);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  }


  // graph 


  console.log("graphdate")
  console.log(graphDate)
  const graphdatacurrent = graphDate;
  console.log(graphdatacurrent);

  
  const newArray = graphdatacurrent.map(item => ({
    Mth: item.Mth,
    LastYearPurchase: item.LyPurchase,
    CurrentYearPurchase: item.CyPurchase,
    LastYearSale: item.LySale,
    CurrentYearSale: item.CySale
  }));
  
  console.log(newArray);   // here convert e.g LYsale to LAstyearsale new KEYs

  
  const uniqueBymonths = Object.values(newArray.reduce((acc, item) => {
    const month = item.Mth;
    if (!acc[month]) {
      acc[month] = {
        Mth: month,
        LastYearPurchase: 0,
        CurrentYearPurchase: 0,
        LastYearSale: 0,
        CurrentYearSale: 0
      };
    }
    acc[month].LastYearPurchase += item.LastYearPurchase;
    acc[month].CurrentYearPurchase += item.CurrentYearPurchase;
    acc[month].LastYearSale += item.LastYearSale;
    acc[month].CurrentYearSale += item.CurrentYearSale;
    return acc;
  }, {}));
  console.log("unique by monthsssss")
  console.log(uniqueBymonths);    // here we get all sale and purchase by months ( unique by months  )


let cumulativeLastYearPurchase = 0;
let cumulativeCurrentYearPurchase = 0;
let cumulativeLastYearSale = 0;
let cumulativeCurrentYearSale = 0;

const cumulativeArr = uniqueBymonths.map((item) => {
  cumulativeLastYearPurchase += item.LastYearPurchase;
  cumulativeCurrentYearPurchase += item.CurrentYearPurchase;
  cumulativeLastYearSale += item.LastYearSale;
  cumulativeCurrentYearSale += item.CurrentYearSale;

  return {
    Mth: item.Mth,
    LastYearPurchase: cumulativeLastYearPurchase,
    CurrentYearPurchase: cumulativeCurrentYearPurchase,
    LastYearSale: cumulativeLastYearSale,
    CurrentYearSale: cumulativeCurrentYearSale
  };
});
console.log("added Sales ")
console.log(cumulativeArr);                                                                                                          

// now split the array in two parts 
let splitIndex = 0;

for (let i = 1; i < cumulativeArr.length; i++) {
  if (
    cumulativeArr[i].CurrentYearPurchase === cumulativeArr[i - 1].CurrentYearPurchase &&
    cumulativeArr[i].CurrentYearSale === cumulativeArr[i - 1].CurrentYearSale
  ) {
    splitIndex = i;
    break;
  }
}

let newArray1 = cumulativeArr.slice(0, splitIndex);
let newArray2 = cumulativeArr.slice(splitIndex);

for (let i = 0; i < newArray2.length; i++) {
  newArray2[i].CurrentYearSale = "";
  newArray2[i].CurrentYearPurchase = "";
}

let mergedArray = newArray1.concat(newArray2);
console.log("merged Array")
console.log(mergedArray)


  
  
  

  // here new login 

  const UniqueByMonth = [];
  const months = [...new Set(graphdatacurrent.map(item => item.Mth))];

  months.forEach(month => {
    const monthData = graphdatacurrent.filter(item => item.Mth === month);
    const obj = { Mth: month };

    monthData.forEach(item => {
      obj.LyPurchase = (obj.LyPurchase || 0) + item.LyPurchase;
      obj.CyPurchase = (obj.CyPurchase || 0) + item.CyPurchase;
      obj.LySale = (obj.LySale || 0) + item.LySale;
      obj.CySale = (obj.CySale || 0) + item.CySale;
    });

    UniqueByMonth.push(obj);
  });

  console.log("UniqueByMonth")
  console.log(UniqueByMonth)

  let updatedData = UniqueByMonth.map((currentMonth, index, arr) => {
    let cumulativeLySale = currentMonth.LySale;
    let cumulativeLyPurchase = currentMonth.LyPurchase;
    let cumulativeCySale = currentMonth.CySale;
    let cumulativeCyPurchase = currentMonth.CyPurchase;

    // Calculate cumulative values of previous months
    for (let i = 0; i < index; i++) {
      cumulativeLySale += arr[i].LySale;
      cumulativeLyPurchase += arr[i].LyPurchase;
      cumulativeCySale += arr[i].CySale;
      cumulativeCyPurchase += arr[i].CyPurchase;
    }

    return {
      Mth: currentMonth.Mth,
      LyPurchase: cumulativeLyPurchase,
      CyPurchase: cumulativeCyPurchase,
      LySale: cumulativeLySale,
      CySale: cumulativeCySale,
    };
  });
  console.log("updated data  fra[h")
  console.log(updatedData);


  // ------------------graph end ------------------

  const handleResize = () => {
    setShouldUpdate(false);
    setTimeout(() => {
      setShouldUpdate(true);
    }, 500);
  };


  const handleclosepopup = () => {
    setpopupclass("display-none")
  }

  const handlePendingRfqsCellClick = (params) => {
    setisform(true)
    setpopupclass("display-none")

  }




  return (
    <>
      {/* {
        isLoading ? <div className='Commonloader'>
        </div> : null
      } */}
      <Grid container spacing={5}  >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Searchbar />
        </Grid>
        <div className="notify">
          <img height="20px" src={noti} />
        </div>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">

          <PageTitle title={`WELCOME TO ${loginname}`}
            button={<Button

              size="medium"
              className={classes.rastorant_mainColor}
            >
              <span className={`${classes[companyColor]}`} >{nowexactdate()}</span>
            </Button>} />
            <select className="dashboarddropdown" value={selectedOption} onChange={(e) => { setSelectedOption(e.target.value); getreportallwarehouse(e); }}>
            {warehouse.map((wareh, i) => (
              <option key={i} value={wareh.Type} className={selectedOption === wareh.Type ? `${classes[companyColor + 'BG']} ${classes.active}` : ''}>
                {wareh.Type}
              </option>
            ))}
          </select>


        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pt-0">
          Date Range
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={7} className="py-1">
          {/* <InputLabel id="demo-controlled-open-select-label">From Date</InputLabel> */}
          <Grid container spacing={5}>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <TextField id="standard-basic" type="date" value={startdate}
                onChange={(e) => { setstartdate(e.target.value); setActiveButton(""); }} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <TextField id="standard-basic" type="date" value={enddate}
                onChange={(e) => { setenddate(e.target.value); setActiveButton(""); }} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <button id="getReport" className={`${classes.dashSideBtn}  ${classes.rastorant_mainColor} ${classes[companyColor + 'BG']}`} onClick={(e) => getreports(startdate, enddate)} >
                {isLoading ? <img className="loaderbtnApply" src={loadbtn} /> : "Apply"}
              </button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid  item lg={2} md={2} sm={1} xs={1}className="py-1"></Grid> */}
        <Grid item lg={6} md={6} sm={6} xs={5} className={`py-1 ${classes.dashSideBtnMain}`}>
          <button className={`${activeButton === "three" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="three" onClick={(e) => getreportseightmonths(e)}>
            Last 3 months
          </button>
          <button className={`${activeButton === "six" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="six" onClick={(e) => getreportssixmonths(e)}>
            Last 6 months
          </button>
          <button className={`${activeButton === "eight" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="eight" onClick={(e) => getreportsthreemonths(e)}>
            Last 8 months
          </button>
        </Grid>

      </Grid>
      <div className={`category_btnGrp ${companyColor.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}>
      <div className='categorydashboardbtn' >  
                 <Button variant='contained' className={`${classes[companyColor+'BG']}  my-1`}  style={{width:"33.33%", margin:"3px", color:"#fff"}} >Master </Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`}style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Item</Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`} style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Category</Button>
               
                </div> 
      </div>
      <div>
     <center><select className="dashboarddropdown">
            <option value="all">ALL</option>
            <option value="category1">VIRGIN</option>
            <option value="category2">TESTING1</option>
            <option value="category3">TESTING2</option>

      </select>
      </center>
      <div>
        <h3 style={{textAlign:"right"}}>Total QOH:- 7868</h3>
      </div>
      </div>
      <center><h2>VIRGIN</h2></center>
     


      <Grid container spacing={6} className={companyColor}>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
             <center><h6 className={`card-title my-0 fw-bold`}>Master</h6></center> 
              <Grid container item alignItems={"center"}>
              <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              


                <Grid item xs={4} className="text-end">

                  {/* <span > <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span> */}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body ">
              <center><h6 className={`card-title my-0 fw-bold`}>QOH</h6></center>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
             <center> <h6 className={`card-title my-0 fw-bold`}>Last 5 Purchase Price</h6></center>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>


                {/* <Grid item xs={4}className="text-end">
              <span >
            <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
                <center><h6 className={`card-title my-0 fw-bold`}>Sale Analysis</h6></center>
                <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid> 
                </Grid>
             
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <center> <h6 className={`card-title my-0 fw-bold`}>Top 5 Purchase Party</h6></center>
              <Grid container item alignItems={"center"}>
              <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <div className={`categorybtn'  ${companyColor.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}style={{width:"100%"}}>
      <div className='categorydashboardbtn' style={{padding:"20px"}}>  
                 <Button variant='contained' className={`${classes[companyColor+'BG']}  my-1`}  style={{width:"33.33%", margin:"3px", color:"#fff"}} >Item Master </Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`}style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Price Stock Ledger</Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`} style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Stock Statement</Button>
               
                </div> 
      </div>
      
      </Grid>


      <Grid container spacing={5} className="Graphdata" >


        <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
          <h6 className={`card-title my-0 fw-bold`}>Sale Graph</h6>
          <div onResize={handleResize}>
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart data={data}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <br/>
        <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
          <h6 className={`card-title my-0 fw-bold`}>Production Summary</h6>
          <div onResize={handleResize}>
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart data={data}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Grid>


      
        <div className="d">
        </div>
      </Grid>
      <Grid container spacing={5} className="Graphdata" >
     
        <div className="d">
        </div>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  const array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  const resultArray = [];
  const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  const mobile = getRandomData(31, 1500, 7500, 7500, 1500);


  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}