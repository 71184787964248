import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Area,
  PieChart,
  Pie, Tooltip,
  Cell, LineChart,
  YAxis,
  XAxis, CartesianGrid
} from "recharts";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import noti from '../../../images/hrx.png'
import axios from "axios";
// styles
import useStyles from "./styles";
// Utiles
import PageTitle from "../../../Utiles/PageTitle/PageTitle";
import Searchbar from "../../../Utiles/Searchbar/SearchBar";
import TotalBilImg from '../../../images/pos/totalBillImg.png';
import SalesAnalysisImg from '../../../images/pos/SalesAnalysis.png';
import SaleItemImg from '../../../images/pos/SaleItem.png';
import Alertmessage from "../../../Utiles/Alert/Alertmessage";
import themes from "../../../themes";
import { COMPANY_CODE, GET_ALL_FG_STOCK, GET_ALL_ITEM_PRODUCED,
   GET_ALL_WAREHOUSES, GET_ITEMS_BELOW_ROL, GET_SALE_ORDER_GRID, GET_TOTAL_BILL_OF_DAY,
    GET_TOTAL_FG_STOCK, GET_TOTAL_FG_STOCK_CATEGORY, GET_TOTAL_ITEM_PRODUCED_CATEGORY } from "../../../constant";
import { GET_TOTAL_ITEM_PRODUCED } from "../../../constant";
import MasterDashboard from "./Master/MasterDashboard";
import ItemDashboard from "./Item/ItemDashboard";
import CategoryDashboard from "./Category/CategoryDashboard";
import { NextWeekRounded } from "@material-ui/icons";
const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];
const color = [
  "#3DDEFA", "#34C2FF", "#4986FF", "#2055BD"
]



const data = [
  {
    "name": "Page A",
    "uv": 4000,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "Page B",
    "uv": 3000,
    "pv": 1398,
    "amt": 2210
  },
  {
    "name": "Page C",
    "uv": 2000,
    "pv": 9800,
    "amt": 2290
  },
  {
    "name": "Page D",
    "uv": 2780,
    "pv": 3908,
    "amt": 2000
  },
  {
    "name": "Page E",
    "uv": 1890,
    "pv": 4800,
    "amt": 2181
  },
  {
    "name": "Page F",
    "uv": 2390,
    "pv": 3800,
    "amt": 2500
  },
  {
    "name": "Page G",
    "uv": 3490,
    "pv": 4300,
    "amt": 2100
  }
]

export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  var today = new Date().toISOString().slice(0, 10); 
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(today)
  const [enddate, setenddate] = useState(nextday)
  const [subDashBoardName, setSubDashBoardName] = useState("Master")

  



  const [todaysdate, settodaysdate] = useState("")
  const [sdate, setSdate] = useState(startdate)  
  const [edate, setEdate] = useState(enddate);
  // const [selectedOption, setSelectedOption] = useState('');

  const [wareHousee, setWareHousee] = useState("");
  // local
 
  const [mainChartState, setMainChartState] = useState("monthly");
  const [ItemsBelowRol, setItemsBelowRol] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [loginname, setloginname] = useState(localStorage.getItem('username'))
  const [SaleOrderGRid, setSaleOrderGRid] = useState([])
  const [currentLiveEnquiris, setCurrentLiveEnquiris] = useState(0);
  const [totalbillCountofday, settotalbillCountofday] = useState(1)
  const [CurrentLiveEnquirispopupdata, setCurrentLiveEnquirispopupdata] = useState([])
  const [pendingRFQspopupdata, setpendingRFQspopupdata] = useState([])
  const [Pendingpopopupdata, setPendingpopopupdata] = useState([])
  const [debitorbalancedata, setdebitorbalancedata] = useState([])
  const [creditorbalancedata, setcreditorbalancedata] = useState([])
  const [pendingRFQ, setPendingRFQ] = useState(0);
  const [topClientOrderCount, setTopClientOrderCount] = useState(0);
  const [TopClientOrdername, setTopClientOrdername] = useState("")
  const [topVendorCount, setTopVendorCount] = useState(0);
  const [TopVendorname, setTopVendorname] = useState("")
  const [topCilentCount, setTopCilentCount] = useState(0);
  const [TopCilentname, setTopCilentname] = useState("")
  const [graphDate, setGraphDate] = useState([]);
  const [popupHeader, setpopupHeader] = useState("")
  const [pendingreqpopup, setpendingreqpopup] = useState(false)
  const [currentlivepopup, setcurrentlivepopup] = useState(false)
  const [pendingPOpopup, setpendingPOpopup] = useState(false)
  const [dibitorbalancepopup, setdibitorbalancepopup] = useState(false)
  const [crediorbalancepopup, setcrediorbalancepopup] = useState(false)
  const [SelectedRows, setSelectedRows] = useState([])
  const [isform, setisform] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [companyColor, setcompanyColor] = useState("")
  const [popupclass, setpopupclass] = useState("display-none")
  const[warehouse,setwarehouse]=useState([])
  const [selectedOption, setSelectedOption] = useState('');
  const [activeButton, setActiveButton] = useState("");


  //  FG STOCK
  const [FGstockcount, setFGstockcount] = useState(0)
  const [FGstockClick, setFGstockClick] = useState(false)
  const [FGstockClickData, setFGstockClickData] = useState([])
  const [FGstockClickcellData, setFGstockClickcellData] = useState([])
  const [FGtblcellClick, setFGtblcellClick] = useState(false)

  const [thisBCD, setthisBCD] = useState("")

  // ITEM PRODUCED

  const [ItemProducedcount, setItemProducedcount] = useState(0)
  const [ItemProducedClick, setItemProducedClick] = useState(false)
  const [ItemProducedClickData, setItemProducedClickData] = useState([])
  const [ItemProducedClickcellData, setItemProducedClickcellData] = useState([])
  const [IPtblcellClick, setIPtblcellClick] = useState(false)

  const currentliveenquryclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 240 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 }

  ]
  //total fg stock 
  const FGstockClickDataCLM = [
    { field: 'Item', headerName: 'ITEM', width: 100 },
    { field: 'Qty', headerName: 'QTY', width: 50 },
    { field: 'BCD', headerName: 'BCD', width: 100 },
  ]
  const FGstockCellClickDataCLM = [
    { field: 'Item', headerName: 'ITEM', width: 200 },
    { field: 'Qty', headerName: 'QTY', width: 50 },
  ]
const SaleOrderGRidCLM =[
  { field: 'Order No', headerName: 'Order No', width: 200 },
  { field: 'DATED', headerName: 'Date', width: 200 },
  { field: 'ITEM', headerName: 'Item', width: 300 },
  { field: 'QTY', headerName: 'Qty', width: 100 },
 // {field: 'Bal Qty', headdrName:'Bal Qty', width:100},
]

  // total items produced 

  const ItemProducedClickDataCLM = [
    { field: 'Item', headerName: 'ITEM', width: 200 },
    { field: 'Qty', headerName: 'QTY', width: 100 },
    { field: 'BCD', headerName: 'BCD', width: 150 },
  ]
  const ItemProducedCellClickDataCLM = [
    { field: 'Item', headerName: 'ITEM', width: 200 },
    { field: 'Qty', headerName: 'QTY', width: 200 },
  ]

  const debitorbalancedataclm = [
    { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150 },
    { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150 },
    { field: 'Debit', headerName: 'DEBITED', width: 80 },
    { field: 'credit', headerName: 'CREDIT', width: 80 },
    { field: 'balance', headerName: 'BALANCE', width: 100 },
  ]

  
// const handleOptionChange = (event) => {
//   setSelectedOption(event.target.value);
// };

  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType')?.split(',')[0])
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
    // ---------- Items below ROL----------------
    axios.post(GET_ITEMS_BELOW_ROL, { "CompanyNumber": COMPANY_CODE }).then((response) => {
      if (response.data.IsSuccess === true) {
        setItemsBelowRol(response.data.Data);
      }
    })
    //---------- GET_TOTAL_FG_STOCK----------------
    axios.post(GET_TOTAL_FG_STOCK, { "CompanyNumber": COMPANY_CODE }).then((response) => {
      if (response.data.IsSuccess === true) {
        setFGstockcount(response.data.Data.BillCount);
      }
    })


    //---------- GET_TOTAL_FG_STOCK_CATEGORY---------------
    axios.post(GET_TOTAL_FG_STOCK_CATEGORY, { "CompanyNumber": COMPANY_CODE, "": "110NOIDA" }).then((response) => {
      if (response.data.IsSuccess === true) {
        setFGstockClickData(response.data.Data);//

      }
    })
    //----------GET_TOTAL_ITEM_PRODUCED----------------
    axios.post(GET_TOTAL_ITEM_PRODUCED, { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        if (response.data.IsSuccess === true) {
          setItemProducedcount(response.data.Data.Qty)
        }
      })

    //---------- GET_ITEM_PRODUCED_CATEGORY---------------
    axios.post(GET_TOTAL_ITEM_PRODUCED_CATEGORY, { "CompanyNumber": COMPANY_CODE }).then((response) => {
      if (response.data.IsSuccess === true) {
        setItemProducedClickData(response.data.Data)
      }
    })
    //---------- GET Sale Order Grid----------------
    axios.post(GET_SALE_ORDER_GRID, { "CompanyNumber": COMPANY_CODE }).then((response) => {
      if (response.data.IsSuccess === true) {

        setSaleOrderGRid(response.data.Data)
      }
    })
    //---------- GET GRAPH DATA FOR DASHBOERD----------------
    //   axios.post(GET_GRAPH_DATA,{
    //     "StartDate":"01-03-2023",
    //     "EndDate":"10-03-2023"
    // }).then((response)=>{
    //     console.log(response)
    //     if(response.data.IsSuccess === true){
    //       setGraphDate(response.data.Data);
    //     }
    //   })
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');


    settodaysdate(formattedDate)

    /**all warehouses dropdown */
    axios.post(GET_ALL_WAREHOUSES,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        if (response.data.IsSuccess === true) {
          setwarehouse(response.data.Data)
        }
      })
  }, []);
  const getreports = () => {
    //  ---------- TotalBillTotheday----------------
    axios.post(GET_TOTAL_BILL_OF_DAY, { "CompanyNumber": COMPANY_CODE, "fromDate": startdate, "toDate": enddate })
      .then((response) => {
        if (response.data.IsSuccess === true) {
          settotalbillCountofday(response.data.Data.BillCount)

        }
      })
  }


  const getreportallwarehouse = (e) => {
    setActiveButton(e.target.name)

    const today = new Date();
    const oneMonthsAgo = new Date();
    oneMonthsAgo.setMonth(today.getMonth() - 1);
    const formattedDate = oneMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  } 

  const getreportseightmonths = (e) => {
    setActiveButton(e.target.name)
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 8);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    var datebeforenineMOnth = formattedDate;

    //   axios.post(GET_GRAPH_DATA,{
    //     "StartDate":todaysdate,
    //     "EndDate":datebeforenineMOnth
    // }).then((response)=>{
    //     console.log(response)
    //     if(response.data.IsSuccess === true){
    //       setGraphDate(response.data.Data);
    //     }
    //     else{
    //       alert("No record Found !!")
    //     }
    //   })

  }

  const getreportssixmonths = (e) => {
    setActiveButton(e.target.name)
    const today = new Date();

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 6);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');


    var datebeforesixMOnth = formattedDate;

    // axios.post(GET_GRAPH_DATA,{
    //   "StartDate":todaysdate,
    //   "EndDate":datebeforesixMOnth
    // }).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     setGraphDate(response.data.Data);
    //   }
    //   else{
    //     alert("No record Found !!")
    //   }
    // })
  }

  const getreportsthreemonths = (e) => {
    setActiveButton(e.target.name)
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    var datebeforethreeMOnth = formattedDate;

    // axios.post(GET_GRAPH_DATA,{
    //   "StartDate":todaysdate,
    //   "EndDate":datebeforethreeMOnth
    // }).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     setGraphDate(response.data.Data);
    //   }
    //   else{
    //     alert("No record Found !!")
    //   }
    // })
  }

  const HandleFGStock = () => {
    setFGstockClick(true)

  }
  const handleCloseFGstockClick = () => {
    setFGstockClick(false)
    setFGtblcellClick(false)
  }

  const HandleItemProduced = () => {
    setItemProducedClick(true)

  }
  const handleCloseItemProducedClick = () => {
    setItemProducedClick(false)
    setIPtblcellClick(false)
  }

  const openRFQsForm = (a, b, c, d) => {
  
  }
  const handleDashFGtblcellClick = (params) => {
    setthisBCD(params.row.BCD)
    axios.post(GET_ALL_FG_STOCK, { "CompanyNumber": COMPANY_CODE, "Warehouse": "110NOIDA", "BCD": params.row.BCD })
      .then((response) => {
        if (response.data.IsSuccess === true) {
          setFGstockClickcellData(response.data.Data)
          setFGtblcellClick(true)
        }
      })
    // setthisBCD(params.row.BCD)
  }

  // item produced 

  const handleDashIPtblcellClick = (params) => {
    setthisBCD(params.row.BCD)
    axios.post(GET_ALL_ITEM_PRODUCED, { "CompanyNumber": COMPANY_CODE, "BCD": params.row.BCD })
      .then((response) => {
        if (response.data.IsSuccess === true) {
          setItemProducedClickcellData(response.data.Data)
          setIPtblcellClick(true)
        }
      })

  }

  var nowexactdate = function getFormettedDate() {
    var datentime = ""
    let today = new Date();

    // Get day, month, and year separately
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    // Create an array of month names
    let monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Get the month name from the array
    let monthName = monthNames[month - 1];
    datentime = `${day} ${monthName} ${year}`
    return datentime

  }

  const handleResize = () => {
    setShouldUpdate(false);
    setTimeout(() => {
      setShouldUpdate(true);
    }, 500);
  };

const SubDashboard=[
  {
    "name":"Master"
  },
  {
    "name":"Item"
  },
  {
    "name":"Category"
  },

]

  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
      <Grid container spacing={5} >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Searchbar />
        </Grid>
        <div className="notify">
          <img height="20px" src={noti} />
        </div>
       
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
          <PageTitle title={`Welcome, ${loginname}`}
            button={<Button
              variant="contained"
              size="medium"
              className={classes.pageheading }
              style={{backgroundColor:'#e0e0e000'}}
            >
              <span className={`  ${classes[companyColor]}`}  >{nowexactdate()}</span>
            </Button>} />

            
            <select className="dashboarddropdown" value={selectedOption} onChange={(e) => { setSelectedOption(e.target.value); getreportallwarehouse(e); }}>
            {warehouse.map((wareh, i) => (
              <option key={i} value={wareh.Type} className={selectedOption === wareh.Type ? `${classes[companyColor + 'BG']} ${classes.active}` : ''}>
                {wareh.Type}
              </option>
            ))}
          </select>
        
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pt-0" >
          Date Range
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="py-1">
          {/* <InputLabel id="demo-controlled-open-select-label">From Date</InputLabel> */}
          <Grid container spacing={5}>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0'>
              <TextField id="standard-basic" type="date" value={startdate}
                onChange={(e) => setstartdate(e.target.value)} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0'>
              <TextField id="standard-basic" type="date" value={enddate}
                onChange={(e) => setenddate(e.target.value)} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0 '>
              <button className={`applyclick ${classes.dashSideBtn}  ${classes.Production_primary} ${classes[companyColor + 'BG']}`} onClick={getreports} >
                Apply
              </button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid  item lg={2} md={2} sm={1} xs={1}className="py-1"></Grid> */}
        <Grid item lg={6} md={6} sm={6} xs={12} className={`py-1 ${classes.dashSideBtnMain}`}>
        <button className={`${activeButton === "three" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="three" onClick={(e) => getreportseightmonths(e)}>
            Last 3 months
          </button>
          <button className={`${activeButton === "six" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="six" onClick={(e) => getreportssixmonths(e)}>
            Last 6 months
          </button>
          <button className={`${activeButton === "eight" ? `${classes[companyColor + 'BG']} ` : ''} ${classes.dashSideBtn}`} name="eight" onClick={(e) => getreportsthreemonths(e)}>
            Last 8 months
          </button>
        </Grid>

      </Grid>
      <div className={`categorybtn ${companyColor.toUpperCase() === "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}>
      <div className='categorydashboardbtn'>
        {SubDashboard.map((data, i) => {
          const isActive = subDashBoardName === data.name;
          return (
            <Button
              key={i}
              variant='contained'
              className={`${classes[companyColor + 'BG']} my-1`}
              style={{
                width: "33.33%",
                margin: "3px",
                color: "#fff",
                backgroundColor: isActive ? "gray" : companyColor, 
              }}
              onClick={e => setSubDashBoardName(data.name)}
            >
              {data.name}
            </Button>
          );
        })}
      </div>
      
      {subDashBoardName === "Master" ? <MasterDashboard startdate={sdate} enddate={edate} wareHousee={wareHousee} /> : ""}
      {subDashBoardName === "Item" ? <ItemDashboard  startdate={sdate} enddate={edate} wareHousee={wareHousee}/> : ""}
      {subDashBoardName === "Category" ? <CategoryDashboard startdate={sdate} enddate={edate} wareHousee={wareHousee}/> : ""}
    </div>
      <Grid container spacing={6} className={companyColor}>
        {/* < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
              <h6 className={`card-title my-0 fw-bold`}>Total FG Stock</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  {
                    FGstockClick ? "" : <h1 onClick={HandleFGStock}>{FGstockcount}</h1>
                  }
                  <Grid container spacing={5} className="pt-0 withoutscroller" >

                    {
                      FGstockClick ?
                        <>
                          {FGtblcellClick ?
                            <p onClick={(e) => setFGtblcellClick(false)} style={{ marginLeft: '200px' }}>back</p>
                            :
                            <p onClick={handleCloseFGstockClick} style={{ marginLeft: '200px' }}>×</p>
                          }
                          <div className='table animatingBottom  production_table_hideFooter' style={{ height: "200px", width: "100%" }}>
                            <DataGrid
                              rows={FGtblcellClick ? FGstockClickcellData : FGstockClickData}
                              columns={FGtblcellClick ? FGstockCellClickDataCLM : FGstockClickDataCLM}
                              pageSize={4}
                              onCellClick={FGtblcellClick ? "" : handleDashFGtblcellClick}
                              getRowId={(row) => row.Item}
                              className={`${classes[companyColor + 'BG']}`}
                              {...(FGtblcellClick ? FGstockClickcellData : FGstockClickData)}
                            />
                          </div>
                        </>
                        : ""
                    }

                  </Grid>
                </Grid>


                <Grid item xs={4} className="text-end">

                </Grid>
              </Grid>
            </div>
          </div>
        </Grid> */}
        {/* < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body ">
              <h6 className={`card-title my-0 fw-bold`}>Total WIP Stock</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid> */}
        {/* < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Pending Production Orders</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>


               
              </Grid>
            </div>
          </div>
        </Grid> */}
        {/* <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
              <h6 className={`card-title my-0 fw-bold`}>Total Production For The Period</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  {
                    ItemProducedClick ? "" : <h1 onClick={HandleItemProduced}>{ItemProducedcount}</h1>
                  }
                  <Grid container spacing={5} className="pt-0 withoutscroller" >

                    {
                      ItemProducedClick ?
                        <>
                          {IPtblcellClick ?
                            <p onClick={(e) => setIPtblcellClick(false)} style={{ marginLeft: '200px' }}>back</p>
                            :
                            <p onClick={handleCloseItemProducedClick} style={{ marginLeft: '200px' }}>×</p>
                          }
                          <div className='table animatingBottom  production_table_hideFooter' style={{ height: "250px", width: "100%" }}>
                            <DataGrid
                              rows={IPtblcellClick ? ItemProducedClickcellData : ItemProducedClickData}
                              columns={IPtblcellClick ? ItemProducedCellClickDataCLM : ItemProducedClickDataCLM}
                              pageSize={4}
                              onCellClick={IPtblcellClick ? "" : handleDashIPtblcellClick}
                              getRowId={(row) => row.Item}
                              className={`${classes[companyColor + 'BG']}`}
                              {...(IPtblcellClick ? ItemProducedClickcellData : ItemProducedClickData)}
                            />
                          </div>
                        </>
                        : ""
                    }
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Items below ROL</h6>
              {ItemsBelowRol.length === 0 ? <p className={`${classes[companyColor]}`} >NO ITEM FOUND</p> : ""}

              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} >
                    <Grid item xs={4} className="pt-0">
                      {
                        ItemsBelowRol.slice(0, 3)?.map((item, i) => (
                          <>
                            <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                              <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                            </ol>
                          </>
                        ))
                      }

                    </Grid>
                    <Grid item xs={4} className="pt-0">
                      {
                        ItemsBelowRol.slice(3, 6)?.map((item, i) => (
                          <>
                            <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                              <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                            </ol>
                          </>
                        ))
                      }
                    </Grid>
                    <Grid item xs={4} className="pt-0">
                      {
                        ItemsBelowRol.slice(6, 9)?.map((item, i) => (
                          <>
                            <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                              <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                            </ol>
                          </>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
              <h6 className={`card-title my-0 fw-bold`}>Pending Sale Orders</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={0} >
                    <Grid item xs={12} className="pt-0" >
                      <div className='table animatingBottom  ' style={{ height: "250px", width: "100%" }}>
                        <DataGrid
                          rows={SaleOrderGRid}
                          columns={SaleOrderGRidCLM}
                          pageSize={4}
                          getRowId={(row) => row.DATED}
                          className={`${classes[companyColor + 'BG']}`}
                          {...SaleOrderGRid}
                        />
                      </div>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid> */}
      </Grid>

     
  
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  const array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  const resultArray = [];
  const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  const mobile = getRandomData(31, 1500, 7500, 7500, 1500);


  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}