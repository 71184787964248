import React from "react";
import useStyles from "./styles";

const ReportTbl = React.memo((props) => {
  
  const classes = useStyles();
  const thisCompany = props.headerColor?.split(",")[0];

  const getTableHeaderClassName = () => {
    switch (thisCompany) {
      case "restaurant":
        return `${classes.restaurantBG} ${classes.additionalStyles}`;
      case "pos":
        return `${classes.posBG} ${classes.additionalStyles}`;
      case "production":
        return `${classes.productionBG} ${classes.additionalStyles}`;
      case "order_management":
        return `${classes.order_managementBG} ${classes.additionalStyles}`;
      default:
        return "";
    }
  };

  return (
    <div style={{ height: "48vh", width: "100%" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead className={getTableHeaderClassName()} style={{ position: "sticky", top: 0 }}>
          <tr>
            {props.TblColumns.map((column) => (
              <th
                key={column.field}
                className={`${classes.tableHeader} ${classes.additionalStyles}`}
              >
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.dataArray.length === 0 ? (
            <tr>
              <td colSpan={props.TblColumns.length} style={{ textAlign: "center", padding: "16px" , color:"#000"}}>
                No data found
              </td>
            </tr>
          ) : (
            props.dataArray.map((row, index) => (
              <tr
                key={row.Id}
                style={{
                  background: index % 2 === 0 ? "#fff" : "#f5f5f5",
                  color: "#000",
                  border: "1px solid #ccc",
                }}
              >
                {props.TblColumns.map((column) => (
                  <td key={`${row.Id}-${column.field}`} style={{ padding: "8px" }}>
                    {row[column.field]}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
});

export default ReportTbl;
