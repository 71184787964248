import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./styles";
import { CSVLink } from "react-csv";
import axios from "axios";
import {
  GET_COLLECTION_REPORT, COMPANY_CODE, GET_ALL_COUTER, GET_ALL_USER, GET_ALL_CATEGORIES, GET_SALE_SUMMARY_REPORT,
  GET_DETAILED_SALE_REPORT, GET_SALE_RETURN_REPORT, GET_SALE_REPORT, GET_COUNTER_DETAIL_REPORT,
  GET_BILL_COMMISSION_REPORT, GET_CLIENT_WISE_COMMISSION_REPORT, GET_SUMMARY_COMMISSION_REPORT,
  GET_ITEM_SUMMARY_REPORT, SELECTED_COMPANY_TYPE, GET_CATEGORY_SALE_REPORT, GET_TABLE_DETAILS_REPORT, GET_VIEW_KOT_DETAILS_REPORT, GET_VIEW_FINANCIAL_DETAILS_REPORT, GET_VIEW_CLIENT_TRACKING_DETAILS_REPORT, GET_VIEW_EXPENCE_DETAILS_REPORT, GET_VAT_REPORT, GET_ALL_WAREHOUSES
} from "../../../constant";
import ReportTbl from "./ReportTbl"
import Alertmessage from "../../../Utiles/Alert/Alertmessage";
export default function CollectionReportMain(props) {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const selectRef = useRef(null);
  const [collectionReportType, setCollectionReportType] = useState("");
  const [collectionreporttbl, setcollectionreporttbl] = useState([]);
  const [allCounts, SetAllCounts] = useState([]);
  const [strWh, setStrWh] = useState([]);
  const [allUser, SetAllUser] = useState([]);
  const [allCategories, SetAllCategories] = useState([]);
  const [salecollectiontbl, setsalecollectiontbl] = useState(false)
  const [isLoading, setisLoading] = useState(false);
  const [collectionsummaryclm, SetCollectionsummaryclm] = useState([]);
  const [Saleclm, setSaleclm] = useState([])
  const [Salereturnclm,setSalereturnclm]=useState([])
  const [BillWiseCommsissionClm, setBillWiseCommsissionClm] = useState([])
  const [CounterDetailsClm, setCounterDetailsClm] = useState([])
  const [salesummaryclm, Setsalesummaryclm] = useState([]);
  const[billwisecommission,setbillwisecommission]=useState([])
  const [detailSaleclm, SetdetailSaleclm] = useState([]);
  const [vatreportclm, Setvatreportclm]= useState([]);
  const [categorySaleyclm, SetCategorySaleyclm] = useState([]);
  const [tableDetailclm, SetTableDetailclm] = useState([]);
  const [KOTDetailclm, SetKOTDetailclm] = useState([]);
  const [ItemSummaryClm, setItemSummaryClm] = useState([])
  const [FinancialDetailclm, SetFinancialDetailclm] = useState([]);
  const [ClientTrackingclm, setClientTrackingclm] = useState([])
  const [Expenceclm, setExpenceclm] = useState([])
  const [companyColor, setcompanyColor] = useState("");
  const [totalQTY, setTotalQTY] = useState(0);
  const [TotalBasicAmount, setTotalBasicAmount] = useState(0);
  const[warehouse,setwarehouse]=useState([])
  const [isalert, setisalert] = useState(false)
  const [alertbg, setalertbg] = useState("")
  const [alertmsg, setalertmsg] = useState("")
  const [blankmsg, setblankmsg] = useState(collectionReportType)
  // const[checkwarehouse,setcheckwarehouse]=useState(true)
  const bgerror = "#d9534f";
  const bgsuccess = "#5cb85c";
  // const[TotalDiscountAmount, setTotalDiscountAmount]= usestate(0);
  const [activeButton, setActiveButton] = useState("");
  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [fromdate, setfromdate] = useState(today)
  const [todate, settodate] = useState(nextday)

  const [collectionData, setCollectionData] = useState({
    "FormDate": "",
    "ToDate": "",
    "CompanyNumber": COMPANY_CODE,
    "strWh": "",
    "SystemName": "",
    "Euser": "",
    "Category": ""
  });

  // let collectionsummaryclm =[];
  // setTimeout(() => {
  //   setisalert(false);
  // }, 5000);
  useEffect(() => {

    axios.post(GET_ALL_WAREHOUSES,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log("ALL WAREHOUSE ")
         
        if (response.data.IsSuccess === true) {
          setwarehouse(response.data.Data)
  
        }
      })
    setcompanyColor(localStorage.getItem('cmpnyType'))
    // document.querySelector('.TOGGLEicon').click();
    var a = window.location.href;

  
    
    //API FOR GET ALL CATEGORIES
    axios.post(GET_ALL_CATEGORIES, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
       
      if (response.data.IsSuccess === true) {
        SetAllCategories(response.data.Data);
      }
      else {
        // var emptList=[];
        SetAllCategories([]);
      }
    })
    allcounter()
    alluser()
    // checkAllCheckboxes()
  }, []);


  function allcounter(){
    axios.post(GET_ALL_COUTER, {
      "CompanyNumber": COMPANY_CODE,
      "FormDate":fromdate ,
    "ToDate": todate
    }
    ).then((response) => {
      if (response.data.IsSuccess === true) {
        SetAllCounts(response.data.Data);
     

      }
      else {
        // var emptList=[];
        SetAllCounts([]);
      }
    })

  }
  function alluser(){
//API FOR GET ALL EUSER
axios.post(GET_ALL_USER, {
  "CompanyNumber": COMPANY_CODE,
  "FormDate":fromdate ,
"ToDate": todate
}
).then((response) => {
  if (response.data.IsSuccess === true) {
    SetAllUser(response.data.Data);
  }
  else {
    // var emptList=[];
    SetAllUser([]);
  }
})
  }

  const handelCollectionChange = (e) => {
    setCollectionReportType(e.target.name);

    setcollectionreporttbl([]);
  }

  const handlereportbtnClick = (e) => {
    debugger;
    setActiveButton(e.target.name)
    setCollectionReportType(e.target.name);
    setblankmsg(e.target.innerHTML)
    setcollectionreporttbl([]);
    // getReport(e.target.name);

  }
  const handlesaleSummaryRbtn = () => {
    setsalecollectiontbl(true)
    // setmaintbl(false)

  }

  // get table sum 
  const getSum = (rows, field) => {
    return rows.reduce((sum, row) => sum + row[field], 0);
  }


  const handleCollectionChange = (e) => {
    // setActiveButton(e.target.name);
    let strWh = "";
    let strWhArr = [];
    if (e.target.name === "SaleOrder" || e.target.name === "MainOrder" || e.target.name === "VRoute" || e.target.name === "COUNTER") {
      if (e.target.checked) {
        //setStrWh(strWh => [...strWh,e.target.value] );
        // setcheckwarehouse(false);
        strWhArr.push(e.target.value);
      }
      else {
        if (strWhArr.indexOf(e.target.value) > -1) {
          strWhArr.splice(strWh.indexOf(e.target.value), 1);
        }
      }
      strWh = strWhArr.join(",");
      setCollectionData({ ...collectionData, "strWh": strWh });
      return;
    }
    console.log (e.target.name);
    setCollectionData({ ...collectionData, [e.target.name]: e.target.value });

  }


   /*** warehouses dropdown ***/

  const getReport = (rptName) => {
    collectionData.FormDate = fromdate
    collectionData.ToDate = todate;
    var from = new Date(collectionData.FormDate);
    var To = new Date(collectionData.ToDate);
    setisLoading(true)
    if (fromdate === "" || todate === "") {
      setisalert(true)
      setalertmsg("from date and to date should not blank.");
      setalertbg(bgerror)
      setisLoading(false)

    }

    else if (from > To) {
      setisalert(true)
      setalertmsg("from date can't be greater than To Date .");
      setalertbg(bgerror)
      setisLoading(false)

    }
    else if (rptName === "") {
      setisalert(true)
      setalertmsg("Please Select The Report Type.");
      setalertbg(bgerror)
      setisLoading(false)

    }
    else {
      Setsalesummaryclm([]);
      if (rptName === "collection") {

        SetCollectionsummaryclm([])
        axios.post(GET_COLLECTION_REPORT, collectionData
        ).then((response) => {
           
          setisLoading(false);
          if (response.data.IsSuccess === true) {
           
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                SetCollectionsummaryclm(collectionsummaryclm => [...collectionsummaryclm, obj]);
              }

            });
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "SaleSummary") {
        axios.post(GET_SALE_SUMMARY_REPORT, collectionData).then((response) => {
          if (response.data.IsSuccess === true) {
            console.log("sale summary res", response);
            Setsalesummaryclm([]);
            setcollectionreporttbl(response.data.Data);
            let columns = [];
            const keys = Object.keys(response.data.Data[0]);
            console.log("headers of sale summary ", keys);
      
            keys.forEach((data) => {
              if (
                data !== "Amount Payable" &&
                data !== "DOC No" &&
                data !== "Total Payment" &&
                data !== "Credit Days" &&
                data !=="Period" &&
                data !=="Id"
              ) {
                let obj = {};
                if (data === "Date") {
                  obj = {
                    field: data,
                    headerName: "Date",
                    width: 250,
                    renderCell: (params) => (
                      <p className="mt-2 f-14">{params?.row?.Date?.slice(0, 10)}</p>
                    ),
                  };
                } else {
                  obj = { field: data, headerName: data, width: 250 };
                }
                columns.push(obj);
              }
            });
      
            // Define the desired order of columns here
            const columnOrder = [
              "Date",
              "Bill Number",
              "Location",
              "Quantity",
              "Basic Amount",
              "Discount Amount",
              "Taxable Amount",
              "Tax Amount",
              "Bill Amount",
              "Extra Charges",
              "Deposit",
              "CASH",
              "Credit",
              "Customer Name",
              "TPIN",
              "Phone",
              "Sold By",
              "CHEQUE",
              "E-WALLET",
              "VISA",
            ];
      
            // Rearrange the columns based on the defined order
            columns.sort((a, b) => {
              return columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field);
            });
      
            Setsalesummaryclm((salesummaryclm) => [...salesummaryclm, ...columns]);
            setisLoading(false);
          } else {
            setblankmsg("NO BILL SUMMARY FOUND !");
            setcollectionreporttbl([]);
            setisLoading(false);
            setTimeout(() => {
              setblankmsg("");
            }, 5000);
          }
        });
      }
      
           
      else if (rptName === "Vatreport") {
        axios.post(GET_VAT_REPORT, collectionData
        ).then((response) => {
          console.log("VAT Report summary")
          console.log(response)
           
          if (response.data.IsSuccess === true) {
            Setvatreportclm([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "Date",
                    headerName: "Date",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {
                      const dateValue = params?.row?.Date;
                      const formattedDate = dateValue ? new Date(dateValue).toISOString().split('T')[0] : '';
                      return (
                        <p className='mt-2 f-14'>{formattedDate}</p>
                      );
                    }
                  };

                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                Setvatreportclm(vatreportclm => [...vatreportclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "DetailedSale") {
        console.log("data ", collectionData);
        axios.post(GET_DETAILED_SALE_REPORT, collectionData).then((response) => {
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
      
            // Define the columns in the desired order
            const columns = [
              {
                field: 'Date',
                headerName: 'Date',
                width: 200,
                disableClickEventBubbling: true,
                renderCell: (params) => (
                  <p className='mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                ),
              },
              
              { field: 'Bill Number', headerName: 'Bill Number', width: 250 },
              { field: 'BARCODE', headerName: 'Item Code', width: 250 },
              { field: 'Item', headerName: 'Item Name', width: 250 },
              { field: 'Type', headerName: 'Type', width: 250 },
              { field: 'Qty', headerName: 'Qty', width: 250 },
              { field: 'Basic Amount', headerName: 'Basic Amount', width: 250 },              
              { field: 'Net Amount', headerName: 'Taxable Amount', width: 250 },
              { field: 'GST %', headerName: 'Tax', width: 250 },
              { field: 'GST Amount', headerName: 'Tax Amount', width: 250 },
              { field: 'Bill Amount', headerName: 'Bill Amount', width: 250 },
              { field: 'TPIN', headerName: 'TPIN', width: 250 },
              { field: 'Customer', headerName: 'Customer name', width: 250 },
              { field: 'Deposit', headerName: 'Deposit', width: 250 },
              { field: 'Sale By', headerName: 'Sold by', width: 250 },
              { field: 'Discount Amount', headerName: 'Discount Amount', width: 250 },
            ];
      
            SetdetailSaleclm(columns);
            setisLoading(false);
          } else {
            setcollectionreporttbl([]);
            setisLoading(false);
          }
        });
      }
      
      else if (rptName === "SaleReport") {
        axios.post(GET_SALE_REPORT, collectionData
        ).then((response) => {
        
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);

            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "SaleReturn") { 
        console.log("category",collectionData.Category)
        console.log("Euser",collectionData.Euser)
        console.log("ciunter",collectionData.SystemName)
        console.log(collectionData)
        axios.post(GET_SALE_RETURN_REPORT, collectionData
        ).then((response) => {
          console.log("data", response)
          if (response.data.IsSuccess === true) {
            setSalereturnclm([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
            
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };

                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                setSalereturnclm(Salereturnclm => [...Salereturnclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "CounterDetail") {
        axios.post(GET_COUNTER_DETAIL_REPORT, collectionData).then((response) => {
          if (response.data.IsSuccess === true) {
            setSaleclm([]);
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.forEach((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              } else {
                if (data === "Date") {
                  obj = {
                    field: data,
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => (
                      <p className='mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                    ),
                    headerName: " Date", // Change the header name if needed
                  };
                } else if (data === "Table Number") { // Change "Table Number" to "Customer Name"
                  obj = { "field": data, "headerName": "Customer Name", width: 250 };
                } else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                setCounterDetailsClm((CounterDetailsClm) => [...CounterDetailsClm, obj]);
              }
            });
            setisLoading(false);
          } else {
            setcollectionreporttbl([]);
            setisLoading(false);
          }
        });
      }
      
      
      else if (rptName === "BillWiseCommsission") { //BillWiseCommsissionClm  setBillWiseCommsissionClm
        axios.post(GET_BILL_COMMISSION_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setbillwisecommission([])
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };
                }
                else {
                  obj = { "field": data, "headerName": data, width: 250 };
                }
                setBillWiseCommsissionClm(BillWiseCommsissionClm => [...BillWiseCommsissionClm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "ClientWiseCommsission") {
        axios.post(GET_CLIENT_WISE_COMMISSION_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "SummaryCommission") {

        axios.post(GET_SUMMARY_COMMISSION_REPORT, collectionData
        ).then((response) => {
          console.log("yes summary comistionm ")
        
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "ItemSummary") {
        // ... Existing code ...
      
        axios.post(GET_ITEM_SUMMARY_REPORT, collectionData).then((response) => {
          console.log(response);
          if (response.data.IsSuccess === true) {
            setItemSummaryClm([]);
            setcollectionreporttbl(response.data.Data);
      
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              } else {
                // Rename column headers as needed
                let columnName = data;
                if (data === "Type") {
                  columnName = "Item Type";
                } else if (data === "Net Amount") {
                  columnName = "Taxable Amount";
                } else if (data === "GST %") {
                  columnName = "Tax";
                }
                else if (data === "GST Amount") {
                  columnName = "Tax Amount";
                }
                else if (data === "Gross Amount") {
                  columnName = "Total Amount";
                }
                else if (data === "Item") {
                  columnName = "Item Name";
                }
                else if (data === "Bar Code") {
                  columnName = "Item Code";
                }
      
                obj = { field: data, headerName: columnName, width: 250 };
                setItemSummaryClm((ItemSummaryClm) => [...ItemSummaryClm, obj]);
              }
            });
      
            setisLoading(false);
          } else {
            setcollectionreporttbl([]);
            setisLoading(false);
          }
        });
      }
      
      else if (rptName === "categorySale") {
        SetCategorySaleyclm([]);
        axios.post(GET_CATEGORY_SALE_REPORT, collectionData).then((response) => {
          if (response.data.IsSuccess === true) {
            console.log("category sale ", response);
            setcollectionreporttbl(response.data.Data);
            var totalQty = 0,
              totalBaciAmt = 0;
      
            response.data.Data.map((data, i) => {
              totalQty = data.Quantity + totalQty;
              totalBaciAmt = data.BasicAmount + totalBaciAmt;
            });
            setTotalQTY(totalQty);
            setTotalBasicAmount(totalBaciAmt);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              } else {
                // Change column names as needed
                let columnName = data;
                if (data === "Net Amount") {
                  columnName = "Taxable Amount";
                } else if (data === "GST Amount") {
                  columnName = "Tax";
                } else if (data === "Gross Amount") {
                  columnName = "Total Amount";
                }
      
                obj = { field: data, headerName: columnName, width: 250 };
                SetCategorySaleyclm((categorySaleyclm) => [
                  ...categorySaleyclm,
                  obj,
                ]);
              }
            });
            setisLoading(false);
          } else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false);
          }
        });
      }
      
      else if (rptName === "tableDetails") {
        axios.post(GET_TABLE_DETAILS_REPORT, collectionData
        ).then((response) => {
          SetTableDetailclm([]);
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                if (data === "Date") {
                  obj = {
                    field: "data",
                    headerName: "Date",
                    width: 200,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {

                      return (
                        <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                      );
                    }
                  };

                }
                else {

                  obj = { "field": data, "headerName": data, width: 250 };
                  SetTableDetailclm(tableDetailclm => [...tableDetailclm, obj]);
                }
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "KOTReport") {

        //kot details 
        SetKOTDetailclm([]);
        axios.post(GET_VIEW_KOT_DETAILS_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                SetKOTDetailclm(KOTDetailclm => [...KOTDetailclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "Financial") {
        SetFinancialDetailclm([]);
        //kot details 
        axios.post(GET_VIEW_FINANCIAL_DETAILS_REPORT, collectionData
        ).then((response) => {
           
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data.Table);
            let obj = {};
            const keys = Object.keys(response.data.Data.Table[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                SetFinancialDetailclm(FinancialDetailclm => [...FinancialDetailclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "clienttracking") {
        setClientTrackingclm([]);
        //kot details 
        axios.post(GET_VIEW_CLIENT_TRACKING_DETAILS_REPORT, collectionData
        ).then((response) => {
           

          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
        
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                setClientTrackingclm(ClientTrackingclm => [...ClientTrackingclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (rptName === "Expence") {

        //kot details 
        setExpenceclm([]);
        axios.post(GET_VIEW_EXPENCE_DETAILS_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
            setcollectionreporttbl(response.data.Data);
            let obj = {};
            const keys = Object.keys(response.data.Data[0]);
            keys.map((data, i) => {
              if (i === keys.length - 1) {
                obj = "";
              }
              else {
                obj = { "field": data, "headerName": data, width: 250 };
                setExpenceclm(Expenceclm => [...Expenceclm, obj]);
              }

            });
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
      else if (from.getTime() > To.getTime()) {
        setisalert(true)
        setalertmsg("from date and to date should not blank.");
        setalertbg(bgerror)
        setisLoading(false)
        setisLoading(false)
      }

      else {
        axios.post(GET_ITEM_SUMMARY_REPORT, collectionData
        ).then((response) => {
          if (response.data.IsSuccess === true) {
             
            setcollectionreporttbl(response.data.Data);
            setisLoading(false)
          }
          else {
            // var emptList=[];
            setcollectionreporttbl([]);
            setisLoading(false)
          }
        })
      }
    }

  }

  // const detailedSaleclm = [
  //   {
  //     field: "CreatedDate",
  //     headerName: "Created AT",
  //     width: 200,
  //     disableClickEventBubbling: true,
  //     renderCell: (params) => {
  //             if(params?.row?.CreatedDate?.split("T")[0]==="1990-01-01")
  //             {
  //               return (
  //                 <p className=' mt-2 f-14'></p>
  //               );
  //             }
  //             else
  //             {
  //               return (
  //                 <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
  //               );
  //             }

  //     }
  //   },
  //   { field: 'BillNumber', headerName: 'Bill Number', width: 200 },
  //   { field: 'UserName', headerName: 'UserName', width: 200 },
  //   { field: 'BasicAmount', headerName: 'Basic Amount', width: 200 },
  //   { field: 'DiscountAmount', headerName: 'Discount Amount', width: 200 },
  //   { field: 'Item', headerName: 'Item', width: 200 },
  //   { field: 'Type', headerName: 'Type', width: 200 },
  //   { field: 'BillAmount', headerName: 'Bill Amount', width: 200 },
  //   { field: 'NetAmount', headerName: 'Net Amount', width: 200 },
  //   { field: 'Deposit', headerName: 'Deposit', width: 200 },
  //   { field: 'GSTPercent', headerName: 'GST%', width: 200 },
  //   { field: 'GSTAmt', headerName: 'GST Amt', width: 200 },
  //   { field: 'SoldBy', headerName: 'Sold By', width: 200 },

  // ]
  // const Saleclm = [
  //   { field: 'Category', headerName: 'Category', width: 100 },
  //   { field: 'Qty', headerName: 'Qty', width: 160 },
  //   { field: 'BasicAmount', headerName: 'Basic Amount', width: 160 },
  //   { field: 'DiscountAmount', headerName: 'Discount Amount', width: 100 },
  //   { field: 'NetAmount', headerName: 'Net Amount', width: 150 },
  //   { field: 'GrossAmt', headerName: 'Gross Amount', width: 130 },
  // ]
  // const CounterDetailsClm = [
  //   {
  //     field: "CreatedDate",
  //     headerName: "Created AT",
  //     width: 200,
  //     disableClickEventBubbling: true,
  //     renderCell: (params) => {

  //       return (
  //         <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
  //       );
  //     }
  //   },
  //   { field: 'TblNo', headerName: 'Table Number', width: 200 },
  //   { field: 'count', headerName: 'count', width: 200 },
  //   { field: 'SaleAmt', headerName: 'Sale Amount', width: 200 },
  // ]
  // const BillWiseCommsissionClm = [
  //   {
  //     field: "CreatedDate",
  //     headerName: "Created AT",
  //     width: 200,
  //     disableClickEventBubbling: true,
  //     renderCell: (params) => {

  //       return (
  //         <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
  //       );
  //     }
  //   },
  //   { field: 'CompanyTotal', headerName: 'Company Total', width: 200 },
  //   { field: 'Client', headerName: 'Client', width: 200 },
  //   { field: 'Bill', headerName: 'Bill', width: 200 },
  //   { field: 'Qty', headerName: 'Qty', width: 200 },
  //   { field: 'Sale', headerName: 'Sale', width: 200 },
  //   { field: 'Percentage', headerName: 'Percentage', width: 200 },
  //   { field: 'Commission', headerName: 'Commission', width: 200 },
  // ]
  const SummaryWiseCommsissionClm = [
    { field: 'CompanyTotal', headerName: 'Company', width: 200 },
    { field: 'Qty', headerName: 'Qty', width: 200 },
    { field: 'Sale', headerName: 'Sale', width: 200 },
    { field: 'Commission', headerName: 'Commission', width: 200 },
  ]
  // const checkAllCheckboxes = (isChecked) => {
  //   const warehouseContainer = document.getElementById("warehousecheck");
  //   const checkboxes = warehouseContainer.querySelectorAll('input[type="checkbox"]');
    
  //   checkboxes.forEach((checkbox) => {
  //     checkbox.checked = isChecked;
  //   });
  // };


  const goHome =()=>{
    history.push('/dashboard')
  
  }
  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
      {
        isalert ? <Alertmessage bgcolor={alertbg} alertmsg={alertmsg} onClose={() => setisalert(false)} /> : null
      }

      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-5 col-12">
            <table className="collection_Table" >
              <tr>
                <td>
                  <button name="collection" className={`${activeButton === "collection" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Collection</button>
                 
                </td>
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    ""
                    :
                    <td>
                      <button name="SaleReport" className={`${activeButton === "SaleReport" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Sale Report</button>
                    </td>
                }
                 {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="Financial" className={`${activeButton === "Financial" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Financial</button>
                    </td>
                    :
                    <td>
                      <button name="BillWiseCommsission" className={`${activeButton === "BillWiseCommsission" ? 'activeCollectionbtn' : ''}`}  onClick={(e) =>handlereportbtnClick(e)}>Bill Wise Commission</button>
                    </td>
                }
              </tr>
              <tr>
                <td>
                  <button name="SaleSummary" className={`${activeButton === "SaleSummary" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Bill Summary" : "Sale Summary"}</button>                 
                </td>
                
                {SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "" :
                  <td>
                    <button name="Vatreport" className={`${activeButton === "Vatreport" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>VAT Report</button>
                 
                  </td>
                }
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? <td></td> :
                    <td>
                      <button name="ClientWiseCommsission" className={`${activeButton === "ClientWiseCommsission" ? 'activeCollectionbtn' : ''}`}  onClick={(e)=>handlereportbtnClick(e)} >Client Wise Commsission</button>
                    </td>
                }

              </tr>
              <tr>
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="categorySale" className={`${activeButton === "categorySale" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Category" : "Category Sale"}</button>

                    </td>
                    : ""}
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="KOTReport" className={`${activeButton === "KOTReport" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>KOT report</button>
                     
                    </td>
                    :
                    <td>
                    </td>
                }
                <td>
                </td>
              </tr>
              <tr>

                <td>
                  <button name="ItemSummary" id="ItemSummary" className={`${activeButton === "ItemSummary" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Item Details" : "Item Summary"}</button>
                </td>
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button name="Expence" className={`${activeButton === "Expence" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Expence</button>        
                    </td>
                    :
                    <td>
                      <button name="CounterDetail" className={`${activeButton === "CounterDetail" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)} >Bill Summary</button>                
                    </td>
                }
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    ""

                    :
                    <td>
                      <button name="Day_Collection" className={`${activeButton === "Day_Collection" ? 'activeCollectionbtn' : ''}`} 
                      //  onClick={(e)=>handlereportbtnClick(e)}
                      >Day Collection</button>
                    </td>
                }
                

              </tr>
              <tr>
              <td>
                  <button name="DetailedSale" className={`${activeButton === "DetailedSale" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Bill Detail" : "Detailed Sale"}</button>   
                </td>
                
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "" :
                    <td>
                      <button name="categorySale" className={`${activeButton === "categorySale" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>{SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "Category" : "Category Sale"}</button>
                    </td>
                }
                 {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "" :
                    <td>
                      <button name="P&L" className={`${activeButton === "P&L" ? 'activeCollectionbtn' : ''}`}>P & L</button>
                    </td>
                }
               
                
              </tr>
              
              <tr>
              {SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                  <td>
                    <button name="tableDetails" className={`${activeButton === "tableDetails" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Table Details</button>
                  </td> :
                  <td>
                    <button name="SaleReturn" className={`${activeButton === "SaleReturn" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)}>Sale Return</button>
                  </td>}
                  {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ?
                    <td>
                      <button
                       name="clienttracking"
                         className={`${activeButton === "clienttracking" ? 'activeCollectionbtn' : ''}`}
                       onClick={(e) => {
                         handlereportbtnClick(e);                        
                     }}
                        >
                          Client Tracking
                        </button>
                    </td>
                    :
                    <td>
                      <button name="SummaryCommission" className={`${activeButton === "SummaryCommission" ? 'activeCollectionbtn' : ''}`} onClick={(e) => handlereportbtnClick(e)} >Summary Commission</button>
                    </td>
                }
                {
                  SELECTED_COMPANY_TYPE?.toUpperCase() == "RESTAURANT" ? "" :
                    <td>
                      <button name="colPerioudExpenseslection" className={`${activeButton === "colPerioudExpenseslection" ? 'activeCollectionbtn' : ''}`} >Period Expenses</button>
                    </td>
                }
               
              </tr>
            </table>

          </div>
          <div className="col-md-7 col-sm-7 col-12 ">
            <div className="row">
                              <div className="col-md-5 col-sm-6 col-12">
                    <div className="col-auto d-flex">
                      <label htmlFor="date" className="col-form-label">FROM</label>&nbsp;&nbsp;&nbsp;
                      <input
                        type="date"
                        className="form-control w-75 input_date"
                        id="FDate"
                        style={{ marginBottom: "5px" }}
                        name="FormDate"
                        value={fromdate}
                        onChange={(e) => {
                          setfromdate(e.target.value);
                          allcounter();
                          alluser();
                        }}
                        
                      />
                    </div>
                    <div className="col-auto d-flex">
                      <label htmlFor="date" className="col-form-label">To</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="date"
                        className="form-control w-75"
                        id="ToDate"
                        style={{ marginBottom: "5px" }}
                        name="ToDate"
                        value={todate}
                   
                        onChange={(e) => {
                          settodate(e.target.value);
                          allcounter();
                          alluser();
                        }}
                        
                      />
                    </div>
                    {SELECTED_COMPANY_TYPE.toUpperCase() === "RESTAURANT" ? (
                      ""
                    ) : (
                      <>
                        <div className="col-auto d-flex">
                          <label htmlFor="date" className="col-form-label">Counter</label>&nbsp;
                          <select
                            className="w-75"
                            name="SystemName"
                            style={{ marginBottom: "5px" }}
                            disabled={collectionReportType === "ItemSummary"||collectionReportType === "SaleReport"||collectionReportType === "Vatreport"||collectionReportType === "ClientWiseCommsission"? true : false}
                            onChange={(e) => {
                              handleCollectionChange(e);
                            }}
                          >
                            {/* <option value="">--Select Counter--</option> */}
                            {allCounts.map((data, i) => {
                              return <option value={data.Euser}>{data.Euser}</option>;
                            })}
                          </select>
                        </div>
                        <div className="col-auto d-flex">
                          <label htmlFor="date" className="col-form-label">User</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <select
                          className="w-75"
                            name="Euser"
                            style={{ marginBottom: "5px", marginTop: "2px" }}
                            disabled={collectionReportType === "ItemSummary"||collectionReportType === "SaleReport" ||collectionReportType === "Vatreport"||collectionReportType === "ClientWiseCommsission"? true : false}
                            onChange={(e) => {
                              handleCollectionChange(e);
                            }}
                          >
                            {/* <option value="">--Select USER--</option> */}
                            {allUser.map((data, i) => {
                              return <option value={data.Euser}>{data.Euser}</option>;
                            })}
                          </select>
                        </div>
                      </>
                    )}
                    <div className="col-auto d-flex">
                      <label htmlFor="date" className="col-form-label">Category</label>&nbsp;
                      <select
                      
                        ref={selectRef}
                        className="w-75"
                        id="categoryDD"
                        name="Category"
                        style={{ marginBottom: "5px" }}
                        disabled={collectionReportType === "ItemSummary"||collectionReportType === "SaleReport"
                        ||collectionReportType === "DetailedSale"||collectionReportType === "collection"||collectionReportType === "Vatreport"||
                        collectionReportType === "SaleReturn" ||collectionReportType === "CounterDetail"||collectionReportType === "categorySale" 
                        ||collectionReportType === "BillWiseCommsission" ||collectionReportType === "SummaryCommission"||collectionReportType === "ClientWiseCommsission"?false : true}
                        onChange={(e) => {
                          handleCollectionChange(e);
                        }}
                      >
                        {/* <option value="">--Select Category--</option> */}
                        {allCategories.map((data, i) => {
                          return (
                            <option value={data.categoryName}>{data.categoryName}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>


              <div className="col-md-3 col-sm-6 col-12" id="warehousecheck">
                {
                  SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ?
                    "" :
                    <>
                  {warehouse.map((wareh, i) => (
                  <div className="form-check warehouse-checkbox" key={i}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={wareh.Type}
                      value={wareh.Type}
                      name={wareh.Type}
                      // checked={checkwarehouse}
                      disabled={collectionReportType === "ItemSummary"? true : false}
                      onChange={handleCollectionChange}
                    />
                    <label className="form-check-label" htmlFor={wareh.Type}>
                      {wareh.Type}
                    </label>
                  </div>
                  ))}
                    </>
                }


              </div>
              <div className="col-md-2 col-sm-3 col-6">
                {
                  SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ?

                    "" :
                    <>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="disabledFieldsetCheck" />
                        <label className="form-check-label" for="disabledFieldsetCheck">
                          Report By VAT
                        </label>
                        <select style={{ width: "100%" }} disabled={collectionReportType === "DetailedSale" || collectionReportType === "ItemSummary" ? false : true}>
                          {/* <option value="">--Select an option--</option> */}
                          <option value="ALLL">ALL</option>
                          <option value="VAT">VAT</option>
                          <option value="MTV">MTV</option>
                          <option value="EXEMPT">EXEMPT</option>
                        </select>
                      </div>

                    </>
                }

              </div>
              <div className={` col-md-2 col-sm-3 col-6 collection_brnGrp ${SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}>

                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} onClick={e=>{getReport(collectionReportType)}}>Display</button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} >View Client</button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} >Print </button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} disabled={collectionreporttbl?.length == 0 ? true : false} ><CSVLink data={collectionreporttbl} className="text-white"> EXCEL</CSVLink></button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`}  onClick={goHome}>exit</button>
              </div>
            </div>
          </div>
        </div >
      </div >
      <hr className={`${classes[companyColor?.split(',')[0] + 'TEXT']}`} />
      <p className={`${classes[companyColor?.split(',')[0] + 'TEXT']}`} style={{ textAlign: 'center', fontWeight: '700' }} >{blankmsg.toUpperCase()} &nbsp; REPORT</p>
      <div className={`${SELECTED_COMPANY_TYPE.toUpperCase() == "RESTAURANT" ? " row restronTableDiv" : " row posTableDiv"}`}>
        
       <div className="col-md-12 col-sm-12 col-12">
          {
            collectionReportType === "collection"  ?
             <ReportTbl dataArray={collectionreporttbl} TblColumns={collectionsummaryclm} headerColor={companyColor} />
              : ""
          }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
          {
            collectionReportType === "SaleSummary"?
              <ReportTbl dataArray={collectionreporttbl} TblColumns={salesummaryclm} headerColor={companyColor} /> : ""

          }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "DetailedSale" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={detailSaleclm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "Vatreport" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={vatreportclm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "SaleReturn" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={Salereturnclm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "SaleReport" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={Saleclm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "CounterDetail" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={CounterDetailsClm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "BillWiseCommsission" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={BillWiseCommsissionClm} headerColor={companyColor} /> : ""

        }</div>
          <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "ClientWiseCommsission" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={BillWiseCommsissionClm} headerColor={companyColor} /> : ""

        }</div>
           <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "SummaryCommission" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={SummaryWiseCommsissionClm} headerColor={companyColor} /> : ""

        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "ItemSummary" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={ItemSummaryClm} headerColor={companyColor} /> : ""

        }
     </div>
     <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "categorySale" ?
            <> <ReportTbl dataArray={collectionreporttbl} TblColumns={categorySaleyclm} headerColor={companyColor} /><div className="row">
              {/* <div className="col-md-2 col-sm-2 col-2"><h6>Total Quantity:<span>{totalQTY}</span></h6>
              </div>
              <div className="col-md-2 col-sm-2 col-2"><h6>Total Basic Amount:<span>{TotalBasicAmount}</span></h6>
              </div> */}
            </div> </> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "tableDetails"  ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={tableDetailclm} headerColor={companyColor} /> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "KOTReport" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={KOTDetailclm} headerColor={companyColor} /> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "Financial" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={FinancialDetailclm} headerColor={companyColor} /> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "clienttracking" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={ClientTrackingclm} headerColor={companyColor} /> : ""
        }
        </div>
        <div className="col-md-12 col-sm-12 col-12">
        {
          collectionReportType === "Expence" ?
            <ReportTbl dataArray={collectionreporttbl} TblColumns={Expenceclm} headerColor={companyColor} /> : ""
        }
        </div>
      </div>

    </>
  );
}

