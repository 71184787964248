import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import {
  Grid,
  TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  InputLabel
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import axios from "axios";
import useStyles from "./styles";
import { COMPANY_CODE, GET_CLIENTS } from "../../constant";

export default function ClientList(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [isLoading, setisLoading] = useState(false);
  const [companyColor, setcompanyColor] = useState("");
  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const pageSize = 30;

  const fetchClients = async () => {
    try {
      const response = await axios.post(GET_CLIENTS, {
        CompanyNumber: COMPANY_CODE,
        userType: "",
        route: "",
        Mcode: ""
      });
      const clientList = response.data.Data;
      setClients(clientList);
      setTotalPages(Math.ceil(clientList.length / pageSize));
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType').split(",")[0]);
    var a = window.location.href;
  });

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const filteredClients = clients.filter((client) =>
    client.acname.toLowerCase().includes(searchValue.toLowerCase())
  );

  const paginatedClients = filteredClients.slice(
    (page - 1) * pageSize,
    page * pageSize
  );

  return (
    <>
      {isLoading ? <div className='Commonloader'></div> : null}
      <div className='accountsearch'>
        <Grid container spacing={6} className="pb-0">
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <form className="search">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  placeholder="Search..."
                  style={{
                    height: '45px',
                    width: '80%',
                    padding: '5px',
                    border: '1px solid #fff',
                    borderRadius: '20px 0px 0px 20px',
                    boxShadow: '0px 2px 2px rgba(137, 137, 137, 0.25)',
                  }}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
                <button
                  style={{
                    backgroundColor:"#fff",
                    marginLeft: '6px',
                    padding: '5px 10px',
                    height:'45px',
                    border: '1px solid #fff',
                    borderRadius: "0px 20px 20px 0px",
                    cursor: 'pointer',
                    boxShadow: '0px 2px 2px rgba(137, 137, 137, 0.25)',
                  }}
                >
                  <SearchIcon name="apps-outline"></SearchIcon>
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={6} className={companyColor}>
        {paginatedClients.map((client, index) => (
          <Grid item lg={4} md={4} sm={4} xs={12} className="pb-0" key={index}>
            <div className={`card py-1 ${classes.row1Card}`}>
              <div className="card-body">
                <h6 className={`card-title my-0 fw-bold`}>{client.acname}</h6>
                <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap></h4>  
                <ol className={`p-0 mt-3 mb-0  pl-0 ${classes.textSecondry}`} start='1'>
                  <li className="list-group-item"><h6 className={classes.textSecondry}>GST No : {client.gstNo}</h6></li>
                  <li className="list-group-item"><h6 className={classes.textSecondry}>Address: {client.address}</h6></li>                      
                </ol>  
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <div style={{ marginTop: '20px' }}>
        <Grid container justify="center">
          <Button
            variant="contained"
            color="primary"
            disabled={page === 1}
            onClick={() => handlePageChange(page - 1)}
            style={{margin:"20px", color:"#fff"}}
          >
            Previous Page
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={page === totalPages}
            onClick={() => handlePageChange(page + 1)}
            style={{margin:"20px", color:"#fff"}}
          >
            Next Page
          </Button>
        </Grid>
      </div>
    </>
  );
}
