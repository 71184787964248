import { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LibraryBooksTwoToneIcon from '@material-ui/icons/LibraryBooksTwoTone';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
// import LogoImg from '../../../images/logo.svg';
import IconImg from '../../../../images/restaurantLOGO.png';
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./style";

const Sidebar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeIndex, setActiveIndex] = useState(0);
    const [stepHeight, setStepHeight] = useState(0);
    const sidebarRef = useRef();
    const [Fyear, setFyear] = useState(localStorage.getItem("Financial_Year"))

    const indicatorRef = useRef();
    const location = useLocation();
    const [reportmenu, setreportmenu] = useState("d-none")
    const [isReprtOpen, setIsReprtOpen] = useState(false);
    const [username, setusername] = useState(localStorage.getItem('username'))

    useEffect(() => {
        // var favicon = document.querySelector("link[rel~='icon']");
        //     favicon.href = IconImg;

        var favicon = document.querySelector("link[rel~='icon']");
        favicon.href = IconImg;
        document.documentElement.style.setProperty('--thumb-bg-color', `#fb7004`);
        document.documentElement.style.setProperty('--scroller-width', `10px`);

        // document.querySelector('.TOGGLEicon').click();
        //     setTimeout(() => {
        //         const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item');
        //         indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
        //         setStepHeight(sidebarItem.clientHeight);
        //     }, 50);
    }, []);


    const handlelogoutPopup = () => {
        props.onLogout(true);
    }
    // change active index
    // useEffect(() => {
    //     const curPath = window.location.pathname.split('/')[1];
    //     const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
    //     setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    // }, [location]);

    const handlerepportsubmenu = () => {

        if (reportmenu == "d-none") {
            setreportmenu("reportsSubmenu")
        }
        else {
            setreportmenu("d-none")
        }


    }
    const sidebarList = [
        {
            "name": "Dashboard",
            "link": "/dashboard",
            "icon": <HomeOutlinedIcon />,
        },
        {
            "name": "Approvals",
            "link": "/Approvals",
            "icon": <LibraryBooksTwoToneIcon />,
        },
        {
            "name": "Open Enquiries",
            "link": "/OpenEnquiries",
            "icon": <LiveHelpOutlinedIcon />,
        },
        {
            "name": "Reports",
            "link": "/reports",
            "icon": <LiveHelpOutlinedIcon />,
        },
        {
            "name": "Help",
            "link": "/help",
            "icon": <LiveHelpOutlinedIcon />,
        },
    ];
    const ReoprtList = [
        {
            "name": "Collection Report",
            "link": "/collectionReport",
        },
        //     {"name":"Account Status",
        //     "link":"/AccountStatus",
        //    },
        //    {"name":"General Ledger",
        //    "link":"/generalLeadure",
        //   },
        //   {"name":"Party Stock Ledger",
        //   "link":"/PartyStockLedger",
        //  },
        //  {"name":"Trail Balance",
        //  "link":"/TrailBalance",
        // },
        // {"name":"Ageing Analysis",
        // "link":"/AgeingAnalysis",
        // },
        // {"name":"Gstr",
        // "link":"/Gstr",
        // },
    ];
    const onHandelSidPopoUp = () => {
        document.querySelector('.TOGGLEicon').click();
        if (isReprtOpen) {
            setIsReprtOpen(false);

        }
        else {
            setIsReprtOpen(true);
        }
    }
    return <div className={`MObileMenu ${classes.sidebar}`} >
        <div className={classes.sidebarLogo}>
            <div style={{ textAlign: "center" }}>
                <div className={classes.sidelogo}>
                    {/* <p className={classes.sideMainText}>SaiTec</p> */}

                    {/* <img src={LogoImg} alt="Example Image" style={{ width: "230px", height: "45px" ,marginRight:"10px"}} /> */}
                    <img src={IconImg} alt="icon" className={classes.userSideImg} />
                    {/* <p className={`fs-5 mb-0 fw-bold ${classes.textPrimary} pt-4`}> {username}</p> */}
                    {/* <p className={`fs-6 fw-bold ${classes.textSecondry}`}>ankursinha@gmail.com</p> */}
                </div>
            </div>
        </div>
        <div ref={sidebarRef} className={`p-3   ${classes.sidebarMenu}`}>
            <NavLink to="/dashboard" activeclassname="active" style={{ textDecoration: 'none' }} >
                <div className={`my-2 act ${classes.sidebarMenuItem}`}>{/*`${activeIndex ? '' : ''}`*/}
                    <span className={classes.sidebarMenuItemIcon}>
                        <HomeOutlinedIcon />
                    </span>
                    <span className={classes.sidebarMenuItemText}>
                        Dashboard
                    </span>
                </div>
            </NavLink>
            {/* <Link to="/Approvals">
                            <div  className={`my-2 ${classes.sidebarMenuItem}`}>
                                <span className={classes.sidebarMenuItemIcon}>
                                <LibraryBooksTwoToneIcon/>
                                </span>
                                <span className={classes.sidebarMenuItemText}>
                                Approvals
                                </span>
                            </div>
                           </Link>  */}
            {/* <Link to="/OpenEnquiries" > 
                            <div  className={`my-2 ${classes.sidebarMenuItem}`}>
                                <span className={classes.sidebarMenuItemIcon}>
                                <LiveHelpOutlinedIcon/>
                                </span>
                                <span className={classes.sidebarMenuItemText}>
                                Open Enquiries
                                </span>


                            </div>
                           </Link> */}

            <NavLink to="/collectionReport" activeclassname="active" style={{ textDecoration: 'none' }} >

                {/* <NavLink to="/Reports" activeclassname="active" style={{ textDecoration: 'none'}} > */}

                <div className={`my-2 act ${classes.sidebarMenuItem}`}>{/*`${activeIndex ? '' : ''}`*/}
                    <span className={classes.sidebarMenuItemIcon}>
                        <LiveHelpOutlinedIcon />
                    </span>

                    <span className={`coloring ${classes.sidebarMenuItemText}`} onClick={e => { onHandelSidPopoUp(e) }}>

                        Reports
                    </span>
                </div>

            </NavLink>

            {
                isReprtOpen ?
                    <div className={classes.subMenu}>
                        {
                            ReoprtList.map((data, i) => {
                                return (
                                    <Link to={data.link} >
                                        <div className={`my-2 ${classes.sidebarMenuItem}`}>{/*`${activeIndex ? '' : ''}`*/}

                                            <span className={classes.sidebarMenuItemText}>
                                                {data.name}
                                            </span>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div> : ""
            }
            <NavLink to="/help" activeclassname="active" style={{ textDecoration: 'none' }} >
                <div className={`my-2 act ${classes.sidebarMenuItem}`}>{/*`${activeIndex ? '' : ''}`*/}
                    <span className={classes.sidebarMenuItemIcon}>
                        <LiveHelpOutlinedIcon />
                    </span>
                    <span className={classes.sidebarMenuItemText}>
                        Help
                    </span>


                </div>
            </NavLink>
            <div className={`mt-3 ${classes.lowerMenu}`}>
                {/* <NavLink to="/Logout" activeclassname="active" style={{ textDecoration: 'none'}} > */}
                <div className={`my-2 ${classes.sidebarMenuItem}`} onClick={handlelogoutPopup}>{/*`${activeIndex ? '' : ''}`*/}
                    <span className={classes.sidebarMenuItemIcon}>
                        <ExitToAppOutlinedIcon />
                    </span>
                    <span className={classes.sidebarMenuItemText}>
                        Logout
                    </span>
                </div>
                {/* </NavLink> */}
            </div>


            {/* <Link to="/Logout" >
                        <div  className={`sidebar__menu__item ${activeIndex ? '' : ''}`} >
                            <div className="sidebar__menu__item__icon">
                            <ExitToAppOutlinedIcon/>
                            </div>
                            <div className="sidebar__menu__item__text">
                            LOGOUT
                            </div>
                        </div>
                        </Link> */}
            <p className={`fs-6 text-white position-absolute bottom-0`}>Financial Year {Fyear}</p>
        </div>
    </div>;
};

export default Sidebar;
