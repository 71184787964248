import { Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { Line } from "react-chartjs-3";
const ref = React.createRef();
const chaart = (props) => {

  const labels=[];
  const dataSetPurchase=[];
  const dataSetSale=[];
 


  props.graphDate.map((c,i)=>
  {
    c.graphCountDate=c.graphCountDate?.split("T")[0];
    labels.push(c.graphCountDate);
    c.graphCountType==="PURCH"?dataSetPurchase.push(c.graphCount):
    dataSetSale.push(c.graphCount);
  });
 
    const options = {
        orientation: "landscape",
        unit: "in",
        format: "a4"
      };
      
  const data = {
   // labels: ["January", "February", "March", "April", "May", "June", "July","August","September","October","November","December"],
    labels: labels,

    datasets: [
      {
        label: "Purchase",
        data:dataSetPurchase,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "#CE2A96",
   
      },
      {
        label: "Sales",
       // data: [28, 48, 40, 19, 86, 27, 100, 30, 88, 23, 44, 69],
     data: dataSetSale,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "#0758A3",
     
      },
      // {
      //   label: "Net Profit",
      //   data: [45, 25, 16, 36, 67, 18, 86, 55, 44, 88, 99, 20],
      //   backgroundColor: "rgba(255, 206, 86, 0.2)",
      //   borderColor: "#FFA134",
        
      // }
    ]
  };


  return (
   <>
    <div className="">
    <div className="chart">
 <div ref={ref}>
 <Typography variant="h6" >
          Revenue Generated
        </Typography>
   <h3></h3>

 <Line
     data={data}
     width={90}
     height={110}
     options={{
       maintainAspectRatio: false
     }}
   />

 </div>
</div>
</div>
   </>
  )
}

export default chaart;
