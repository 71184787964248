
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Area,
  PieChart,
  Pie, Tooltip,
  Cell, LineChart,
  YAxis,
  XAxis, CartesianGrid
} from "recharts";

import axios from "axios";
// styles
import useStyles from "../styles";

import { COMPANY_CODE, GET_ALL_FG_STOCK, GET_ALL_ITEM_PRODUCED,
    GET_ALL_WAREHOUSES, GET_ITEMS_BELOW_ROL, GET_SALE_ORDER_GRID, GET_TOTAL_BILL_OF_DAY,
     GET_TOTAL_FG_STOCK, GET_TOTAL_FG_STOCK_CATEGORY, GET_TOTAL_ITEM_PRODUCED_CATEGORY } from "../../../../constant";
 import { GET_TOTAL_ITEM_PRODUCED } from "../../../../constant";
 
 const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]
 export default function Dashboard(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [startdate, setstartdate] = useState(props.startdate)

    const [subDashBoardName, setSubDashBoardName] = useState("Master")  
    const [enddate, setenddate] = useState(props.enddate)
    const [todaysdate, settodaysdate] = useState("")
    // const [selectedOption, setSelectedOption] = useState('');
    // local
    
    const [ItemsBelowRol, setItemsBelowRol] = useState([]);
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const [loginname, setloginname] = useState(localStorage.getItem('username'))
    const [SaleOrderGRid, setSaleOrderGRid] = useState([]) 
    const [totalbillCountofday, settotalbillCountofday] = useState(1)
    const [isLoading, setisLoading] = useState(false)
    const [companyColor, setcompanyColor] = useState("")
    const[warehouse,setwarehouse]=useState([])
    const [selectedOption, setSelectedOption] = useState('');
    const [activeButton, setActiveButton] = useState("");  
  
    //  FG STOCK
    const [FGstockcount, setFGstockcount] = useState(0)
    const [FGstockClick, setFGstockClick] = useState(false)
    const [FGstockClickData, setFGstockClickData] = useState([])
    const [FGstockClickcellData, setFGstockClickcellData] = useState([])
    const [FGtblcellClick, setFGtblcellClick] = useState(false)
  
    const [thisBCD, setthisBCD] = useState("")
  
    // ITEM PRODUCED
  
    const [ItemProducedcount, setItemProducedcount] = useState(0)
    const [ItemProducedClick, setItemProducedClick] = useState(false)
    const [ItemProducedClickData, setItemProducedClickData] = useState([])
    const [ItemProducedClickcellData, setItemProducedClickcellData] = useState([])
    const [IPtblcellClick, setIPtblcellClick] = useState(false)
  
    const currentliveenquryclm = [
      { field: 'topClientName', headerName: 'CLIENT NAME', width: 240 },
      { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
      { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 }
  
    ]
    //total fg stock 
    const FGstockClickDataCLM = [
      { field: 'Item', headerName: 'ITEM', width: 100 },
      { field: 'Qty', headerName: 'QTY', width: 50 },
      { field: 'BCD', headerName: 'BCD', width: 100 },
    ]
    const FGstockCellClickDataCLM = [
      { field: 'Item', headerName: 'ITEM', width: 200 },
      { field: 'Qty', headerName: 'QTY', width: 50 },
    ]
  const SaleOrderGRidCLM =[
    { field: 'Order No', headerName: 'Order No', width: 200 },
    { field: 'DATED', headerName: 'Date', width: 200 },
    { field: 'ITEM', headerName: 'Item', width: 300 },
    { field: 'QTY', headerName: 'Qty', width: 100 },
   // {field: 'Bal Qty', headdrName:'Bal Qty', width:100},
  ]
  
    // total items produced 
  
    const ItemProducedClickDataCLM = [
      { field: 'Item', headerName: 'ITEM', width: 200 },
      { field: 'Qty', headerName: 'QTY', width: 100 },
      { field: 'BCD', headerName: 'BCD', width: 150 },
    ]
    const ItemProducedCellClickDataCLM = [
      { field: 'Item', headerName: 'ITEM', width: 200 },
      { field: 'Qty', headerName: 'QTY', width: 200 },
    ]
  
    const debitorbalancedataclm = [
      { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150 },
      { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150 },
      { field: 'Debit', headerName: 'DEBITED', width: 80 },
      { field: 'credit', headerName: 'CREDIT', width: 80 },
      { field: 'balance', headerName: 'BALANCE', width: 100 },
    ]
  
    
  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  
    useEffect(() => {
      setcompanyColor(localStorage.getItem('cmpnyType')?.split(',')[0])
      setisLoading(true)
      setTimeout(() => {
        setisLoading(false)
      }, 1500);
      // ---------- Items below ROL----------------
      axios.post(GET_ITEMS_BELOW_ROL, { "CompanyNumber": COMPANY_CODE }).then((response) => {
        if (response.data.IsSuccess === true) {
          setItemsBelowRol(response.data.Data);
        }
      })
      //---------- GET_TOTAL_FG_STOCK----------------
      axios.post(GET_TOTAL_FG_STOCK, { "CompanyNumber": COMPANY_CODE }).then((response) => {
        if (response.data.IsSuccess === true) {
          setFGstockcount(response.data.Data.BillCount);
        }
      })
  
  
      //---------- GET_TOTAL_FG_STOCK_CATEGORY---------------
      axios.post(GET_TOTAL_FG_STOCK_CATEGORY, { "CompanyNumber": COMPANY_CODE, "": "110NOIDA" }).then((response) => {
        if (response.data.IsSuccess === true) {
          setFGstockClickData(response.data.Data);//
  
        }
      })
      //----------GET_TOTAL_ITEM_PRODUCED----------------
      axios.post(GET_TOTAL_ITEM_PRODUCED, { "CompanyNumber": COMPANY_CODE })
        .then((response) => {
          if (response.data.IsSuccess === true) {
            setItemProducedcount(response.data.Data.Qty)
          }
        })
  
      //---------- GET_ITEM_PRODUCED_CATEGORY---------------
      axios.post(GET_TOTAL_ITEM_PRODUCED_CATEGORY, { "CompanyNumber": COMPANY_CODE }).then((response) => {
        if (response.data.IsSuccess === true) {
          setItemProducedClickData(response.data.Data)
        }
      })
      //---------- GET Sale Order Grid----------------
      axios.post(GET_SALE_ORDER_GRID, { "CompanyNumber": COMPANY_CODE }).then((response) => {
        if (response.data.IsSuccess === true) {
  
          setSaleOrderGRid(response.data.Data)
        }
      })
      //---------- GET GRAPH DATA FOR DASHBOERD----------------
      //   axios.post(GET_GRAPH_DATA,{
      //     "StartDate":"01-03-2023",
      //     "EndDate":"10-03-2023"
      // }).then((response)=>{
      //     console.log(response)
      //     if(response.data.IsSuccess === true){
      //       setGraphDate(response.data.Data);
      //     }
      //   })
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(today.getMonth());
  
      const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
      const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
        .replace(/\//g, '-');
  
      settodaysdate(formattedDate)
  
      /**all warehouses dropdown */
      axios.post(GET_ALL_WAREHOUSES,
        { "CompanyNumber": COMPANY_CODE })
        .then((response) => {
          if (response.data.IsSuccess === true) {
            setwarehouse(response.data.Data)
          }
        })

        /// get report call on evey time the date prop changes
        getreports(props.startdate, props.enddate)
    }, [props.startdate, props.enddate]);

    const getreports = (start , end) => {
      //  ---------- TotalBillTotheday----------------
      axios.post(GET_TOTAL_BILL_OF_DAY, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
        .then((response) => {
      
          if (response.data.IsSuccess === true) {
            settotalbillCountofday(response.data.Data.BillCount)
  
          }
        })
    }
  
  
    const getreportallwarehouse = (e) => {
      setActiveButton(e.target.name)
  
      const today = new Date();
      const oneMonthsAgo = new Date();
      oneMonthsAgo.setMonth(today.getMonth() - 1);
      const formattedDate = oneMonthsAgo.toISOString().slice(0, 10);
      setstartdate(formattedDate)
      setenddate(new Date().toISOString().slice(0, 10))
      // // hit apply BUtton  //So  every thing is Updated 
      // // document.querySelector('#getReport').click()
      getreports(formattedDate, new Date().toISOString().slice(0, 10))
  
    } 
  
    const getreportseightmonths = () => {
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(today.getMonth() - 8);
  
      const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
      const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
        .replace(/\//g, '-');
  
      var datebeforenineMOnth = formattedDate;
  
      //   axios.post(GET_GRAPH_DATA,{
      //     "StartDate":todaysdate,
      //     "EndDate":datebeforenineMOnth
      // }).then((response)=>{
      //     console.log(response)
      //     if(response.data.IsSuccess === true){
      //       setGraphDate(response.data.Data);
      //     }
      //     else{
      //       alert("No record Found !!")
      //     }
      //   })
  
    }
  
    const getreportssixmonths = () => {
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(today.getMonth() - 6);
  
      const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
      const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
        .replace(/\//g, '-');
  
  
      var datebeforesixMOnth = formattedDate;
  
      // axios.post(GET_GRAPH_DATA,{
      //   "StartDate":todaysdate,
      //   "EndDate":datebeforesixMOnth
      // }).then((response)=>{
      //   console.log(response)
      //   if(response.data.IsSuccess === true){
      //     setGraphDate(response.data.Data);
      //   }
      //   else{
      //     alert("No record Found !!")
      //   }
      // })
    }
  
    const getreportsthreemonths = () => {
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(today.getMonth() - 3);
  
      const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
      const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
        .replace(/\//g, '-');
  
      var datebeforethreeMOnth = formattedDate;
  
      // axios.post(GET_GRAPH_DATA,{
      //   "StartDate":todaysdate,
      //   "EndDate":datebeforethreeMOnth
      // }).then((response)=>{
      //   console.log(response)
      //   if(response.data.IsSuccess === true){
      //     setGraphDate(response.data.Data);
      //   }
      //   else{
      //     alert("No record Found !!")
      //   }
      // })
    }
  
    const HandleFGStock = () => {
      setFGstockClick(true)
  
    }
    const handleCloseFGstockClick = () => {
      setFGstockClick(false)
      setFGtblcellClick(false)
    }
  
    const HandleItemProduced = () => {
      setItemProducedClick(true)
  
    }
    const handleCloseItemProducedClick = () => {
      setItemProducedClick(false)
      setIPtblcellClick(false)
    }
  
    const openRFQsForm = (a, b, c, d) => {
      // debugger;
  
    }
    const handleDashFGtblcellClick = (params) => {

      setthisBCD(params.row.BCD)
      axios.post(GET_ALL_FG_STOCK, { "CompanyNumber": COMPANY_CODE, "Warehouse": "110NOIDA", "BCD": params.row.BCD })
        .then((response) => {

          if (response.data.IsSuccess === true) {
            setFGstockClickcellData(response.data.Data)
            setFGtblcellClick(true)
          }
        })
      // setthisBCD(params.row.BCD)
    }
  
    // item produced 
  
    const handleDashIPtblcellClick = (params) => {
      setthisBCD(params.row.BCD)
      axios.post(GET_ALL_ITEM_PRODUCED, { "CompanyNumber": COMPANY_CODE, "BCD": params.row.BCD })
        .then((response) => {
          if (response.data.IsSuccess === true) {
            setItemProducedClickcellData(response.data.Data)
            setIPtblcellClick(true)
          }
        })
  
    }
  
    var nowexactdate = function getFormettedDate() {
      var datentime = ""
      let today = new Date();
  
      // Get day, month, and year separately
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
  
      // Create an array of month names
      let monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
  
      // Get the month name from the array
      let monthName = monthNames[month - 1];
      datentime = `${day} ${monthName} ${year}`
      return datentime
  
    }
  
    const handleResize = () => {
      setShouldUpdate(false);
      setTimeout(() => {
        setShouldUpdate(true);
      }, 500);
    };
  
  const SubDashboard=[
    {
      "name":"Master"
    },
    {
      "name":"Item"
    },
    {
      "name":"Category"
    },
  
  ]
  
    return (
      <>
        {
          isLoading ? <div className='Commonloader'>
          </div> : null
        }
 
        <Grid container spacing={6} className={companyColor}>
          < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
            <div className={`card py-1 ${classes.row1Card}`}>
              <div className="card-body pb-4">
                <h6 className={`card-title my-0 fw-bold`}>Total FG Stock</h6>
                <Grid container item alignItems={"center"}>
                  <Grid item xs={10}>
                    {
                      FGstockClick ? "" : <h1 onClick={HandleFGStock}>{FGstockcount}</h1>
                    }
                    <Grid container spacing={5} className="pt-0 withoutscroller" >
  
                      {
                        FGstockClick ?
                          <>
                            {FGtblcellClick ?
                              <p onClick={(e) => setFGtblcellClick(false)} style={{ marginLeft: '200px' }}>back</p>
                              :
                              <p onClick={handleCloseFGstockClick} style={{ marginLeft: '200px' }}>×</p>
                            }
                            <div className='table animatingBottom  production_table_hideFooter' style={{ height: "200px", width: "100%" }}>
                              <DataGrid
                                rows={FGtblcellClick ? FGstockClickcellData : FGstockClickData}
                                columns={FGtblcellClick ? FGstockCellClickDataCLM : FGstockClickDataCLM}
                                pageSize={4}
                                onCellClick={FGtblcellClick ? "" : handleDashFGtblcellClick}
                                getRowId={(row) => row.Item}
                                className={`${classes[companyColor + 'BG']}`}
                                {...(FGtblcellClick ? FGstockClickcellData : FGstockClickData)}
                              />
                            </div>
                          </>
                          : ""
                      }
  
                    </Grid>
                  </Grid>
  
  
                  <Grid item xs={4} className="text-end">
  
                    {/* <span > <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span> */}
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
            <div className={`card py-1 ${classes.row1Card}`}>
              <div className="card-body ">
                <h6 className={`card-title my-0 fw-bold`}>Total WIP Stock</h6>
                <Grid container item alignItems={"center"}>
                  <Grid item xs={10}>
                    <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                    </h4>
                    <Grid container spacing={5} className="pt-0" >
                      <Grid item xs={6} className="pt-0">
                        <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                        </ol>
                      </Grid>
                      <Grid item xs={6} className="pt-0">
                        <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                        </ol>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
            <div className={`card py-1 ${classes.row1Card}`}>
              <div className="card-body pb-0">
                <h6 className={`card-title my-0 fw-bold`}>Pending Production Orders</h6>
                <Grid container item alignItems={"center"}>
                  <Grid item xs={10}>
                    <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                    </h4>
                    <Grid container spacing={5} className="pt-0" >
                      <Grid item xs={6} className="pt-0">
                        <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                        </ol>
                      </Grid>
                      <Grid item xs={6} className="pt-0">
                        <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                          <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                        </ol>
                      </Grid>
                    </Grid>
                  </Grid>
  
  
                  {/* <Grid item xs={4}className="text-end">
                <span >
              <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span>
                  </Grid> */}
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
            <div className={`card py-1 ${classes.row1Card}`}>
              <div className="card-body pb-4">
                <h6 className={`card-title my-0 fw-bold`}>Total Production For The Period</h6>
                <Grid container item alignItems={"center"}>
                  <Grid item xs={10}>
                    {
                      ItemProducedClick ? "" : <h1 onClick={HandleItemProduced}>{ItemProducedcount}</h1>
                    }
                    <Grid container spacing={5} className="pt-0 withoutscroller" >
  
                      {
                        ItemProducedClick ?
                          <>
                            {IPtblcellClick ?
                              <p onClick={(e) => setIPtblcellClick(false)} style={{ marginLeft: '200px' }}>back</p>
                              :
                              <p onClick={handleCloseItemProducedClick} style={{ marginLeft: '200px' }}>×</p>
                            }
                            <div className='table animatingBottom  production_table_hideFooter' style={{ height: "250px", width: "100%" }}>
                              <DataGrid
                                rows={IPtblcellClick ? ItemProducedClickcellData : ItemProducedClickData}
                                columns={IPtblcellClick ? ItemProducedCellClickDataCLM : ItemProducedClickDataCLM}
                                pageSize={4}
                                onCellClick={IPtblcellClick ? "" : handleDashIPtblcellClick}
                                getRowId={(row) => row.Item}
                                className={`${classes[companyColor + 'BG']}`}
                                {...(IPtblcellClick ? ItemProducedClickcellData : ItemProducedClickData)}
                              />
                            </div>
                          </>
                          : ""
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
            <div className={`card py-1 ${classes.row1Card}`}>
              <div className="card-body pb-0">
                <h6 className={`card-title my-0 fw-bold`}>Items below ROL</h6>
                {ItemsBelowRol.length === 0 ? <p className={`${classes[companyColor]}`} >NO ITEM FOUND</p> : ""}
  
                <Grid container item alignItems={"center"}>
                  <Grid item xs={12}>
                    <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                    </h4>
                    <Grid container spacing={5} >
                      <Grid item xs={4} className="pt-0">
                        {
                          ItemsBelowRol.slice(0, 3)?.map((item, i) => (
                            <>
                              <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                              </ol>
                            </>
                          ))
                        }
  
                      </Grid>
                      <Grid item xs={4} className="pt-0">
                        {
                          ItemsBelowRol.slice(3, 6)?.map((item, i) => (
                            <>
                              <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                              </ol>
                            </>
                          ))
                        }
                      </Grid>
                      <Grid item xs={4} className="pt-0">
                        {
                          ItemsBelowRol.slice(6, 9)?.map((item, i) => (
                            <>
                              <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                              </ol>
                            </>
                          ))
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
            <div className={`card py-1 ${classes.row1Card}`}>
              <div className="card-body pb-4">
                <h6 className={`card-title my-0 fw-bold`}>Pending Sale Orders</h6>
                <Grid container item alignItems={"center"}>
                  <Grid item xs={12}>
                    <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                    </h4>
                    <Grid container spacing={0} >
                      <Grid item xs={12} className="pt-0" >
                        <div className='table animatingBottom  ' style={{ height: "250px", width: "100%" }}>
                          <DataGrid
                            rows={SaleOrderGRid}
                            columns={SaleOrderGRidCLM}
                            pageSize={4}
                            getRowId={(row) => row.DATED}
                            className={`${classes[companyColor + 'BG']}`}
                            {...SaleOrderGRid}
                          />
                        </div>
                      </Grid>
  
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
  
         {/* <div className={`categorybtn'  ${companyColor.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}style={{width:"100%"}}>
        <div className='categorydashboardbtn' style={{padding:"20px"}}>  
                   <Button variant='contained' className={`${classes[companyColor+'BG']}  my-1`}  style={{width:"33.33%", margin:"3px", color:"#fff"}} >Item Master </Button>
                  <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`}style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Price Stock Ledger</Button>
                  <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`} style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Stock Statement</Button>
                 
                  </div> 
        </div> */}
        <Grid container spacing={5} className="Graphdata" >
  
  
          <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
            <h6 className={`card-title my-0 fw-bold`}>Production Summary</h6>
            <div onResize={handleResize}>
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart data={data}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <br/>
          <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
            <h6 className={`card-title my-0 fw-bold`}>Production Summary</h6>
            <div onResize={handleResize}>
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart data={data}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Grid>
  
  

          <div className="d">
          </div>
        </Grid>
        <Grid container spacing={5} className="Graphdata" >
 
          <div className="d">
          </div>
        </Grid>
      </>
    );
  }
// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
    const array = new Array(length).fill();
    let lastValue;
  
    return array.map((item, index) => {
      let randomValue = Math.floor(Math.random() * multiplier + 1);
  
      while (
        randomValue <= min ||
        randomValue >= max ||
        (lastValue && randomValue - lastValue > maxDiff)
      ) {
        randomValue = Math.floor(Math.random() * multiplier + 1);
      }
  
      lastValue = randomValue;
  
      return { value: randomValue };
    });
  }
  
  function getMainChartData() {
    const resultArray = [];
    const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
    const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
    const mobile = getRandomData(31, 1500, 7500, 7500, 1500);
  
  
    for (let i = 0; i < tablet.length; i++) {
      resultArray.push({
        tablet: tablet[i].value,
        desktop: desktop[i].value,
        mobile: mobile[i].value,
      });
    }
  
    return resultArray;
  }