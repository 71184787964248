import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";
import axios from 'axios';
import ReportTbl from '../CollectionReport/ReportTbl';
import {useHistory} from 'react-router-dom';
import ComingSoonPopup from '../PopUP/PopUp';
import { COMPANY_CODE, GET_TRAIL_BALANCE_REPORT, GET_TRIAL_BALANCE_REPORT,GET_GROUP_SELECTION, GET_SALE_ORDER_STATUS, GET_ALL_WAREHOUSES, GET_PRICED_STOCK_LEDGER_ORDER_TYPE } from '../../../constant';

function SaleOrderStatus() {
    let history = useHistory();
    const classes = useStyles();
    const [companyColor,setcompanyColor] = useState("");
    const [ordertype,setordertype] = useState([]);
    const [checkedwarehouse, setcheckedwarehouse] = useState([]);
    const [trailbalancedata,settrailbalancedata] = useState([])
    const [isDataVisible, setIsDataVisible] = useState(false);
    const[groupselection,setgroupselection]=useState([]);
    const [checkedTypeItems, setCheckedTypeItems] = useState([]);
    const [rowData, setRowData] = useState([]);
    const[trialbalanceclm,settrialbalanceclm]=useState([]);
    const[popup,setpopup]=useState(false);
    const [warehouse, setwarehouse] = useState([]);
     
    useEffect(() => {
      // Define a function to fetch group selection data
      const fetchGroupSelection = () => {
        axios
          .post(GET_GROUP_SELECTION, { CompanyNumber: COMPANY_CODE })
          .then((response) => {
            console.log("GROUP SELECTION");
            console.log(response);
            if (response.data.IsSuccess === true) {
              console.log(response.data.Data);
              setgroupselection(response.data.Data);
            }
          });
      };
    
      // Define a function to fetch order type data
      const fetchOrderType = () => {
        axios
          .post(GET_PRICED_STOCK_LEDGER_ORDER_TYPE, { CompanyNumber: COMPANY_CODE })
          .then((response) => {
            console.log(" GET ORDER TYPE ");
            console.log(response);
            if (response.data.IsSuccess === true) {
              console.log(response.data.Data);
              setordertype(response.data.Data);
            }
          });
      };
    
      // Define a function to fetch all warehouses
      const fetchAllWarehouses = () => {
        axios
          .post(GET_ALL_WAREHOUSES, { CompanyNumber: COMPANY_CODE })
          .then((response) => {
            if (response.data.IsSuccess === true) {
              setwarehouse(response.data.Data);
            }
          });
      };
    
      // Call the functions to fetch data
      setcompanyColor(localStorage.getItem('cmpnyType'));
      fetchGroupSelection();
      fetchOrderType();
      fetchAllWarehouses();
    }, []); 
   
    // const LoadData = () => {
    //     setisLoading(true); 
    //     var categories =Array.from(document.querySelectorAll("#categorydata input:checked")).map((it) => it.value);
       
    //     console.log(categories);
    //   axios.post(GET_SALE_ORDER_STATUS,
    //     {
    //         "CompanyNumber": COMPANY_CODE,
    //         "FormDate": fromdate,
    //         "ToDate": todate,
    //         "Category":categories.join(','),
    //         "DocNo":"SALE",    
    //         "Detailed":1,    
    //         "Summarise":1,    
    //         "BillDate":1,    
    //         "Ledger":0,    
    //         "Paytm":0,   
    //         "POGeneration":0,   
    //         "strWh":"MS01", 
    //         // "strWh":checkedwarehouse.join(','),   
    //         "Period":"",    
    //         "Item":"",     
    //         "Type":"",    
    //         "Bill":""
        
    //     }
    //     )
    //     .then((response) => {
    //         console.log("testing data",response)
    //         if (response.data.IsSuccess === true) {
    //           var  mydata = response.data.Data?.map((item,i)=>({...item}))
    
    //           var obj ={}
    //           var saleHeader =[]
    //           var keys = Object.keys(mydata[0])
    //         keys.forEach((data,i)=>{
    //          var newobject = { field: data, headerName: data, width: 150 }
    
    //          saleHeader.push(newobject)
    //         })
    //           settrialbalanceclm(saleHeader);
    //           setRowData(mydata); 
    //         } 
    //           setisLoading(false); 
    //         })
    //       .catch((error) => {
    //         console.error('Error fetching data:', error);
      
    //       });
    //     };
    const LoadData = () => {
      setisLoading(true);
    
      axios
        .post(GET_SALE_ORDER_STATUS, 
          {
          CompanyNumber: COMPANY_CODE,
          FormDate: fromdate,
          ToDate: todate,
          DocNo: "SALE",
          Detailed: 1,
          Summarise: 1,
          BillDate: 1,
          Ledger: 0,
          Paytm: 0,
          POGeneration: 0,
          // strWh: "MS01",
          strWh:checkedwarehouse.join(','), 
          Period: "",
          Item: "",
          Type: "",
          Bill: "",
        }
        )
        .then((response) => {
          console.log("testing data", response);
          if (response.data.IsSuccess === true) {
            const mydata = response.data.Data.map((item, i) => ({ ...item }));
    
            const saleHeader = [];
            const keys = Object.keys(mydata[0]);
            keys.forEach((data, i) => {
              const newobject = { field: data, headerName: data, width: 150 };
              saleHeader.push(newobject);
            });
            settrialbalanceclm(saleHeader);
            setRowData(mydata);
          }
          setisLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setisLoading(false); // Make sure to set isLoading to false in case of an error.
        });
    };
    

       /** GROUP SELECTION */
  
    var today = new Date().toISOString().slice(0, 10); 
    var toda = new Date();
    var tomorrow = new Date(toda);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var nextday = tomorrow.toISOString().slice(0, 10);
    const [fromdate, setfromdate] = useState(today)
    const [todate, settodate] = useState(nextday)
  
    const [TrailBalance,setTrailBalance] = useState([]);
    const [isLoading,setisLoading] = useState(false)

    const TrailBalanceRow =[
        { field: 'Classification', headerName: 'Classification', width: 200   },
        { field: 'Code', headerName: 'Code', width: 150 },
        { field: 'Account', headerName: 'Account', width: 150 },
        { field: 'Opening Dr', headerName: 'Opening Dr', width: 150 },
        { field: 'Opening Cr', headerName: 'Opening Cr', width: 150 },
        { field: 'Period Dr', headerName: 'Period Dr', width: 150 },
        { field: 'Closing Dr', headerName: 'Closing Dr', width: 150 },
        { field: 'Closing Cr', headerName: 'Closing Cr', width: 150 },
        {
            field: "FormDate",
            headerName: "Form Date",
            width: 180,
            disableClickEventBubbling: true,
            renderCell: (params) => {
      
              return (
                params.row.FormDate?.replace("T" , " ")
      
              );
            }
          },
    ]

    const goHome =()=>{
        history.push('/dashboard')
      }
      const openpop =()=>{
        setpopup(true);
       }
       const closePopup = () => {
         setpopup(false);
       };
       
       const handleWarehouseCheckboxChange = (e, item) => {
        const isChecked = e.target.checked;
        setcheckedwarehouse((prevChecked) => {
          if (isChecked) {
            return [...prevChecked, item.Type];
          } else {
            return prevChecked.filter((selectedItem) => selectedItem !== item.Type);
          }
        });
      };

      const handleTypeCheckboxChange = (e, item) => {
        const isChecked = e.target.checked;
        setCheckedTypeItems(prevState => {
          if (isChecked) {
            return [...prevState, item];
          } else {
            return prevState.filter(selectedItem => selectedItem.BCD !== item.BCD);
          }
        });
      };
    return (
      <>
       {
            isLoading?  <div className='Commonloader'>
            </div>:null
          }
           {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
          <div className="balacepoupformmain trailBalance_main" >
            <div className="balacepoupformleft allfont">

                {/* From Date   <->  To Date */}
                <div className="row">
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Period From &nbsp;&nbsp; </p>
                            <input type="date" className="" id="FDate" name="FormDate" value={fromdate} onChange={(e)=>setfromdate(e.target.value)} />

                        </div>
                    </div>
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">To&nbsp;&nbsp; </p>
                            <input type="date" className="" id="TDate" name="ToDate" value={todate}  onChange={(e)=>settodate(e.target.value)}/>

                        </div>
                    </div>
                 
                </div>
                {/* opening year */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Reported By</p>
                        </div>
                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="form-check form-check-inline">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="inlineRadioOptions" value="" defaultChecked={true} />Order Date
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="form-check form-check-inline">
                            <input type="radio" className="form-check-input" id="" name="inlineRadioOptions" value="Selected" />Required By

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
                {/* Report Format */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Report For</p>
                        </div>
                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="inlineRadioOption" value="" defaultChecked={true}  />Detail
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="inlineRadioOption" value="Selected" />Summary
                            </li>
                        </div>
                    </div>
                   
                </div>
                
                {/* statement print sequence */}
                <div className="row" style={{ padding: '5px' }}>
                    <div className="col-sm-2 col-2 col-md-2 ">
                        <div className="row indent">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Order Status</p>
                        </div>
                    </div>
                    <div className="col-sm-10 col-10 col-md-10">
                        <div className="row insideRow">
                            <div className="col-sm-3 col-3 col-md-3 ">
                                <input type="radio" className="form-check-input" id="" name="sales_off" value="" defaultChecked={true} />&nbsp;All
                            </div>
                            <div className="col-sm-4 col-4 col-md-4">
                                <input type="radio" className="form-check-input" id="" name="sales_off" value="Selected" />&nbsp;Approval Pending
                            </div>
                            <div className="col-sm-5 col-5 col-md-5">
                                <input type="radio" className="form-check-input" id="" name="sales_off" value="Selected" />&nbsp;Approved Only
                            </div>
                        </div>
                        <div className="row insideRow">
                        <div className='col-sm-6 col-6 col-lg-6'>
                        <input type="checkbox" className="form-check-input" id="" name="Print_Account_Code" value="" />&nbsp;Open Order

                    </div>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        <input type="checkbox" className="form-check-input" id="" name="Print_Account_Code" value="" />&nbsp;Short Close

                    </div>
                        </div>
                        <div className="row insideRow">
                        <div className='col-sm-6 col-6 col-lg-6'>
                        <input type="checkbox" className="form-check-input" id="" name="Print_Account_Code" value="" />&nbsp;Closed

                    </div>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        <input type="checkbox" className="form-check-input" id="" name="Print_Account_Code" value="" />&nbsp;Cancelled
                    </div>
                        </div>
                    </div>
                </div>
                <div className="row PricedStockLedger_2">
  <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">
    Select Warehouse
  </p>
  <div className="col-sm-12 col-12 col-md-12">
    <div className="scrollerDiv">
      {warehouse.map((item, index) => (
        <div key={index}>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`warehouseCheckbox${index}`}
              name={`warehouseCheckbox${index}`}
              value={item.Type}
              checked={checkedwarehouse.includes(item.Type)}
              onChange={(e) => handleWarehouseCheckboxChange(e, item)}
            />
            <label
              className="form-check-label text-dark my-1"
              htmlFor={`warehouseCheckbox${index}`}
            >
              &nbsp; {item.Type}
            </label>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>
              {/* <div className="row">
                 <div className="mt-1 mb-1 Stockstatement_2grid">
                 <span>Warehouse &nbsp;</span>
                    <select  style={{width:'73%'}} >
                    <option   value="">Select Warehouse</option>
                    </select>   
                    </div>
                    </div>  */}
                <div className='row text-center'>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        Order Series
                    </div>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        Transaction Types
                    </div>
                </div>
                <div className='col-sm-12 col-12 col-lg-12'>
                 <div className='row text-center'>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        <div className='groupsSelection' id="categorydata">
                        <br />
                        {groupselection.map(item => (
                            <li key={item.BCD}>
                            <input
                             type="checkbox" 
                            id={item.BCD} 
                            name={item.BCD}
                             value={item.BCD} 
                             />
                           &nbsp; {item.DESCRIPTION}
                            </li>
                        ))}
                        </div>
                   </div>
                   <div className="col-sm-6 col-6 col-md-6">
                <p className="form-check-label text-dark my-1" for="inlineRadio2"></p>
                <div className="scrollerDiv">
    {ordertype.map((item, index) => (
      <div key={index}>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`inlineCheckboxType${index}`}
            name={`orderTypeCheckbox${index}`}

            value={item.BCD}
            onChange={(e) => handleTypeCheckboxChange(e, item)}
          />
          <label className="form-check-label text-dark my-1" htmlFor={`inlineCheckboxType${index}`}>
          &nbsp; {item.DESCRIPTION}
          </label>
        </div>
      </div>
    ))}
  </div>
                
              </div>
                    {/* <div className='col-sm-6 col-6 col-lg-6'>
                        <div className='groupsSelection' id="categorydata">
                        <br />
                        {groupselection.map(item => (
                            <li key={item.BCD}>
                            <input
                             type="checkbox" 
                            id={item.BCD} 
                            name={item.BCD}
                             value={item.BCD} 
                             />
                           &nbsp; {item.DESCRIPTION}
                            </li>
                        ))}
                        </div>
                    </div> */}
                    </div>
                    
                    </div>
                {/* Print Account Code*/}
                <div className='row text-center'>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        Account Category
                    </div>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        Item Category
                    </div>
                </div>
                <div className='col-sm-12 col-12 col-lg-12'>
                 <div className='row text-center'>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        <div className='groupsSelection' id="categorydata">
                        <br />
                        {groupselection.map(item => (
                            <li key={item.BCD}>
                            <input
                             type="checkbox" 
                            id={item.BCD} 
                            name={item.BCD}
                             value={item.BCD} 
                             />
                           &nbsp; {item.DESCRIPTION}
                            </li>
                        ))}
                        </div>
                   </div>
                    <div className='col-sm-6 col-6 col-lg-6'>
                        <div className='groupsSelection' id="categorydata">
                        <br />
                        {groupselection.map(item => (
                            <li key={item.BCD}>
                            <input
                             type="checkbox" 
                            id={item.BCD} 
                            name={item.BCD}
                             value={item.BCD} 
                             />
                           &nbsp; {item.DESCRIPTION}
                            </li>
                        ))}
                        </div>
                    </div>
                    </div>
                    
                    </div>
                    <div className="row">
                 <div className="mt-3 Stockstatement_2grid">
                 <span style={{ color: '#000' }}>Specific Party &nbsp;</span>
                    <select  style={{width:'72%'}} >
                    <option value=""></option>
    
                    </select>   
                    </div>
                    </div>
                    <div className="row">
                 <div className="mt-3 Stockstatement_2grid">
                 <span style={{ color: '#000' }}>Specific Item &nbsp;</span>
                    <select  style={{width:'73%'}} >
                    <option value=""></option>
    
                    </select>   
                    </div>
                    </div> 
                <div className='row trialbalance' style={{position:'sticky',bottom:"0px"}}>
                <div className="d-flex mt-2">
                <div className='col '> <Button variant="contained" className={classes[companyColor?.split(',')[0]]}onClick={openpop} >EXCEL</Button></div>
                <div className='col trail'>  <Button variant='contained' className={classes[companyColor?.split(',')[0]]}
                 onClick={LoadData} 
                 >VIEW</Button></div>
                <div className='col trail'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]}onClick={openpop}  >PDF</Button></div>
                <div className='col trail'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={goHome} >EXIT</Button></div>
                </div>
              
                    
                </div>

            </div>
           
            <div className="balacepoupformright">
                {trialbalanceclm.length > 0 ? (
            <ReportTbl
            dataArray={rowData}
            TblColumns={trialbalanceclm}
            headerColor={companyColor}
            
            />
        ) :(
            <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
              <thead className={classes[companyColor?.split(',')[0]]}> 
                <tr>
                  <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>
        
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
                
                </tr>
              </tbody>
            </table>
          )}
        </div>
        
        </div>
      </>
    );
}

export default SaleOrderStatus;