import React, { useEffect, useState } from "react";
import {
  Grid,Select,
  TextField ,Input, 
  Button,InputLabel ,
  RadioGroup,FormControlLabel,
  Radio,
  Checkbox
} from "@material-ui/core";
import useStyles from "../../styles";
import { DataGrid } from "@mui/x-data-grid";
export default function Form(props) {
  const [formdetails,setformdetails] = useState([])
  const [tabledata,settabledata] = useState([])
  const [headername,setheadername] = useState("")
    const classes = useStyles();

 const tableclm =[
      { field: 'ITEM CODE', headerName: 'ITEM CODE', width: 160   },
      { field: 'ITEM', headerName: 'ITEM', width: 160   },
      { field: 'QTY', headerName: 'QTY', width: 160   },
      { field: 'RATE', headerName: 'RATE', width: 160   },
      { field: 'TAX INCL.', headerName: 'TAX INCL.', width: 160   },
      { field: 'TAX %', headerName: 'TAX %', width: 160   },
      { field: 'topClientName', headerName: 'LEAD TIME', width: 160   },
      { field: 'topClientName', headerName: 'CEDIT DAYS', width: 160   },
    ]

    const balanceclm =[
      { field: 'DATE', headerName: 'DATE', width: 160   },
      { field: 'VOUCHER', headerName: 'VOUCHER', width: 160   },
      { field: 'NARRATION', headerName: 'NARRATION', width: 160   },
      { field: 'DEBIT', headerName: 'DEBIT', width: 160   },
      { field: 'CREDIT', headerName: 'CREDIT', width: 160   },
      { field: 'CURRENCY', headerName: 'CURRENCY', width: 160   },
      { field: 'BALANCE', headerName: 'BALANCE', width: 160   },
      { field: 'CURRENCY AMOUNT', headerName: 'CURRENCY AMMOUNT', width: 160   },
    ]

    const liveEnquirylm =[
      { field: 'ITEM CODE', headerName: 'PARTY', width: 100   },
      { field: 'ITEM', headerName: 'ITEM ', width: 60   },
      { field: 'VARIENT', headerName: 'VARIENT', width: 60   },
      { field: 'MACHINE', headerName: 'MACHINE', width: 60   },
      { field: 'SPECIFICIAL', headerName: 'SPECIFICIAL', width: 60   },
      { field: 'REQUIRED', headerName: 'REQUIRED', width: 60   },
      { field: 'UOM', headerName: 'UOM', width: 60   },
      { field: 'UNIT RATE', headerName: 'UNIT RATE', width: 60   },
      { field: 'AMOUNT', headerName: 'AMOUNT', width: 60   },
      { field: 'DELIVERY BY', headerName: 'DELIVERY BY', width: 60   },
      { field: 'STATUS', headerName: 'STATUS', width: 60   },
      { field: 'PROCESSE QTY', headerName: 'PROCESSE QTY', width: 60   },
      { field: 'QOH', headerName: 'QOH', width: 60   },
      { field: 'ROWPOS', headerName: 'ROWPOS', width: 60   },
      { field: 'IICODE', headerName: 'IICODE', width: 60   },
      { field: 'APPROVE', headerName: 'APPROVE', width: 60   },
      { field: 'APPROVED', headerName: 'APPROVED', width: 60   },
      { field: 'DATED', headerName: 'DATED', width: 60   },
      { field: 'CC', headerName: 'CC', width: 60   },
      { field: 'DEPT', headerName: 'DEPT', width: 60   },
      { field: 'REMARKS', headerName: 'REMARKS', width: 60   },
      { field: 'INCREMENT', headerName: 'INCREMENT', width: 60   },
    ]

useEffect(()=>{
console.log("rows",props.rows)
setformdetails(props.rows)
console.log(props.header)
setheadername(props.header)
},[])

    return (
        <>
        {/* <p>Pending RFQs</p> */}
        <span className="form-heading">{headername}</span>
        <Grid className={classes.formContainter} >
          
      {
        headername == "Pending RFQs" ? 
        <>
         {
          formdetails.map((item,index)=>(
            <>
           <Grid container spacing={4} key={index} className="pendinrfq-details" style={{textAlign:'center'}}>
          
          <Grid item lg={4} md={4} sm={6} xs={12}>
         <h4>PARTY</h4>
          <p >{item.topClientName}</p>

          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
          <h4>INDENT NO.</h4>
          <p>{item.EnquirisOrderNumber}</p>

          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
          <h4>CLIENT QUOTATION NO.</h4>
         <p>{item.RequestNO}</p>
          </Grid>
          </Grid> 
        
              </>
          ))
        }
       <br />
        <div className='table animatingBottom' style={{height:"380px",width:"100%"}}>
      <DataGrid
      rows={tabledata}
      columns={tableclm}
      pageSize={4}
    
      // getRowId={(row) => row.RequestNO}
    
      //   onSelectionModelChange={(ids) => {
      //     const selectedIDs = new Set(ids);
      //     const selectedRows = tabledata.filter((row) =>
      //       selectedIDs.has(row.RequestNO),
         
      //     );
          
      //     console.log(selectedRows)                                                     
      //     setSelectedRows(selectedRows);
      //     // setfirstname(selectedRows.mcode)

      //   }}
        {...tabledata}
      />
      </div>
        </>
        :""
      }
      {
        headername =="Current Live Enquiries" ? 
        <>
         {
          formdetails.map((item,index)=>(
            <>
           <Grid container spacing={4} key={index} className="pendinrfq-details" style={{textAlign:'center'}}>
          
          <Grid item lg={4} md={4} sm={6} xs={12}>
         <h4>PARTY</h4>
          <p >{item.topClientName}</p>

          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
          <h4>INDENT NO.</h4>
          <p>{item.EnquirisOrderNumber}</p>

          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
          <h4>CLIENT QUOTATION NO.</h4>
         <p>{item.RequestNO}</p>
          </Grid>
          </Grid> 
          
          <Grid container spacing={4} key={index} className="pendinrfq-details" style={{textAlign:'center'}}>
          
          <Grid item lg={4} md={4} sm={6} xs={12}>
         <h4>DATE</h4>
          <p >{item.topClientName}</p>

          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
          <h4>PROJECT</h4>
          <p>{item.EnquirisOrderNumber}</p>

          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
          <h4>INDENT REMARKS</h4>
         <p>{item.RequestNO}</p>
          </Grid>
          </Grid> 
        
              </>
          ))
        }
        <br/>
        <div className='table animatingBottom' style={{height:"380px",width:"100%"}}>
      <DataGrid
      rows={tabledata}
      columns={liveEnquirylm}
      pageSize={4}
    
      // getRowId={(row) => row.RequestNO}
    
      //   onSelectionModelChange={(ids) => {
      //     const selectedIDs = new Set(ids);
      //     const selectedRows = tabledata.filter((row) =>
      //       selectedIDs.has(row.RequestNO),
         
      //     );
          
      //     console.log(selectedRows)                                                     
      //     setSelectedRows(selectedRows);
      //     // setfirstname(selectedRows.mcode)

      //   }}
        {...tabledata}
      />
      </div>
        </>
        :""
      }
       {
        headername =="Debitor Balance" || headername == "Creditor Balance" ? 
        <>
      <div className="balacepoupformmain">
      <div className="balacepoupformleft">
        <div className="left-blankspace"></div>
        <div className="checkboxes-d-flex">
          <div>
                  <Checkbox label="Rate" defaultChecked size="small" />
                  <span style={{color:'#111'}}>Account To Account</span>
                 <p style={{color:'#fff'}}>FROM</p>
                 <p style={{color:'#fff'}}>TO</p>
                 <p style={{color:'#fff',height:'120px'}}>Selected Accounts</p>
                 <p style={{color:'#fff'}}>From<span><input type="date" /></span> </p>
                
          
          </div>
          <div>
                 <Checkbox label="Rate" defaultChecked size="small" />
                 <span style={{color:'#111'}}>Selected Account</span>
                 {
                  formdetails.map((item,index)=>(
                    <>
                    <p style={{background:'#fff'}} >{item.topClientName}</p>
                    <p style={{background:'#fff'}} >vibhav</p>
                    </>
                  ))
                 }
               
                <ul style={{background:'#fff'}} >
                <li >
                <input type="checkbox" id="one" name="sales_officer" value="" />vibhav
               </li>
               <li >
                <input type="checkbox" id="one" name="sales_officer" value="" />vibhav
               </li>
               <li >
                <input type="checkbox" id="one" name="sales_officer" value="" />vibhav
               </li>
               <li >
                <input type="checkbox" id="one" name="sales_officer" value="" />vibhav
               </li>
               <li >
                <input type="checkbox" id="one" name="sales_officer" value="" />vibhav
               </li>
                </ul>
                <p style={{color:'#fff'}}>To<span><input type="date" /></span> </p>
          </div>
        </div>
        {
          formdetails.map((item,index)=>(
          <center style={{color:'#111',fontWeight:'900'}}>{item.topClientName}</center>
          ))
        }
        
        <br />
        <Checkbox label="Rate" defaultChecked size="small" />
         <span style={{color:'#111'}}>Report In Local Currency</span>
         <br/>
         <Button variant="contained">NEXT</Button>
         <br/> 
         <div className="d-flex mt-2" style={{width:'89%'}}>
         <Button variant="contained">EXCEL</Button>
          <Button variant="contained">VIEW</Button>
          <Button variant="contained">EXIT</Button>
         </div>

        </div>
        <div className="balacepoupformright"> 
        <div className='table animatingBottom' style={{height:"570px",width:"100%"}}>
      <DataGrid
      rows={tabledata}
      columns={balanceclm}
      pageSize={4}
    
      // getRowId={(row) => row.RequestNO}
    
      //   onSelectionModelChange={(ids) => {
      //     const selectedIDs = new Set(ids);
      //     const selectedRows = tabledata.filter((row) =>
      //       selectedIDs.has(row.RequestNO),
         
      //     );
          
      //     console.log(selectedRows)                                                     
      //     setSelectedRows(selectedRows);
      //     // setfirstname(selectedRows.mcode)

      //   }}
        {...tabledata}
      />
      </div>
      </div>
      </div>
        </>
        :""
      }
      
      
            
          </Grid>
        </>
      );
}




















// ----------  grid inputs for later


    {/* <Grid container spacing={4}   className="poinvoiceform">
     
      <TextField id="outlined-basic" label="Rate" variant="outlined" size="small" />
      <TextField id="outlined-basic" label="Lead Time" variant="outlined" size="small" />
      <TextField id="outlined-basic" label="Tax in (%)" variant="outlined" size="small" />
      <TextField id="outlined-basic" label="credit Day" variant="outlined" size="small" />
      <span style={{color:'#111'}}>Tax Inclusive</span>
      <Checkbox label="Rate" defaultChecked size="small" />
      
          </Grid> */}