import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import useStyles from "../PartyStockLedger/style";
import { COMPANY_CODE, GET_ACCOUNT_CLASSIFICATION_REPORT, GET_ALL_CATEGORIES, GET_VIEW_ACCOUNT_REPORT } from '../../../constant';
import axios from 'axios';
import ReportTbl from '../CollectionReport/ReportTbl';
import { Category } from '@material-ui/icons';
import ComingSoonPopup from '../PopUP/PopUp';
import { useFinancialYear } from "../FinancialYearContext";


function AccountStatus() {
  const classes = useStyles();

  const history = useHistory();
  const { financialYear } = useFinancialYear();

  var today = new Date();
  var currentMonth = today.getMonth();
  const [isLoading, setisLoading] = useState(false);
  var currentYear = today.getFullYear();
  var financialYearStart = new Date(currentYear - 1, 3, 1); 
  var financialYearEnd = new Date(currentYear, 2, 31); 
  var financialYearStartFormatted = financialYearStart.toISOString().slice(0, 10);
  var financialYearEndFormatted = financialYearEnd.toISOString().slice(0, 10);  
  const [fromdate, setfromdate] = useState(financialYear.financialYearStart);
  const [todate, settodate] = useState(financialYear.financialYearEnd);
  const [salesummaryclm, Setsalesummaryclm] = useState([]);
  const [acconutstatus, setacconutstatus] = useState([]);
  const [companyColor, setcompanyColor] = useState("")
  const [AllCategories,SetAllCategories] =useState([])
  const [selectedValue, setSelectedValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const[popup,setpopup]=useState(false);
 useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
    //API FOR GET ALL CATEGORIES
    axios.post(GET_ACCOUNT_CLASSIFICATION_REPORT, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
      console.log("account dropdown data")
      console.log(response)
      if (response.data.IsSuccess === true) {
        // console.log(  "Account Classification DD" , response.data.Data)

var newData = response.data.Data;

// var newObj = {Type:'All' , Category:'ALL'}
// newData.push(newObj)
// console.log(newData)

        SetAllCategories(newData);
      }
      else {
        SetAllCategories([]);
      }
    })

  }, [])

  const acconutstatusclm = [
    { field: 'Account Name', headerName: 'Account Name', width: 150 },
    { field: 'Category', headerName: 'Category', width: 150 },
    {
      field: "CompanyNumber",
      headerName: "Company Number",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.CompanyNumber === null ? COMPANY_CODE : params.row.CompanyNumber

        );
      }
    },
    { field: 'CreatedDate', headerName: 'Created Date', width: 150 },
    { field: 'Credit', headerName: 'Credit', width: 150 },
    { field: 'CURRENCY', headerName: 'Currency', width: 150 },
    { field: 'Dabit', headerName: 'Debit', width: 150 },
    { field: 'FormDate', headerName: 'Form Date', width: 150 },
    { field: 'Mcode', headerName: 'Mcode', width: 150 },
    { field: 'ToDate', headerName: 'To Date', width: 150 },
    { field: 'chkLocalCurrency', headerName: 'Local Currency', width: 150 },
    { field: 'classification', headerName: 'Classification', width: 150 },
    { field: 'closingCredit', headerName: 'Closing Credit', width: 150 },
    { field: 'closingDabit', headerName: 'Closing Debit', width: 150 },
    { field: 'openingCredit', headerName: 'Opening Credit', width: 150 },
    { field: 'openingDabit', headerName: 'Opening Debit', width: 150 },
  ]


  const loadData = () => {
    setisLoading(true);
    // Clear the existing columns
    Setsalesummaryclm([]);
  
    axios.post(GET_VIEW_ACCOUNT_REPORT, {
      "CompanyNumber": COMPANY_CODE,
      "FormDate": fromdate,
      "ToDate": todate,
      "chkLocalCurrency": isChecked,
      "Category": selectedValue
    }).then((response) => {
      setisLoading(false);
      console.log(response)
      if (response.data.IsSuccess === true) {
        setacconutstatus(response.data.Data);
        const keys = Object.keys(response.data.Data[0]);
        keys.map((data, i) => {
          if (data !== "Classification" && data !== "MCODE" && data !== "Id" && data !== "Sl No" ) {
            let obj = {};
            if (data === "Date") {
              obj = {
                field: "data",
                width: 200,
                disableClickEventBubbling: true,
                renderCell: (params) => (
                  <p className='mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                )
              };
            } else {
              obj = { "field": data, "headerName": data, width: 250 };
            }
            // Append the new column to salesummaryclm
            Setsalesummaryclm(salesummaryclm => [...salesummaryclm, obj]);
          }
        });
      } else {
        setacconutstatus([]);
      }
    });
  };
  



  const goHome =()=>{
    history.push('/dashboard')
  }
  const openpop =()=>{
    setpopup(true);
   }
   const closePopup = () => {
     setpopup(false);
   };
   
  return (
    <>
    {
      isLoading ? <div className='Commonloader'>
      </div> : null
    }
     {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
    <div className="balacepoupformmain " >
      <div className="accountStatusform" style={{ color: '#0b5aa4', paddingTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>

        <div className='accountStatusform'>
          <div >
            <span >Account Classification </span>
            <select style={{ width: '170px' }} onChange={(e) => setSelectedValue(e.target.value)}>
            <option value='All'>ALL</option>
              {
                AllCategories?.reverse().map((item,i)=>(
                   <option value={item.Type}>{item.Category}</option>
                ))
              }
            </select>

          </div>
          <div className='accountStatusform_secondgrid' >
            <p >Financial Year : <span>
              <input type="date" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
              <input type="date" value={todate} onChange={(e) => settodate(e.target.value)} />
            </span> </p>

          </div>
          <div>
            <div className='accontstatus_checkbox'>
            <input type="checkbox" style={{ width:'20px', height:'20px', marginTop:'3px' }} id="one" name="sales_officer" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}/>
            <span className='accountStatusform_secondgrid_span' style={{ fontSize: '12px', marginLeft:"5px", marginBottom:'4px' }}>Report in Local Currency</span>
          </div></div>
          <div className="d-block collectionreportbtns" style={{ justifyContent: 'space-between', width: '170px', marginTop:'-5px' }}>
            <button type="button" className={` accountStatusformbtn ${classes[companyColor?.split(',')[0]]}  my-1`} onClick={loadData}>LOAD DATA</button>

          </div>
        </div>
      </div>


      {/* <div className='table animatingBottom whiteheader' style={{ height: "500px", width: "100%", background: '#fff' }}>
        <DataGrid
          rows={acconutstatus}
          columns={salesummaryclm}
          pageSize={7}
          className={classes[companyColor?.split(',')[0]]}
          getRowId={(row) => row.MCODE}
          {...acconutstatus}
        />
      </div> */}
   
   <div className='accountstatustbl'>
  {salesummaryclm.length > 0 ? (
    <ReportTbl dataArray={acconutstatus} TblColumns={salesummaryclm} headerColor={companyColor} />
  ) : (
    <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
      <thead className={classes[companyColor?.split(',')[0]]}> 
        <tr>
          <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>

        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
        
        </tr>
      </tbody>
    </table>
  )}
</div>
<div className='stockstatsbtn' >
                <div className='col ' >
                 <div className='stockstatusbutton' style={{float:'left', marginBottom:"10px"}} >  
                 <Button variant='contained' className={classes[companyColor?.split(',')[0]]}>Search </Button>&nbsp;
                <Button variant="contained" className={classes[companyColor?.split(',')[0]]}  onClick={openpop}>Excel</Button>&nbsp;
                <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop} >PDF</Button>&nbsp;
                <Button variant="contained" className={classes[companyColor?.split(',')[0]]}  >Cancel</Button>&nbsp;
                <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={goHome}  >Exit</Button>
                </div> 
                </div>
            </div>

    </div>
    </>
  );
}
export default AccountStatus;