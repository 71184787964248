import React from "react";
import ReactDOM from "react-dom";
import { Line } from "react-chartjs-3";

const ref = React.createRef();

function Linechart() {
  const options = {
    orientation: "landscape",
    unit: "in",
    format: "a4"
  };
 

  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July","August","September","October","November","December"],
    datasets: [
      {
        label: "Line 1",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)"
      },
      {
        label: "Line 2",
        data: [28, 48, 40, 19, 86, 27, 100],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)"
      },
      {
        label: "Line 3",
        data: [45, 25, 16, 36, 67, 18, 86],
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)"
      }
    ]
  };

  return (
    <div className="">
 
      <div ref={ref}>
        
        <h2>Start !</h2>
        <Line
          data={data}
          width={100}
          height={20}
          options={{
            maintainAspectRatio: false
          }}
        />
      </div>
    </div>
  );
}

export default Linechart;
