import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  showMore: {
  cursor:"pointer"
  },
  formContainter:{
    // backgroundImage: "linear-gradient(96deg, #526bf726, #f54b4b21)",
    background:"#c1c1c140",
    paddingLeft:theme.spacing(3),
    paddingRight:theme.spacing(3),
    paddingTop:theme.spacing(1),
    paddingBottom:theme.spacing(4),
  },
AccInput:{
  height: "32px",
},
AccCheckboxDiv:{
  height: "160px",
  overflow: "scroll",
  background: "#fcfcfc",
  color: "black",
} ,
btnForLedger:{
  background:"#0b5aa4",
  color:"white",
},
restaurant: {
  backgroundColor: theme.palette.primary.Rastorant_primary,
  color: theme.palette.primary.whiteColor,
},
pos: {
  backgroundColor: theme.palette.primary.POS_primary,
  color: theme.palette.primary.whiteColor,
},
production: {
  backgroundColor: theme.palette.primary.Production_primary,
  color: theme.palette.primary.whiteColor,
},
order_management: {
  backgroundColor: theme.palette.primary.OrderMngmt_primary,
  color: theme.palette.primary.whiteColor,
},
}
));
