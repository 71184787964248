import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react'
import useStyles from "../PartyStockLedger/style";
import axios from 'axios';
import ComingSoonPopup from '../PopUP/PopUp';
import Chart from 'chart.js';
import { useHistory } from 'react-router-dom';
import ReportTbl from '../CollectionReport/ReportTbl';
import { GET_FSN, COMPANY_CODE, GET_GROUP_SELECTION, GET_ALL_WAREHOUSES, } from '../../../constant';

function FsnAnalysis() {
  const history = useHistory();
  const[popup,setpopup]=useState(false);
  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(today);
  const [enddate, setenddate] = useState(nextday);
  const classes = useStyles();
  const [companyColor, setcompanyColor] = useState("")
  const [warehouse, setwarehouse] = useState([]);
  const [getcategory, setgetcategory] = useState([]);
  const [checkedwarehouse, setcheckedwarehouse] = useState([]);
  const [checkedTypeItems, setCheckedTypeItems] = useState([]);
  const [checkedCategoryItems, setCheckedCategoryItems] = useState([]);
  const [fsnclm, setfsnclm] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [selectedWarehouseData, setSelectedWarehouseData] = useState([]);
  const chartRef = useRef();


    // Function to create the line chart
    const createLineChart = (companyTypeColor) => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
    
        // Generate labels for the last 6 months (including the current month)
        const currentDate = new Date();
        const labels = [];
        for (let i = 5; i >= 0; i--) {
          const date = new Date(currentDate);
          date.setMonth(date.getMonth() - i);
          const month = date.toLocaleString('default', { month: 'long' });
          labels.push(month);
        }
    
        // Replace the labels and data with your dynamic data
        new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Dummy Data',
                data: [10, 20, 15, 25, 30, 35],
                borderColor: companyTypeColor,
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        });
      }
    };

  const handleWarehouseCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    setcheckedwarehouse((prevChecked) => {
      if (isChecked) {
        return [...prevChecked, item.Type];
      } else {
        return prevChecked.filter((selectedItem) => selectedItem !== item.Type);
      }
    });
    // Update the selected warehouse data
    if (isChecked) {
      setSelectedWarehouseData((prevData) => [...prevData, item]);
    } else {
      setSelectedWarehouseData((prevData) => prevData.filter((selectedItem) => selectedItem.Type !== item.Type));
    }
  };
  const handlestockButtonClick = () => {
    const selectedCategoryFilters = checkedCategoryItems?.map(item => item.DESCRIPTION);
    setisLoading(true);
    var categories = Array.from(document.querySelectorAll("#categorydata input:checked")).map((it) => it.value);
    console.log("data category",categories.join(','))
    // Include selected warehouse data in the axios request
    const selectedWarehouses = selectedWarehouseData.map(item => item.Type);

    axios.post(GET_FSN, {
      "CompanyNumber": COMPANY_CODE,
      "strWh": selectedWarehouses.join(','),
      "Category": categories.map(item => `'${item}'`).join(','),

      "Id": 12,
    })
      .then((response) => {
        console.log("FSN response", response)
        if (response.data.IsSuccess === true) {
          var mydata = response.data.Data?.map((item, i) => ({ ...item }))

          var obj = {}
          var saleHeader = []
          var keys = Object.keys(mydata[0])
          keys.forEach((data, i) => {
            var newobject = { field: data, headerName: data, width: 150 }

            saleHeader.push(newobject)
          })
          setfsnclm(saleHeader);
          setRowData(mydata);
        }
        else{
          setfsnclm([]);
          setRowData([]);

        }
        setisLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {

    /**all warehouses dropdown */
    axios.post(GET_ALL_WAREHOUSES,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {

        if (response.data.IsSuccess === true) {
          setwarehouse(response.data.Data)

        }
      })

    // get CATEGORY
    axios.post(GET_GROUP_SELECTION,
      { "CompanyNumber": COMPANY_CODE })
      .then((response) => {
        console.log(" GET CATEGORY ")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setgetcategory(response.data.Data)

        }
      })
  }, [])
  useEffect(() => {
 // Get the company type color from localStorage
 const companyTypeColor = localStorage.getItem('cmpnyType');
 setcompanyColor(companyTypeColor);
    // Call the createLineChart function when the component mounts
    createLineChart(companyTypeColor);
  }, []);
  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
    createLineChart();
  }, [])
  const [isLoading, setisLoading] = useState(false)


  const handleTypeCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    setCheckedTypeItems(prevState => {
      if (isChecked) {
        return [...prevState, item];
      } else {
        return prevState.filter(selectedItem => selectedItem.BCD !== item.BCD);
      }
    });
  };

  const goHome = () => {
    history.push('/dashboard')
  }
  const openpop =()=>{
    setpopup(true);
   }
   const closePopup = () => {
     setpopup(false);
   };
   
  // const handleWarehouseCheckboxChange = (e, item) => {
  //   const isChecked = e.target.checked;
  //   setcheckedwarehouse((prevChecked) => {
  //     if (isChecked) {
  //       return [...prevChecked, item.Type];
  //     } else {
  //       return prevChecked.filter((selectedItem) => selectedItem !== item.Type);
  //     }
  //   });
  // };

  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
       {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
      <div>
        <div className="container">

          <div className="scrolltype">
            <div className="row Stockstatement_2">
              <div className="col-sm-12 col-12 col-md-2" style={{ width: "100px", paddingTop: "20px" }}>
                <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2" >
                  Report for (Months)
                </p>
                <input type='number' id='' style={{ width: "40px" }} disabled></input>

              </div>
              <div className="col-sm-12 col-12 col-md-2">

                <div className="scrollerDiv">
                  {warehouse.map((item, index) => (
                    <div key={index}>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`warehouseCheckbox${index}`}
                          name={`warehouseCheckbox${index}`}
                          value={item.Type}
                          checked={checkedwarehouse.includes(item.Type)}
                          onChange={(e) => handleWarehouseCheckboxChange(e, item)}
                        />
                        <label
                          className="form-check-label text-dark my-1"
                          htmlFor={`warehouseCheckbox${index}`}
                        >
                          &nbsp; {item.Type}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-sm-12 col-12 col-md-2">
                <p className="form-check-label text-dark my-1" for="checkbox"></p>

                <div className="scrollerDiv" id="categorydata">
                  {getcategory?.map((item, index) => (
                    <div key={index}>
                      <div className="form-check" >
                        <input
                          type="checkbox"
                          className=""
                          id={`inlineCheckboxType${index}`}
                          name={`orderTypeCheckbox${index}`}
                          value={item.BCD}
                        />
                        <label className="form-check-label text-dark my-1" htmlFor={`inlineCheckboxType${index}`}>
                          &nbsp; {item.DESCRIPTION}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-sm-12 col-12 col-md-5">
              <canvas ref={chartRef}></canvas> {/* Chart canvas */}
              </div>
              <div className="button col-sm-12 col-12 col-md-1 collection_brnGrp text-right ">
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} onClick={handlestockButtonClick} >View</button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`}onClick={openpop} >Excel</button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} onClick={openpop} >Print </button>
                <button type="button" className={`${classes[companyColor?.split(',')[0]]}  my-1`} onClick={goHome}>Exit</button>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div className='fsnanlsis'>
        {fsnclm.length > 0 ? (
          <ReportTbl dataArray={rowData} TblColumns={fsnclm} headerColor={companyColor} tableHeight="70vh" />
        ) : (
          <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
            <thead className={classes[companyColor?.split(',')[0]]}>
              <tr>
                <th style={{ height: "50px", padding: "8px", border: "1px solid #ccc" }}></th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: "8px", border: "1px solid #ccc", color: "#000", }}> No data&nbsp;</td>

              </tr>
            </tbody>
          </table>
        )}

      </div>

    </>
  );
}

export default FsnAnalysis;