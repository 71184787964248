import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";
import axios from 'axios';
import ReportTbl from '../CollectionReport/ReportTbl';
import {useHistory} from 'react-router-dom';
import ComingSoonPopup from '../PopUP/PopUp';
import { COMPANY_CODE, GET_TRAIL_BALANCE_REPORT, GET_TRIAL_BALANCE_REPORT, GET_GROUP_TRIALSELECTION } from '../../../constant';

function TrialBalance() {
    let history = useHistory();
    const classes = useStyles();
    const [companyColor,setcompanyColor] = useState("")
    const[groupselection,setgroupselection]=useState([])
    const [rowData, setRowData] = useState([]);
    const[trialbalanceclm,settrialbalanceclm]=useState([]);
    const[popup,setpopup]=useState(false);


    
    useEffect(() => {
        setcompanyColor(localStorage.getItem('cmpnyType'))
        //document.querySelector('.TOGGLEicon').click();
        axios.post(GET_GROUP_TRIALSELECTION,
            { "CompanyNumber": COMPANY_CODE })
            .then((response) => {
              console.log("GROUP SELECTION")
              console.log(response)
              if (response.data.IsSuccess === true) {
                console.log(response.data.Data);
                setgroupselection(response.data.Data)
      
              }
            })
    
      }, []);
    const LoadData = () => {
        setisLoading(true); 
        var categories =Array.from(document.querySelectorAll("#categorydata input:checked")).map((it) => it.value);
       
        console.log(categories)
      axios.post(GET_TRIAL_BALANCE_REPORT, {
          "CompanyNumber": COMPANY_CODE,
           "FormDate": fromdate,
            "ToDate": todate,
            "Category":categories.join(','),
        }
        )
        .then((response) => {
            console.log("testing data",response)
            if (response.data.IsSuccess === true) {
              var  mydata = response.data.Data?.map((item,i)=>({...item}))
    
              var obj ={}
              var saleHeader =[]
              var keys = Object.keys(mydata[0])
            keys.forEach((data,i)=>{
             var newobject = { field: data, headerName: data, width: 150 }
    
             saleHeader.push(newobject)
            })
              settrialbalanceclm(saleHeader);
              setRowData(mydata); 
            } 
              setisLoading(false); 
            })
          .catch((error) => {
            console.error('Error fetching data:', error);
      
          });
        };

       /** GROUP SELECTION */
  
    var today = new Date().toISOString().slice(0, 10); 
    var toda = new Date();
    var tomorrow = new Date(toda);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var nextday = tomorrow.toISOString().slice(0, 10);
    const [fromdate, setfromdate] = useState(today)
    const [todate, settodate] = useState(nextday)
    const [isLoading,setisLoading] = useState(false)

    const goHome =()=>{
        history.push('/dashboard')
      }
      const openpop =()=>{
        setpopup(true);
       }
       const closePopup = () => {
         setpopup(false);
       };
       
    return (
      <>
       {
            isLoading?  <div className='Commonloader'>
            </div>:null
          }
           {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
          <div className="balacepoupformmain trailBalance_main" >
            <div className="balacepoupformleft allfont">

                {/* From Date   <->  To Date */}
                <div className="row">
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">From &nbsp;&nbsp; </p>
                            <input type="date" className="" id="FDate" name="FormDate" value={fromdate} onChange={(e)=>setfromdate(e.target.value)} />

                        </div>
                    </div>
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">To&nbsp;&nbsp; </p>
                            <input type="date" className="" id="TDate" name="ToDate" value={todate}  onChange={(e)=>settodate(e.target.value)}/>

                        </div>
                    </div>
                 
                </div>
                {/* opening year */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Only Year Opening</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="form-check form-check-inline">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="inlineRadioOptions" value="" defaultChecked={true} />Yes
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="form-check form-check-inline">
                            <input type="radio" className="form-check-input" id="" name="inlineRadioOptions" value="Selected" />No

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
                {/* Report Format */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Report Format</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="inlineRadioOption" value="" defaultChecked={true}  />Descriptive
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="inlineRadioOption" value="Selected" />Short
                            </li>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="inlineRadioOption" value="Selected" />Comperitive
                            </li>
                        </div>
                    </div>
                </div>
                {/* TYpe Of Account */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Type Of Account</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="sales" value=""  defaultChecked={true}/>All A/C
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <input type="radio" className="form-check-input" id="" name="sales" value="Selected" />Non-Zero

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
                {/* statement print sequence */}
                <div className="row" style={{ padding: '5px' }}>
                    <div className="col-sm-3 col-3 col-md-3 ">
                        <div className="row" style={{height:'100%'}}>
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Statement Print Request</p>
                        </div>
                    </div>
                    <div className="col-sm-9 col-9 col-md-9">
                        <div className="row insideRow">
                            <div className="col-sm-6 col-6 col-md-6 ">
                                <input type="radio" className="form-check-input" id="" name="sales_off" value="" defaultChecked={true} />Alphabetical
                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="" name="sales_off" value="Selected" />Summarised
                            </div>
                        </div>
                        <div className="row insideRow">
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="" name="sales_off" value="Selected" />Grouped By Classification
                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="" name="sales_off" value="Selected" />Grouped By reference
                            </div>
                        </div>

                    </div>

                </div>
                {/* Add Opening Balance */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2"> Add Opening </p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="sales_office" value="" defaultChecked={true}/>Yes
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <input type="radio" className="form-check-input" id="" name="sales_office" value="Selected" />No

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
                {/* Account Closing Balance */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Account Closing</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="one" name="sales_officer" value="" defaultChecked={true}/>All
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="one" name="sales_officer" value="Selected" />Debit<br />&nbsp;&nbsp; Only
                            </li>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="one" name="sales_officer" value="Selected" />Credit<br />&nbsp;&nbsp;&nbsp;Only
                            </li>
                        </div>
                    </div>
                </div>
                {/* Print Account Code*/}
                <div className='row text-center'>
                    <div className='col-sm-12 col-12 col-lg-12'>
                        <input type="checkbox" className="form-check-input" id="" name="Print_Account_Code" value="" />&nbsp;Print Account Code

                    </div>
                </div>
                {/* Group Selection*/}

                {/* <div className='row text-center'>
                    <div className='col-sm-12 col-12 col-lg-12'>
                        <div className='groupSelection'>
                            <br />

                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Marketing Expense
                            </li>
                        </div>
                    </div>
                </div> */}
                 <div className='row text-center'>
                    <div className='col-sm-12 col-12 col-lg-12'>
                        <div className='groupSelection' id="categorydata">
                        <br />
                        {groupselection.map(item => (
                            <li >
                            <input
                             type="checkbox" 
                            id={item.BCD} 
                            name={item.BCD}
                             value={item.BCD} 
                             disabled
                             />
                           &nbsp; {item.DESCRIPTION}
                            </li>
                        ))}
                        </div>
                    </div>
                    </div>
                    
                <div className='row trialbalance' style={{position:'sticky',bottom:"0px"}}>
                <div className="d-flex mt-2" style={{ }}>
                <div className='col '> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>EXCEL</Button></div>
                <div className='col trail'>  <Button variant='contained' className={classes[companyColor?.split(',')[0]]} onClick={LoadData} >VIEW</Button></div>
                <div className='col trail'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>PDF</Button></div>
                <div className='col trail'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={goHome} >EXIT</Button></div>
                </div>
              
                    
                </div>

            </div>
           
            <div className="balacepoupformright">
                {trialbalanceclm.length > 0 ? (
            <ReportTbl
            dataArray={rowData}
            TblColumns={trialbalanceclm}
            headerColor={companyColor}
            
            />
        ) :(
            <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
              <thead className={classes[companyColor?.split(',')[0]]}> 
                <tr>
                  <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>
        
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
                
                </tr>
              </tbody>
            </table>
          )}
        </div>
          {/* <DataGrid
          rows={trailbalancedata}
          columns={TrailBalanceRow}
          // loading={loading}
          pageSize={9}
          className={classes[companyColor?.split(',')[0]]}
           getRowId={(row) => row.Code}
        //     onSelectionModelChange={(ids) => {
        //       const selectedIDs = new Set(ids);
        //       const selectedRows = tabledata.filter((row) =>
        //         selectedIDs.has(row.Voucher),
             
        //       );
    
        //     }}
            {...trailbalancedata}
          /> */}
        </div>
      </>
    );
}

export default TrialBalance;