import React, { useEffect, useState } from 'react'
import './help.css'
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";
import { COMPANY_CODE, GET_HELP_PAGE } from '../../constant';
import axios from 'axios';

function Help() {
    const classes = useStyles();
    const theme = useTheme();
    const [companyColor, setcompanyColor] = useState("");
    const [helpData,setHelpData] = useState({
        "CompanyNumber":COMPANY_CODE,
        "Type":"2022-2023",    
        "Euser":"ADMIN",
        "UserName":"",
        "visa":"",
        "Diners":""
    })

    useEffect(() => {
        setcompanyColor(localStorage.getItem('cmpnyType').split(',')[0])
    }, [])

const submitHelp =()=>{

   console.log("helpData",helpData)

    // axios.post(GET_HELP_PAGE , helpData).then((response)=>{
    //     console.log(response)
    //     // have to fix this based on Status Code 
    // })
}

const handlehelpdata =(e)=>{const {name,value} = e.target;setHelpData((prev)=>({...prev , [name]:value}))}


    return (
        <>
            <div className='context' >

                <div className={`left ${classes[companyColor?.split(',')[0]]}`}>

                    <h1 className={`${classes[companyColor + 'BG']}`}>CALL US</h1>

                    <p className='paragraphtext'> 8587070911, 9810023713</p>

                    <h1 className={`${classes[companyColor + 'BG']}`}>LOCATION</h1>
                    <p className='paragraphtext'> H-196, Office number-301, Second Floor, Sector-63, Noida-201301</p>
                    <h1 className={`${classes[companyColor + 'BG']}`}>BUSINESS HOURS</h1>
                    <p className='paragraphtext'> Monday-Friday, 10am - 8pm<br/> Saturday , Sunday ..Closed</p>

                </div>
                <div className={`${classes[companyColor + 'border']} right`}>

                    <center><h2 className={`${classes[companyColor + 'BG']} contact_help_text`}>Contact Us</h2></center>
                    <div className='helpForm'>
                        <input type='text' className={`${classes[companyColor + 'BB']}`} name='UserName' placeholder='Enter Your Name.'  value={helpData.UserName} onChange={(e)=>handlehelpdata(e)}/>
                        <input type='email' className={`${classes[companyColor + 'BB']}`} placeholder='Enter Your Email' name='visa'  value={helpData.visa} onChange={(e)=>handlehelpdata(e)}/>
                        <textarea className={`textare_resize ${classes[companyColor + 'BB']}`} placeholder='Feel Free To Message...' rows="1" cols="30" name='Diners' value={helpData.Diners} onChange={(e)=>handlehelpdata(e)}></textarea>
                        <br />
                        <button className={` helpSubmitBtn ${classes[companyColor?.split(',')[0]]} `} onClick={submitHelp}>SUBMIT</button>
                    </div>
                </div>


            </div>

            <div className={` area  my-1`} >
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

            </div >
        </>
    )
}

export default Help;