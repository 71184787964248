import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel, ListItemText
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Label,
  Area,
  PieChart, BarChart, Bar,
  Pie, Tooltip,
  Cell, LineChart,
  YAxis,
  XAxis, CartesianGrid, LabelList
} from "recharts";
import axios from "axios";
// styles
import useStyles from "../styles";

import {
  COMPANY_CODE, GET_CATEGORY_SALE_REPORT, GET_GRAPH_DATA,GET_ALL_WAREHOUSES,
  GET_ITEMS_BELOW_ROL, GET_SALES_ANALYSIS_COUNT, GET_TOP_TEN_SALE_ITEM,
  GET_TOTAL_BILL_OF_DAY, GET_COLLECTION_REPORT, GET_MASTER_FROM_ITEM, GET_FOR_ITEM,GET_CBIT_ITEM,GET_QOH_ITEM,GET_TOPFIVEPURCHASE_ITEM,GET_LASTFIVEPURCHASE_ITEM, GET_ITEM_SALE_PURCHASEGRAPH, GET_FORSALEANALYSIS, GET_TOPFIVEPURCHASE_CATEGORY
} from "../../../../constant";
import { mdiPrinterPos } from "@mdi/js";

const mainChartData = getMainChartData();
export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();

  var today = new Date().toISOString().slice(0, 10); 
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(props.startdate)   
  const [enddate, setenddate] = useState(props.enddate);
 



  // local
  const [masterfromitem, setmasterfromitem] = useState();
  const [qohfromitem, setqohfromitem] = useState([]);
  const [saleanalysis, setsaleanalysis]= useState([]);

  const [DDvalue,setDDvalue] = useState("")
  const [heading, setheading] = useState("")


  const [itemsalepurchase, setitemsalepurchase] = useState([]);
  const [lastfivepurchaseitem, setlastfivepurchaseitem] = useState();
  const [topfivepurchaseitem, settopfivepurchaseitem] = useState([]);
  const [loginname, setloginname] = useState(localStorage.getItem('username'))
  const [topTenSaleItem, settopTenSaleItem] = useState([])
  const [ChartCategory, setChartCategory] = useState([])
  const [salsAnalysis, setsalsAnalysis] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [graphDate, setGraphDate] = useState([]);
  const [isLoading, setisLoading] = useState(false)
  const [isform, setisform] = useState(false)
  const [companyColor, setcompanyColor] = useState("")
  const [activeButton, setActiveButton] = useState("");
  const[warehouse,setwarehouse]=useState([])
  const[warehouses,setwarehouses]=useState([])
  const [collectionData, setCollectionData] = useState({
    "FormDate": startdate,
    "ToDate": enddate,
    "CompanyNumber": COMPANY_CODE,
    "strWh": "",
    "SystemName": "",
    "Euser": "",
    "Category": ""
  });
  const color = [
    "#8884d8", "#e01ee9", "#34C2FF", "#4986FF", "#2055BD", "#1204c1", "#883be5", "#d65fff", "#e12856", "#e13e3e"
  ]
  const color2 = [
    "#883be5", "#d65fff", "#e01ee9", "#e12856", "#e13e3e"
  ]
  const PieChartData = [
    { name: "Group A", value: ChartCategory[0]?.Quantity, color: "primary" },
    { name: "Group B", value: ChartCategory[1]?.Quantity, color: "secondary" },
    { name: "Group C", value: ChartCategory[2]?.Quantity, color: "warning" },
    { name: "Group D", value: ChartCategory[3]?.Quantity, color: "success" },
    { name: "Group E", value: ChartCategory[4]?.Quantity, color: "success" },
    { name: "Group F", value: ChartCategory[5]?.Quantity, color: "success" },
    { name: "Group G", value: ChartCategory[6]?.Quantity, color: "success" },
    { name: "Group H", value: ChartCategory[7]?.Quantity, color: "success" },
    { name: "Group I", value: ChartCategory[8]?.Quantity, color: "success" },
    { name: "Group J", value: ChartCategory[9]?.Quantity, color: "success" },
  ];
  const [TotalCollection, setTotalCollection] = useState([]);


  const [popupclass, setpopupclass] = useState("display-none")
  const currentliveenquryclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 240 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 }

  ]

  const pendingRFQsclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 150 },
    { field: 'topVendorName', headerName: 'VENDOR NAME', width: 150 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 150 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 },


  ]
  const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]

  // graph sale data 

  const graphdatacurrent = itemsalepurchase; 
  const newArray = graphdatacurrent.map(item => ({
    Mth: item.Mth,
    LastYearPurchase: item.LyPurchase,
    CurrentYearPurchase: item.CyPurchase,
    LastYearSale: item.LySale,
    CurrentYearSale: item.CySale
  }));
    
  const SaleuniqueBymonths = Object.values(newArray.reduce((acc, item) => {
    const month = item.Mth;
    if (!acc[month]) {
      acc[month] = {
        Mth: month,
        LastYearPurchase: 0,
        CurrentYearPurchase: 0,
        LastYearSale: 0,
        CurrentYearSale: 0
      };
    }
    acc[month].LastYearPurchase += item.LastYearPurchase;
    acc[month].CurrentYearPurchase += item.CurrentYearPurchase;
    acc[month].LastYearSale += item.LastYearSale;
    acc[month].CurrentYearSale += item.CurrentYearSale;
    return acc;
  }, {}));
console.log("sale by unique months",SaleuniqueBymonths)
  
  const debitorbalancedataclm = [
    { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150 },
    { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150 },
    { field: 'Debit', headerName: 'DEBITED', width: 80 },
    { field: 'credit', headerName: 'CREDIT', width: 80 },
    { field: 'balance', headerName: 'BALANCE', width: 100 },
  ]

  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return months[monthNumber - 1] || null;
  }


  // __________________

 

  useEffect(()=>{
    setstartdate(props.startdate)
    setenddate(props.enddate)
    console.log("this is start date " , props.startdate)
    console.log(" start date " , props.enddate)


  },[startdate ,enddate])

  


  useEffect(() => {
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
    debugger;
    setcompanyColor(localStorage.getItem('cmpnyType').split(",")[0])
    setisLoading(true);
    getAllWareHouse();
    getAllItems();
    getAllMaster();
    getAllQOH();
    getSalePuchGraph();
    getTopPuch();
    getLastFivePuch();
    getsaleanalysis();
// _______________________________________________________
  
  }, [DDvalue,props.startdate]);


   /**all warehouses dropdown */

const getAllWareHouse=()=>
{
 axios.post(GET_ALL_WAREHOUSES,
  { "CompanyNumber": COMPANY_CODE })
  .then((response) => {

    if (response.data.IsSuccess === true) {
      setwarehouses(response.data.Data)

    }
  })
}
 /** ITEM DASHBOARD dropdown */
 const getAllItems=()=>
 {
 axios.post(GET_CBIT_ITEM,
  { "CompanyNumber": COMPANY_CODE})
  .then((response) => {
console.log("dashbord dropdown warehouses" , response)

    if (response.data.IsSuccess === true) {
      setwarehouse(response.data.Data);

    }
  })
}

  //  ---------- GET MASTER FROM ITEM----------------
  const getAllMaster=()=>
  {
    // console.log("warehouse",warehouse)
    console.log("item =>Master" , DDvalue)
  axios.post(GET_MASTER_FROM_ITEM,
     { "CompanyNumber": COMPANY_CODE, "ICODE":DDvalue})
    .then((response) => {
    
      if (response.data.IsSuccess === true) {
     
        setmasterfromitem(response.data.Data)

      }
    })
    .catch((error) => {
    });
  }
     //  ---------- GET QOH FROM ITEM----------------
     const getAllQOH=()=>
     {
  axios.post(GET_QOH_ITEM,
    { "CompanyNumber": COMPANY_CODE, "ICODE":DDvalue})
   .then((response) => {

    console.log("POS=>QOH=>",response)
     if (response.data.IsSuccess === true) {
       setqohfromitem(response.data.Data)
     }
     else{
      setqohfromitem([])
     }
   })
  }


    //  ---------- GET SALE AND PURCHASE GRAPH FROM ITEM----------------
    const getSalePuchGraph=()=>
    {
  axios.post(GET_ITEM_SALE_PURCHASEGRAPH,
    // { "CompanyNumber": COMPANY_CODE, "ICODE":"1141"})
    { "CompanyNumber": COMPANY_CODE,   "ICODE":null, "Fromdate":'2010-09-13', "Todate":props.enddate})
   .then((response) => {
    console.log(" Graphhhhhhhhhhhhhhhhhhh  " , response)
     if (response.data.IsSuccess === true) {
      console.log(response.data)
       setitemsalepurchase(response.data.Data)

     }
   })
  }
    //  ---------- GET TOP 5 PURCHASE FROM ITEM----------------
    const getTopPuch=()=>
    {
  axios.post(GET_LASTFIVEPURCHASE_ITEM,
    // { "CompanyNumber": COMPANY_CODE, "ICODE":"1141"})
    { "CompanyNumber": COMPANY_CODE,   "ICODE":DDvalue, "Fromdate":props.startdate, "Todate":props.enddate})
   .then((response) => {
    console.log("last 5 purchase price " , response)
     if (response.data.IsSuccess === true) {
       settopfivepurchaseitem(response.data.data)

     }
   })
  }
    //  ---------- GET LAST 5 PURCHASE FROM ITEM----------------
    const getLastFivePuch=()=>
    {
  axios.post(GET_TOPFIVEPURCHASE_ITEM,
    { "CompanyNumber": COMPANY_CODE,   "ICODE":DDvalue, "Fromdate":props.startdate, "Todate":props.enddate})
   .then((response) => {
    console.log("DDvalue ",DDvalue)
    console.log("startdate ",props.startdate)
    console.log("last 5 year purchase" , response.data.Data)
    console.log(response.data.Data.length)
    console.log(response.data.IsSuccess)
     if (response.data.IsSuccess === true) {
       setlastfivepurchaseitem(response.data.Data)

     }
   })
  }
   //  ----------  GET Sale analysis ITEM----------------
   const getsaleanalysis=()=>
   {
   axios.post(GET_FORSALEANALYSIS,
   { "CompanyNumber":COMPANY_CODE, "ICODE":DDvalue, "Fromdate":props.startdate, "Todate":props.enddate, "WAREHOUSE":"HW01", "ItemProfit":"", "ItemTotalPurchase":"", "ItemTotalSale":"" })
   .then((response) => {
    if (response.data.IsSuccess === true) {
      setsaleanalysis(response.data.data)
 
    }
  })
   }
  // Get Report Function => getreports(startdate , enddate)

  const getreports = (start, end) => {

    

   
    //---------- Total Collection----------------

    collectionData.FormDate = start
    collectionData.ToDate = end

    
  }

  var nowexactdate = function getFormettedDate() {
    var datentime = ""
    let today = new Date();

    // Get day, month, and year separately
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    // Create an array of month names
    let monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Get the month name from the array
    let monthName = monthNames[month - 1];
    datentime = `${day} ${monthName} ${year}`
    return datentime

  }

  const CustomBarLabel = ({ x, y, width, value }) => (
    <text x={x + width / 2} y={y - 4} dy={-4} textAnchor="middle" fill="#666">
      {value}
    </text>
  );

  const getreportallwarehouse = (e) => {
    setActiveButton(e.target.name)

    const today = new Date();
    const oneMonthsAgo = new Date();
    oneMonthsAgo.setMonth(today.getMonth() - 1);
    const formattedDate = oneMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  } 

  const getreportseightmonths = (e) => {
    setActiveButton(e.target.name)

    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  }

  const getreportssixmonths = (e) => {

    setActiveButton(e.target.name)
    // ActiveButton
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 6);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))
  }
  //date before respective Months => (a) 
  // setActiveButton("six")

  const getreportsthreemonths = (e) => {
    setActiveButton(e.target.name)
    // setActiveButton("eight")
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 8);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  }




  // ------------------graph end ------------------

  const handleResize = () => {
    setShouldUpdate(false);
    setTimeout(() => {
      setShouldUpdate(true);
    }, 500);
  };


  const handleclosepopup = () => {
    setpopupclass("display-none")
  }

  const handlePendingRfqsCellClick = (params) => {
    setisform(true)
    setpopupclass("display-none")

  }

  return (
    <>
      {/* {
        isLoading ? <div className='Commonloader'>
        </div> : null
      } */}
    
      <div>
     <center>
      <select className="dashboarddropdown sd" 
      onChange={(e)=>{
        setDDvalue(e.target.value);
        console.log("evemnt" ,e.target);
         setheading(e.target.selectedOptions[0].textContent); 
          }  }>
      {
      warehouse.map((item,i)=>(
        <option key={item.ICODE} value={item.ICODE}>{item.ItemName}</option>
      )) 
      }
        
      </select>
      </center>
      <div>
        {/* <h3 style={{textAlign:"right"}}>Total QOH:- 7868</h3> */}
        <div>
      <h2  style={{textAlign:"right"}}>Total QOH:&nbsp;

        {qohfromitem.reduce((total, item) => total + parseInt(item.QOH), 0)}
        </h2>
    </div>
      </div>
      </div>
      <center><h2>{heading}</h2></center>
      <Grid container spacing={6} className={companyColor}>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
             <center><h6 className={`card-title my-0 fw-bold`}>Master</h6></center> 
              <Grid container item alignItems={"center"}>
              <Grid item>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>                  
                    <Grid item  className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                      {
                        <div>
                        <li className="list-group-item">
                          <h6 className={classes.textSecondry} >Purchase Price: {masterfromitem?.PurchaseValue}</h6>
                          </li>  
                        <li className="list-group-item">
                          <h6 className={classes.textSecondry}>Selling Price: {masterfromitem?.SellingValue}</h6>
                          </li>
                        <li className="list-group-item">
                          <h6 className={classes.textSecondry}>Margin: {masterfromitem?.MargingValue}</h6>
                          </li>
                          </div>
                          }
                      </ol>
                    </Grid>
                </Grid>
                <Grid item xs={4} className="text-end">

                  {/* <span > <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span> */}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
          <div className="card-body">
      <center>
        <h6 className={`card-title my-0 fw-bold`} style={{paddingBottom:"14px"}}>QOH</h6>
      </center>
      <Grid container item alignItems={"center"}>
        <Grid item xs={10}>
          <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
          </h4>
          <Grid container spacing={5} className="pt-0">
          {/* <th>Warehouse</th>
           <th>QOH</th> */}
            <Grid item xs={12} className="pt-0"> 
              {qohfromitem && qohfromitem.length > 0 ? (
                <table style={{color:'#111'}}>
                  <thead>
                    <tr>
                      <th>Warehouse</th>
                      <th>QOH</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qohfromitem.map((item) => (
                      <tr key={item.WAREHOUSE}>
                        <td>{item.WAREHOUSE}</td>
                        <td>{item.QOH}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : isLoading ? (
                <p>Loading data...</p>
              ) : (
                <p style={{ position: 'absolute', bottom: '0', left: '4' }}>No data available.</p>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
          </div>
        </Grid>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
             <center> <h6 className={`card-title my-0 fw-bold`}>Last 5 Purchase Price</h6></center>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                  <Grid item xs={12} className="pt-0"> 
                  {topfivepurchaseitem && topfivepurchaseitem.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>PARTY</th>
                      <th>QUANTITY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qohfromitem.map((item) => (
                      <tr key={item.Party}>
                        <td>{item.Party}</td>
                        <td>{item.QTY}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : isLoading ? (
                <p>Loading data...</p>
              ) : (
                <p style={{ position: 'absolute', bottom: '0', left: '4' }}>No data available.</p>
              )}
            </Grid> 
                  </Grid>
                </Grid>


                {/* <Grid item xs={4}className="text-end">
              <span >
            <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
        <div className={`card py-1 ${classes.row1Card}`}>
      <div className="card-body pb-4">
        <center><h6 className={`card-title my-0 fw-bold`}>Sale Analysis</h6></center>
        <Grid container item alignItems={"center"}>
          <Grid item xs={10}>
          {/* {saleanalysis.length === 0 ? <p className={`${classes[companyColor]}`} style={{ float: 'right' }}>NO DATA AVAILABLE</p> : ""} */}
                
                <h6 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  TotalSale: {saleanalysis?.ItemTotalSale}
                </h6>
                <h6 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  TotalPurchase: {saleanalysis?.ItemTotalPurchase}
                </h6>
                <h6 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  Net Margin: {saleanalysis?.ItemProfit}
                </h6>
             
          </Grid>
        </Grid>
      </div>
    </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <center> <h6 className={`card-title my-0 fw-bold`}>Top 5 Purchase Party</h6></center>
              <Grid container item alignItems={"center"}>
              <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                  <Grid item xs={12} className="pt-0"> 
                
                   {lastfivepurchaseitem && lastfivepurchaseitem?.length > 0 ? (
              <>
              <br/>
                <table style={{color:'#111' , height:'200px' ,overflow:'scroll'}}>
                  <thead>
                    <tr>
                      <th>PARTY</th>
                      <th>RATE</th>
                      <th>QTY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastfivepurchaseitem.map((item,i) => (
                      <tr key={i}>
                        <td>{item.Party}</td>
                        <td>{item.Rate}</td>
                        <td>{item.QTY}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
              ) : isLoading ? (
                <p >Loading data...</p>
              ) : (
                <p style={{ position: 'absolute', bottom: '0', left: '4' }} >No data available.</p>
              )}
            </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        {/* <div className={`categorybtn'  ${companyColor.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}style={{width:"100%"}}>
      <div className='categorydashboardbtn' style={{padding:"20px"}}>  
                 <Button variant='contained' className={`${classes[companyColor+'BG']}  my-1`}  style={{width:"33.33%", margin:"3px", color:"#fff"}} >Item Master </Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`}style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Price Stock Ledger</Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`} style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Stock Statement</Button>
               
                </div> 
      </div> */}
      
      </Grid>


      <Grid container spacing={5} className="Graphdata" >
            <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
            <h6 className={`card-title my-0 fw-bold`}>Sale Graph</h6>
          <ResponsiveContainer width="100%" height={250}>
            <AreaChart data={SaleuniqueBymonths} margin={{ top: 10, right: 0, left: 10, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorUvLastYear" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPvLastYear" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey={(data) => getMonthName(data.Mth)} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area type="monotone" dataKey="CurrentYearSale" stroke="#fb7004" fillOpacity={0.5} fill="#fb7004cf" />
              <Area type="monotone" dataKey="CurrentYearPurchase" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              <Area type="monotone" dataKey="LastYearSale" stroke="#8ba7e5" fillOpacity={0.5} fill="#8ba7e5" />
              <Area type="monotone" dataKey="LastYearPurchase" stroke="#82ca9d" fillOpacity={0.5} fill="url(#colorPvLastYear)" />
            </AreaChart>
          </ResponsiveContainer>

        </Grid>
        <br/>
        <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
          <h6 className={`card-title my-0 fw-bold`}>Purchase Graph</h6>
          <div onResize={handleResize}>
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart data={data}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Grid>


      
        <div className="d">
        </div>
      </Grid>
      <Grid container spacing={5} className="Graphdata" >
     
        <div className="d">
        </div>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  const array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  const resultArray = [];
  const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  const mobile = getRandomData(31, 1500, 7500, 7500, 1500);


  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}