import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";
import axios from 'axios';
import ComingSoonPopup from '../PopUP/PopUp';
import {useHistory} from 'react-router-dom';
import ReportTbl from '../CollectionReport/ReportTbl';
import {GET_PRICED_STOCK_LEDGER_GET_ITEM,GET_PRICED_STOCK_LEDGER_ORDER_TYPE,GET_PRICED_STOCK_LEDGER,COMPANY_CODE,GET_GROUP_SELECTION ,GET_ALL_WAREHOUSES, GET_CATEGORY_STOCK_STATUS, GET_SPECIFIC_STOCK_STATUS, GET_STOCK_STATEMENT, } from '../../../constant';

function StockStatement() {
  const history = useHistory();
  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
    const [startdate, setstartdate] = useState(today);
    const [enddate, setenddate] = useState(nextday);
    const classes = useStyles();
    const [companyColor,setcompanyColor] = useState("")
    const [trailbalancedata,settrailbalancedata] = useState([])
    const [allpartyTyp,setallpartyTyp] = useState([])
    const [SelectedfromValue,setSelectedfromValue] = useState("")
    const [ChartCategory, setChartCategory] = useState([])
    const [warehouse, setwarehouse] = useState([]);
    const [getcategory,setgetcategory] = useState([]);
    const [ordertype,setordertype] = useState([]);
    const [specificitem,setspecificitem] = useState([]);
    const[Selectedwarehouse,setSelectedwarehouse] = useState("")
    const [checkedwarehouse, setcheckedwarehouse] = useState([]);
    const [checkedTypeItems, setCheckedTypeItems] = useState([]);
    const [checkedCategoryItems, setCheckedCategoryItems] = useState([]);
    const[Selectedselection,setSelectedselection] = useState("");
    const[stockstatementdata,setstockstatementclm]=useState([]);
    const [rowData, setRowData] = useState([]);
    const[popup,setpopup]=useState(false);
    const [showROLColumn, setShowROLColumn] = useState(false);
    const[showBINColumn, setShowBINColumn] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState('Catego'); 
    const [selectedReportValue, setSelectedReportValue] = useState('no');
    const [selectedDataType, setSelectedDataType] = useState('non');
      // Handle radio button change event
      const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
      };
      const handleRadChange = (e) => {
        setSelectedReportValue(e.target.value);
      }; const handleRChange = (e) => {
        setSelectedDataType(e.target.value);
      };
    const handlestockButtonClick = () => {
        // if (!Selectedwarehouse) {
        //     alert('Please select a warehouse.');
        //     return;
        //   }
        const selectedCategoryFilters = checkedCategoryItems?.map(item => item.DESCRIPTION);  
        setisLoading(true); 

        var datatype = GetCheckedRadioBtnByParent('datatypePO')
        var reportvaluePO = GetCheckedRadioBtnByParent('reportvaluePO')
        var batchtype = GetCheckedRadioBtnByParent ('batchtype')
        console.log(datatype)
        console.log(reportvaluePO)
        console.log(batchtype)
        var categories =Array.from(document.querySelectorAll("#categorydata input:checked")).map((it) => it.value);
        console.log(categories)
        console.log(GET_STOCK_STATEMENT)
        axios.post(GET_STOCK_STATEMENT, 
            
          {
          "CompanyNumber": COMPANY_CODE,
          "FormDate": startdate,
          "ToDate": enddate,
          "strWh":  checkedwarehouse.join(','),
          "Category":categories.join(','),
            
          "Summarise":0,
          "Detailed":datatype == "non" ? 1 :0,
          "BillDate":datatype == "negative" ? 1 :0,
          "Ledger":datatype == "Transacted" ? 1: 0,
          "Paytm":datatype == "Batch" ? 1:0,
  
        })
            .then((response) => {
                console.log("get stock statement",response)
                if (response.data.IsSuccess === true) {
                  var  mydata = response.data.Data?.map((item,i)=>({...item,sno:i+1}))
        
                  var obj ={}
                  var saleHeader =[]
                  var keys = Object.keys(mydata[0])
                keys.forEach((data,i)=>{
                 var newobject = { field: data, headerName: data, width: 150 }
        
                 saleHeader.push(newobject)
                })
                  setstockstatementclm(saleHeader);
                  setRowData(mydata); 
                } 
                  setisLoading(false); 
                })
              .catch((error) => {
                console.error('Error fetching data:', error);
          
              });
          };
      function GetCheckedRadioBtnByParent (parentdiv){
        const parentDiv = document.getElementById(parentdiv);
    const radioButtons = parentDiv.querySelectorAll('input[type="radio"]');
    let selectedValue = null;
    radioButtons.forEach((radio) => {
      if (radio.checked) {
        selectedValue = radio.value;
      }
    });
        return selectedValue
    }

    useEffect(() => {

        /**all warehouses dropdown */
        axios.post(GET_ALL_WAREHOUSES,
          { "CompanyNumber": COMPANY_CODE })
          .then((response) => {
      
            if (response.data.IsSuccess === true) {
              setwarehouse(response.data.Data)
    
            }
          })
  
      // get order type 
     axios.post(GET_PRICED_STOCK_LEDGER_ORDER_TYPE,
      { "CompanyNumber":COMPANY_CODE })
      .then((response) => {
        console.log(" GET ORDER TYPE ")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setordertype(response.data.Data)
  
        }
      })

       // GET SPECIFIC ITEM
     axios.post(GET_SPECIFIC_STOCK_STATUS,
        { "CompanyNumber":COMPANY_CODE })
        .then((response) => {
          console.log(" GET Specific Item ")
          console.log(response)
          if (response.data.IsSuccess === true) {
            console.log(response.data.Data);
            setspecificitem(response.data.Data)
    
          }
        })
        
      // get CATEGORY
  
      axios.post(GET_GROUP_SELECTION,
        { "CompanyNumber":COMPANY_CODE})
        .then((response) => {
          console.log(" GET CATEGORY ")
          console.log(response)
          if (response.data.IsSuccess === true) {
            console.log(response.data.Data);
            setgetcategory(response.data.Data)
    
          }
        })
    }, [startdate, enddate])

      const handleSelectChange = (e) => {
      setSelectedfromValue(e.target.value);
      console.log("Selected Value:", e.target.value);
    };

    
    useEffect(()=>{
        setcompanyColor(localStorage.getItem('cmpnyType'))
       
    },[])

    var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
    var toda = new Date();
    var tomorrow = new Date(toda);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var nextday = tomorrow.toISOString().slice(0, 10);
    const [fromdate, setfromdate] = useState(today)
    const [todate, settodate] = useState(nextday)
    const [isLoading,setisLoading] = useState(false)

    const handleTypeCheckboxChange = (e, item) => {
        const isChecked = e.target.checked;
        setCheckedTypeItems(prevState => {
          if (isChecked) {
            return [...prevState, item];
          } else {
            return prevState.filter(selectedItem => selectedItem.BCD !== item.BCD);
          }
        });
      };

      const goHome =()=>{
        history.push('/dashboard')
      }
      const openpop =()=>{
        setpopup(true);
       }
       const closePopup = () => {
         setpopup(false);
       };
       
      const handleWarehouseCheckboxChange = (e, item) => {
        const isChecked = e.target.checked;
        setcheckedwarehouse((prevChecked) => {
          if (isChecked) {
            return [...prevChecked, item.Type];
          } else {
            return prevChecked.filter((selectedItem) => selectedItem !== item.Type);
          }
        });
      };
    return (
      <>
       {
            isLoading?  <div className='Commonloader'>
            </div>:null
          }
           {
      popup ?  <ComingSoonPopup /> :null
    }
     {
        popup ? <ComingSoonPopup onClose={closePopup} /> : null
      }
        <div className="balacepoupformmain stockstatement_main" >
            <div className="balacepoupformleft allfont" style={{width:"35%"}}>

                {/* From Date   <->  To Date */}
                <div className="row">
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">From &nbsp;&nbsp; </p>
                            <input type="date" className="" id="FDate" name="FormDate" value={startdate}
                             onChange={(e)=>setstartdate(e.target.value)} />

                        </div>
                    </div>
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="">
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">To&nbsp;&nbsp; </p>
                            <input type="date" className="" id="TDate" name="ToDate" value={enddate} 
                             onChange={(e)=>setenddate(e.target.value)}/>

                        </div>
                    </div>
                 
                </div>
                {/* opening year */}
                <div className="row" id='batchtype'>
                <p className="" style={{marginBottom:"5px"}} for="inlineRadio2">Statement Type</p>
                    
                    <div className="col-sm-3 col-3 col-md-3" >
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="batchhAlphab" name="" value="Alphab"
                               disabled    checked={selectedRadio === 'Alphab'} // Reflect the selected state
                onChange={handleRadioChange}/>&nbsp;Alphab
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <input type="radio" className="form-check-input" id="hbatchhAlphab" name="batchh" value="Catego"
                            checked={selectedRadio === 'Catego'}
                              onChange={handleRadioChange}/>&nbsp;Catego

                        </div>
                    </div>
                   <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                        <input type="radio" className="form-check-input" id="batchtfh" name="batchh" value="Batch"
                       checked={selectedRadio === 'Batch'}
                       onChange={handleRadioChange} />&nbsp;Batch
                        </div>
                    </div> 
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                        <input type="radio" className="form-check-input" id="batvbchh" name="batchh" value="Size"
                        checked={selectedRadio === 'Size'}
                        onChange={handleRadioChange} />&nbsp;Size
                        </div>
                    </div> 
                </div>

                <div className="row">
                 <div className="mb-0 Stockstatement_2grid">
                 <span style={{ color: '#000' }}>Specific Party &nbsp;</span>
                    <select  style={{width:'73%'}}  onChange={(e) => setSelectedfromValue(e.target.value)}  >
                    <option value="">Please Select Party</option>
                    {specificitem?.map((item,i) => (
          <option key={i} value={item.ITEM}>
            {item.ITEM}

          </option>
        ))}
                    </select>   
                    </div>
                    </div>
            
                <div className="row" style={{ padding: '5px' }}>
                    <div className="col-sm-3 col-3 col-md-3 ">
                        <div className="row" style={{height:'100%'}}>
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Report Value By</p>
                        </div>
                    </div>
                    <div className="col-sm-9 col-9 col-md-9" id='reportvaluePO'>
                        <div className="row insideRow">
                            <div className="col-sm-6 col-6 col-md-6 ">
                                <input type="radio" className="form-check-input" id="nos" name="sale" value="no" 
                                  checked={selectedReportValue === 'no'}
                        onChange={ handleRadChange} />&nbsp;No
                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="standatw" name="sale" value="Standard"
                                  checked={selectedReportValue === 'Standard'}
                        onChange={ handleRadChange} disabled/>&nbsp;Standard Rate
                            </div>
                        </div>
                        <div className="row insideRow">
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="evaluat" name="sale" value="Evaluated"  
                                checked={selectedReportValue === 'Evaluated'}
                        onChange={ handleRadChange}  />&nbsp;Evaluated
                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="mrpd" name="sale" value="Mrp" 
                                 checked={selectedReportValue === 'Mrp'}
                        onChange={ handleRadChange} disabled />&nbsp;Mrp
                            </div>
                        </div>

                    </div>

                </div>

                <div className="row" style={{ padding: '5px' }}>
                    <div className="col-sm-3 col-3 col-md-3 ">
                        <div className="row" style={{height:'100%'}}>
                            <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">Data Type</p>
                        </div>
                    </div>
                    <div className="col-sm-9 col-9 col-md-9" id='datatypePO'>
                        <div className="row insideRow">
                            <div className="col-sm-6 col-6 col-md-6 ">
                                <input type="radio" className="form-check-input" id="nob" name="sales_officer" value="non"
                                    checked={selectedDataType === 'non'}
                        onChange={handleRChange} />&nbsp;Non Zero
                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="asl" name="sales_officer" value="all"
                                  checked={selectedDataType === 'all'}
                                  onChange={handleRChange}/>&nbsp;All
                            </div>
                        </div>
                        <div className="row insideRow">
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="negatid" name="sales_officer" value="negative"
                                  checked={selectedDataType === 'negative'}
                                  onChange={handleRChange}/>&nbsp;Negative
                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="transac" name="sales_officer" value="Transacted" 
                                 checked={selectedDataType === 'Transacted'}
                                 onChange={handleRadioChange}/>&nbsp;Transacted
                            </div>
                        </div>

                    </div>

                </div>

                <div className="scrolltype">
            <div className="row Stockstatement_2">
            <div className="">
      <p className="form-check-label text-dark my-1" htmlFor="inlineRadio2">
        Select Warehouse
      </p>
      <div className="scrollerDiv">
        {warehouse.map((item, index) => (
          <div key={index}>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`warehouseCheckbox${index}`}
                name={`warehouseCheckbox${index}`}
                value={item.Type}
                checked={checkedwarehouse.includes(item.Type)}
                onChange={(e) => handleWarehouseCheckboxChange(e, item)}
              />
              <label
                className="form-check-label text-dark my-1"
                htmlFor={`warehouseCheckbox${index}`}
              >
                &nbsp; {item.Type}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
            {/* <div className="">
                <select style={{ width:"100%" }} onChange={(e) => setSelectedwarehouse(e.target.value)}>
        <option   value="">Select Warehouse</option>

        {warehouse?.map((item) => (
          <option key={item.Type} value={item.Type}>
            {item.Type}
          </option>
        ))}
      </select>
              </div> */}
              <div className="col-sm-12 col-12 col-md-12">      
                <p className="form-check-label text-dark my-1" for="checkbox"></p>

                <div className="scrollerDiv">
    {ordertype?.map((item, index) => (
      <div key={index}>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`inlineCheckboxType${index}`}
            name={`orderTypeCheckbox${index}`}

            value={item.BCD}
            onChange={(e) => handleTypeCheckboxChange(e, item)}
          />
          <label className="form-check-label text-dark my-1" htmlFor={`inlineCheckboxType${index}`}>
          &nbsp; {item.DESCRIPTION}
          </label>
        </div>
      </div>
    ))}
  </div>
              </div>
              <div className="col-sm-12 col-12 col-md-12">      
                <p className="form-check-label text-dark my-1" for="checkbox"></p>

                <div className="scrollerDiv" id="categorydata">
    {getcategory?.map((item, index) => (
      <div key={index}>
        <div className="form-check" >
          <input
            type="checkbox"
            className=""
            id={`inlineCheckboxType${index}`}
            name={`orderTypeCheckbox${index}`}
           value={item.BCD}
          />
          <label className="form-check-label text-dark my-1" htmlFor={`inlineCheckboxType${index}`}>
          &nbsp; {item.DESCRIPTION}
          </label>
        </div>
      </div>
    ))}
  </div>
              </div>
              </div>
              </div>
                <div className="row">
                <div className="col-sm-12 col-12 col-md-12">
                        <div className="row insideRow">
                            <div className="col-sm-4 col-4 col-md-4 ">
                                <input type="checkbox" className="form-check-input" id="" name="sales_officer" value="" />&nbsp;Clear All
                            </div>
                            <div className="col-sm-4 col-4 col-md-4">
                                <input type="checkbox" className="form-check-input" id="" name="sales_officer" value=""  onChange={() => setShowROLColumn(!showROLColumn)}  />&nbsp;Print ROL
                            </div>
                            <div className="col-sm-4 col-4 col-md-4">
                                <input type="checkbox" className="form-check-input" id="" name="sales_officer" value="" onChange={() => setShowBINColumn(!showBINColumn)}  />&nbsp;Print Bin
                            </div>
                            
                        </div>
                        {/* <div className="row insideRow">
                            <div className="col-sm-4 col-4 col-md-4">
                                <input type="checkbox" className="form-check-input" id="" name="sales_officer" value=""  disabled/>&nbsp;Print
                            </div>
                            <div className="col-sm-4 col-4 col-md-4">
                                <input type="checkbox" className="form-check-input" id="" name="sales_officer" value=""  disabled/>&nbsp;Print Bin
                            </div>
                            <div className="col-sm-4 col-4 col-md-4">
                                <input type="checkbox" className="form-check-input" id="" name="sales_officer" value="" disabled />&nbsp;Print
                            </div>
                        </div> */}

                    </div>
                    </div>
               
                <div className='row ' style={{position:'sticky',bottom:"0px"}}>
                <div className='col-sm-3 col-3 col-md-3'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>EXCEL</Button></div>
                <div className='col-sm-3 col-3 col-md-3'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]}onClick={handlestockButtonClick} >VIEW</Button></div>
                <div className='col-sm-3 col-3 col-md-3'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={openpop}>PDF</Button></div>
                <div className='col-sm-3 col-3 col-md-3'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]}  onClick={goHome}>EXIT</Button></div>
                </div>
                <div className="d-flex mt-2" style={{ width: '100%',background:'#0078d4' }}>
                    
                </div>

            </div>
            <div className="balacepoupformright">
  <div className=''>
  {stockstatementdata.length > 0 ? (
  <ReportTbl
    dataArray={rowData}
    TblColumns={stockstatementdata.filter((column) => {
      if ((showROLColumn || column.field !== 'ROL' && column.field !== 'ROQ' )
      && (showBINColumn || column.field !== 'BIN')&&
      column.field !== 'sno' // Exclude the 'sno' column
    ) {
        return true;
      }
      return false;
    })}
    headerColor={companyColor}
    tableHeight="70vh"
  />
) : (
  <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ccc" }}>
    <thead className={classes[companyColor?.split(',')[0]]}> 
      <tr>
        <th style={{ height:"50px", padding: "8px", border: "1px solid #ccc" }}></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ padding: "8px", border: "1px solid #ccc", color:"#000",  }}> No data&nbsp;</td>
      </tr>
    </tbody>
  </table>
)}

   
  </div>
</div>
        </div>
      </>
    );
}

export default StockStatement;