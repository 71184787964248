import React from 'react';

const Searchbar = () => {
  return (
    <form className="search-form">
      <input type="text" placeholder="Tap here to search..." className="search-input" />
      <select className="dropdown">
        <option value="all">Dashboard</option>
        <option value="category1">Category 1</option>
        <option value="category2">Category 2</option>
        <option value="category3">Category 3</option>

      </select>
      {/* <button type="submit">Submit</button> */}
    </form>
  );
};

export default Searchbar;
